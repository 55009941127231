import type { Core, Plugin } from '../../core';
import { PLUGIN_ID } from './constants';
import type { PluginStart } from './types';
import { init as initApi } from './api';
import type { API, Queries } from './api';

export class ConnectorsPlugin implements Plugin {
  id = PLUGIN_ID;

  private startApi: { api: API; queries: Queries } | undefined;

  setup(core: Core) {
    this.startApi = initApi({ httpClient: core.httpClient });
  }

  start(): PluginStart {
    if (!this.startApi) {
      throw new Error(`Connectors plugin not nitialized.`);
    }

    return {
      ...this.startApi,
    };
  }
}

export default ConnectorsPlugin;
