import type { HttpStatusCode, ErrorArgs } from './types';

export class BaseError extends Error {
  statusCode: HttpStatusCode;

  error: string | undefined;

  type: string | undefined;

  data: Record<any, any> | undefined;

  code: string | undefined;

  constructor(message: string, { statusCode = 500, name = '', type, data, code }: ErrorArgs = {}) {
    super(message);

    Object.setPrototypeOf(this, new.target.prototype);
    this.name = name;
    this.statusCode = statusCode;
    this.data = data;
    this.type = type;
    this.code = code;
    Error.captureStackTrace(this);
  }

  toJSON() {
    return {
      message: this.message,
      name: this.name,
      type: this.type,
      statusCode: this.statusCode,
      data: this.data,
      code: this.code,
    };
  }
}

export default BaseError;
