import BigNumber from 'bignumber.js';
import { RESULT_DECIMALS } from '../../constants';
import type { GetTradeConfigStatsResponse, TradeConfig } from './shared_imports';

export const calculateUnrealizedRoiTradeConfig = ({
  tradeConfig,
  stats,
}: {
  tradeConfig: TradeConfig;
  stats: GetTradeConfigStatsResponse;
}) => {
  const { unrealized, assetPair } = stats;
  const xchange = tradeConfig.outputs[0]?.xchange.id;

  const unRealizedCost = BigNumber(unrealized.cost ?? 0);
  const unRealizedSize = BigNumber(unrealized.size ?? 0);

  if (unRealizedCost.isZero() || unRealizedSize.isZero()) {
    return null;
  }

  const avgOpenPrice = unRealizedCost.dividedBy(unRealizedSize).toFixed(RESULT_DECIMALS);

  return {
    avgOpenPrice,
    assetPair,
    xchange,
  };
};
