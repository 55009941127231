import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type { BaseError, CoreState } from '../../../core';
import type {
  GetAssetPairQueryParams,
  ListTradeConfigsQueryParams,
  TradeConfig,
} from '../shared_imports';

import type { API } from './api';

export const init = ({ api, coreState: { testModeOn } }: { api: API; coreState: CoreState }) => {
  function useTradeConfigs(params: ListTradeConfigsQueryParams = {}) {
    return useInfiniteQuery(['tradeConfigs', testModeOn], () => {
      return api.getTradeConfigs({ ...params }, { testModeOn });
    });
  }

  function useTradeConfig(tradeName?: string) {
    return useQuery<TradeConfig | null, BaseError>(['tradeConfigs', tradeName, testModeOn], () => {
      if (tradeName === undefined) {
        return null;
      }
      return api.getTradeConfig(tradeName, { testModeOn });
    });
  }

  function useAssetPairs(
    params: { xchange: GetAssetPairQueryParams['xchange'] | 'paper' },
    assetPairs: string[] = []
  ) {
    return useQuery(
      ['crypto', 'assetPairs', assetPairs, params],
      ({ signal }) => {
        const isEmpty = Array.isArray(assetPairs) && assetPairs.filter(Boolean).length === 0;
        if (!Array.isArray(assetPairs) || isEmpty || params.xchange === undefined) {
          return null;
        }

        const parsed: GetAssetPairQueryParams = {
          xchange: params.xchange === 'paper' ? undefined : params.xchange,
        };

        return api.getAssetPairs(assetPairs, parsed, { signal });
      },
      {
        retry: (_, err: BaseError) => {
          return err.statusCode !== 404;
        },
      }
    );
  }

  return {
    useTradeConfigs,
    useTradeConfig,
    useAssetPairs,
  };
};

export type Queries = ReturnType<typeof init>;
