import { FC, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Loader,
  Modal,
  Select,
  Stack,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

import { useCoreState } from '../../../../core';
import { AddKrakenConnector, CONNECTORS_META } from '../../../connectors';
import type {
  ListConnectorResponse,
  XchangeConnector,
  XchangeConnectorId,
} from '../../shared_imports';
import { useFormContext } from './create-edit-trade-config-form';

const exchangeConnectors = Object.values(CONNECTORS_META).filter(({ type }) => type === 'xchange');

export const xchangeSelectItems: Array<{ value: XchangeConnectorId | 'paper'; label: string }> =
  exchangeConnectors.map(({ id, label }) => ({
    value: id as XchangeConnectorId,
    label,
  }));

interface Props {
  isLoadingConnectors: boolean;
  userConnectors?: ListConnectorResponse | null;
  isDisabled?: boolean;
  isFetchingAssetPair?: boolean;
  showFormErrors?: boolean;
}

export const AssetSelector: FC<Props> = ({
  isLoadingConnectors,
  userConnectors,
  showFormErrors = false,
  isDisabled = false,
  isFetchingAssetPair = false,
}) => {
  const [{ testModeOn }] = useCoreState();
  const { getInputProps, values } = useFormContext();
  const theme = useMantineTheme();
  const [isAddConnectorModalOpened, setIsAddConnectorModalOpened] = useState(false);

  const { xchange } = values;

  const userXchangeConnectors = useMemo(() => {
    if (!userConnectors) {
      return null;
    }
    return userConnectors.filter(({ type }) => type === 'xchange') as XchangeConnector[];
  }, [userConnectors]);

  const selectedXchangeConnector = useMemo(() => {
    if (!userConnectors) {
      return null;
    }
    return userConnectors.find(({ id }) => id === xchange);
  }, [xchange, userConnectors]);

  const connectXchange = () => {
    setIsAddConnectorModalOpened(true);
  };

  if (isLoadingConnectors || (!testModeOn && !userXchangeConnectors)) {
    return null;
  }

  const renderAssetPairInput = () => {
    const hasXchangeConnector =
      testModeOn || isLoadingConnectors ? true : !!selectedXchangeConnector;

    if (!hasXchangeConnector) {
      return (
        <Alert
          icon={<IconAlertCircle size="1rem" />}
          title={`[${xchange}] connector missing`}
          color="red"
        >
          <Text>You need to connect to {xchange} before continuing.</Text>
          <Button variant="outline" compact color="red" mt="md" onClick={connectXchange}>
            Connect to {xchange}
          </Button>
        </Alert>
      );
    }

    return (
      <TextInput
        label="Asset pair"
        placeholder="e.g BTCUSD"
        required
        {...getInputProps('ticker')}
        error={showFormErrors && getInputProps('ticker').error}
        disabled={isDisabled}
        rightSection={
          isFetchingAssetPair ? (
            <Loader
              size="xs"
              color={theme.colorScheme === 'dark' ? theme.colors.dark[1] : undefined}
            />
          ) : undefined
        }
      />
    );
  };

  return (
    <>
      <Stack>
        {!testModeOn && !isDisabled && (
          <Select
            label="Select exchange"
            placeholder="Pick one"
            dropdownPosition="bottom"
            withAsterisk
            {...getInputProps('xchange')}
            error={showFormErrors && getInputProps('xchange').error}
            data={xchangeSelectItems ?? []}
          />
        )}

        {renderAssetPairInput()}
      </Stack>
      <Modal
        opened={isAddConnectorModalOpened}
        onClose={() => setIsAddConnectorModalOpened(false)}
        title={<Text fw={700}>Connect to {xchange}</Text>}
      >
        <AddKrakenConnector onConnectorCreated={() => setIsAddConnectorModalOpened(false)} />
      </Modal>
    </>
  );
};
