import { createContext, useContext, useEffect, useMemo } from 'react';
import type { FC, ReactNode } from 'react';

import type { Subscription } from 'rxjs';
import type { AppCore, State } from './core';
import { useObservable } from '../utils';
import { stateDefaultValue } from './constants';

const coreContext = createContext<AppCore | null>(null);
const ContextProvider = coreContext.Provider;

export const CoreProvider: FC<AppCore & { children: ReactNode }> = ({ children, ...rest }) => {
  return <ContextProvider value={rest}>{children}</ContextProvider>;
};

export const useCore = () => {
  const ctx = useContext(coreContext);
  if (!ctx) {
    throw new Error(`useCore() must be used within a <CoreProvider />.`);
  }
  return ctx;
};

let lastState: State = stateDefaultValue;
let subscription: Subscription | null = null;

export const useCoreState = () => {
  const {
    state: { set, state$ },
  } = useCore();
  const reactState = useObservable(state$, lastState);

  useEffect(() => {
    if (!subscription) {
      subscription = state$.subscribe((state) => {
        lastState = state;
      });
    }
  }, [state$]);

  return useMemo(() => [reactState, set] as const, [set, reactState]);
};
