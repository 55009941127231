import type { FC } from 'react';
import { Button, Drawer, Flex } from '@mantine/core';
import { CustomDrawer } from '../../../components';
import { PositionDetails } from './position_detail';
import type { Position } from '../types';

interface Props {
  opened: boolean;
  close: () => void;
  position: Position | null;
  closePosition: (position: Position) => void;
}

export const PositionDetailsDrawer: FC<Props> = ({ opened, close, position, closePosition }) => {
  return (
    <CustomDrawer
      opened={opened}
      onClose={close}
      position="right"
      padding="xl"
      size="md"
      scrollAreaComponent={Drawer.NativeScrollArea}
    >
      {position !== null && (
        <>
          <CustomDrawer.Header title="Position" />
          <CustomDrawer.Main>
            <PositionDetails position={position} />
          </CustomDrawer.Main>
          {position.status === 'open' && (
            <CustomDrawer.Footer>
              <Flex justify="flex-end">
                <Button color="red" onClick={() => closePosition(position)}>
                  Close position
                </Button>
              </Flex>
            </CustomDrawer.Footer>
          )}
        </>
      )}
    </CustomDrawer>
  );
};
