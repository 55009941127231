import type { FC } from 'react';
import type { XchangeConnectorId } from '../../shared_imports';
import { renderDecimalValue, useCurrentPrice } from '../../utils';

interface Props {
  assetPair: string;
  xchange: XchangeConnectorId | 'paper';
  quote: string;
}

export const CurrentPrice: FC<Props> = ({ assetPair, xchange = 'paper', quote }) => {
  const price = useCurrentPrice({ assetPair, xchange });

  return <span>{renderDecimalValue(price, { quote })}</span>;
};
