import { useInfiniteQuery } from '@tanstack/react-query';
import type { ListMessagesQueryParams } from '../shared_imports';

import type { API } from './api';
import type { CoreState } from '../../../core';

export const init = ({ api, coreState: { testModeOn } }: { api: API; coreState: CoreState }) => {
  function useMessagesTradeConfig(
    params: ListMessagesQueryParams = { limit: '20' },
    {
      refetchInterval = 5000,
      autoRefetch = true,
    }: { refetchInterval?: number; autoRefetch?: boolean } = {}
  ) {
    return useInfiniteQuery({
      queryKey: ['webhook', 'messages', params, testModeOn],
      queryFn: ({ pageParam: pageCursor }) => {
        return api.listMessages({ ...params, pageCursor }, { testModeOn });
      },
      getNextPageParam: (lastPage) => lastPage.pageCursor,
      refetchInterval: autoRefetch ? refetchInterval : undefined,
    });
  }

  return {
    useMessagesTradeConfig,
  };
};

export type Queries = ReturnType<typeof init>;
