import lodable from '@loadable/component';
import type { RouteObject } from 'react-router-dom';

import { ConnectorsProvider } from './shared_imports';
import type { ConnectorsPluginStart } from './shared_imports';
import { UserContextProvider } from './context';
import type { API, Queries } from './api';

const UserLazy = lodable(() => import('./user'), {
  resolveComponent: (components) => components.User,
});

export const init = ({
  getPlugins,
  getApi,
}: {
  getPlugins: () => {
    connectors: ConnectorsPluginStart;
  };
  getApi: () => {
    api: API;
    queries: Queries;
  };
}) => {
  const UserWrapped = () => {
    const plugins = getPlugins();

    const getConnectorsApi = () => ({
      api: plugins.connectors.api,
      queries: plugins.connectors.queries,
    });

    return (
      <ConnectorsProvider getApi={getConnectorsApi}>
        <UserContextProvider plugins={plugins} getApi={getApi}>
          <UserLazy />
        </UserContextProvider>
      </ConnectorsProvider>
    );
  };

  const route: RouteObject = {
    path: '/profile',
    element: <UserWrapped />,
  };

  return {
    route,
  };
};

export default init;
