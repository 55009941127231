import { Timeline, useMantineTheme } from '@mantine/core';
import { FC, useMemo } from 'react';
import format from 'date-fns/format';
import type { StepSkipped, StepError } from '../../shared_imports';
import type { GenericMessageStep, MessageStepEnhanced, WebhookStepAction } from '../../types';
import { KeyValue, Props as KeyValueProps } from '../key-value';

interface Props {
  steps: MessageStepEnhanced[];
}

const formatTime = (timestamp: number): string => {
  try {
    return format(timestamp, 'HH:mm:ss.SSS');
  } catch (e) {
    return 'error';
  }
};

const actionToLabel: { [action in WebhookStepAction]: string } = {
  begin: 'Start',
  end: 'End',
  validateConditions: 'Validate conditions',
  placeOrder: 'Place order',
  insertRowGoogleSheet: 'Insert position in Google sheet',
  updateRowGoogleSheet: 'Update position in Google sheet',
  calculatePositionResult: 'Calculate ROI position',
  closePositionStart: 'Start closing position',
  createPosition: 'Open position',
  getPositionToClose: 'Get position to close',
  initOpenClosePosition: 'Start opening/closing position',
  loadSignalConfig: 'Load signal configuration',
  onPosition: 'Handle position',
  openPositionStart: 'Start opening position',
  sendTradeExecutionToPipe: 'Send trade to pipe',
  sendTradeExecutionToPipes: 'Send trades to pipes',
  updatePosition: 'Update position',
  validateAssetBalance: 'Validate asset balance',
  validateCapital: 'Validate capital',
  validateMessage: 'Validate message',
  validateStatus: 'Validate status',
};

const isStepSkipped = (step: GenericMessageStep): step is StepSkipped => step.status === 'skipped';
const isFailedStep = (step: GenericMessageStep): step is StepError => step.status === 'error';

export const MessageSteps: FC<Props> = ({ steps }) => {
  const theme = useMantineTheme();
  let lastExecutedStep = steps.findIndex(({ status }) => status === 'skipped');
  lastExecutedStep = lastExecutedStep === -1 ? steps.length : lastExecutedStep - 1;

  const commonPropsStepKeyValue = useMemo<Partial<KeyValueProps>>(
    () => ({
      isDimmed: true,
      fontWeightValue: null,
      ff: theme.fontFamilyMonospace,
      size: 'xs',
    }),
    [theme]
  );

  return (
    <Timeline active={lastExecutedStep} lineWidth={1} bulletSize={12} p={0}>
      {steps.map((step, i) => {
        if (isStepSkipped(step)) {
          return (
            <Timeline.Item key={i} title={actionToLabel[step.action]}>
              <KeyValue label="Status" value="skipped" {...commonPropsStepKeyValue} />
            </Timeline.Item>
          );
        }
        const { timestamp, action, duration } = step;
        const key = timestamp ? `${timestamp}-${i}` : i;

        if (isFailedStep(step)) {
          const { error } = step;
          return (
            <Timeline.Item key={key} title={actionToLabel[action]}>
              <KeyValue label="Time" value={formatTime(timestamp)} {...commonPropsStepKeyValue} />
              {step.text && (
                <KeyValue label="Command" value={step.text} {...commonPropsStepKeyValue} />
              )}
              <KeyValue
                label="Error"
                value={error.message}
                {...commonPropsStepKeyValue}
                color="red"
              />
            </Timeline.Item>
          );
        }

        return (
          <Timeline.Item key={key} title={actionToLabel[action]}>
            <KeyValue label="Time" value={formatTime(timestamp)} {...commonPropsStepKeyValue} />
            {step.action !== 'end' && (
              <KeyValue label="Duration" value={`${duration}ms`} {...commonPropsStepKeyValue} />
            )}
            {step.text && (
              <KeyValue label="Command" value={step.text} {...commonPropsStepKeyValue} />
            )}
          </Timeline.Item>
        );
      })}
    </Timeline>
  );
};
