import loadable from '@loadable/component';
import { Navigate, RouteObject } from 'react-router-dom';
import { AuthPage } from './auth/ui';

const LoginLazy = loadable(() => import('./auth/ui/login'), {
  resolveComponent: (components) => components.Login,
});
const RegisterLazy = loadable(() => import('./auth/ui/register'), {
  resolveComponent: (components) => components.Register,
});
const PasswordResetLazy = loadable(() => import('./auth/ui/password-reset'), {
  resolveComponent: (components) => components.PasswordReset,
});
const GoodByeLazy = loadable(() => import('./pages/goodbye'), {
  resolveComponent: (components) => components.Goodbye,
});

export const routes: RouteObject[] = [
  {
    path: '/auth',
    element: <AuthPage />,
    children: [
      {
        index: true,
        element: <Navigate to="login" replace={true} />,
      },
      {
        path: 'login',
        element: <LoginLazy />,
      },
      {
        path: 'register',
        element: <RegisterLazy />,
      },
      {
        path: 'password-reset',
        element: <PasswordResetLazy />,
      },
    ],
  },
  {
    path: 'goodbye',
    element: <GoodByeLazy />,
  },
];

export default routes;
