import type { MantineThemeOverride, Tuple, DefaultMantineColor } from '@mantine/core';

type ExtendedCustomColors = 'teal' | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<ExtendedCustomColors, Tuple<string, 10>>;
  }
}

export const theme: MantineThemeOverride = {
  black: '#444',
  cursorType: 'pointer',
  primaryColor: 'teal',
  colors: {
    // Color shades created in https://themera.vercel.app/
    teal: [
      '#EFF6F6',
      '#D1E5E5',
      '#B4D5D5',
      '#97C4C4',
      '#7AB3B3',
      '#5CA3A3',
      '#4A8282',
      '#376262',
      '#254141',
      '#122121',
    ],
  },
  // headings: {
  //   // properties for all headings
  //   fontWeight: 400,
  //   fontFamily: 'Roboto',

  //   // properties for individual headings, all of them are optional
  //   sizes: {
  //     h1: { fontWeight: 100, fontSize: 32, lineHeight: 1.4 },
  //     h2: { fontSize: 28, lineHeight: 1.5 },
  //     // ...up to h6
  //     h6: { fontWeight: 900 },
  //   },
  // },
};

export default theme;
