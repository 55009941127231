import { createContext, FC, ReactNode, useContext } from 'react';
import type { ConnectorsPluginStart } from '../connectors';
import type { API, Queries } from './api';

interface Context {
  getApi: () => {
    api: API;
    queries: Queries;
  };
  plugins: {
    connectors: ConnectorsPluginStart;
  };
}

const UserContext = createContext<Context | null>(null);

export const UserContextProvider: FC<Context & { children: ReactNode }> = ({
  children,
  ...rest
}) => {
  return <UserContext.Provider value={rest}>{children}</UserContext.Provider>;
};

export const useApp = () => {
  const ctx = useContext(UserContext);
  if (!ctx) {
    throw new Error(`Ctx missing`);
  }
  return ctx;
};
