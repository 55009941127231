import type { Core, CoreState, Plugin } from '../../core';
import type { StatsPluginStart } from '../stats';
import type { PluginStart } from './types';
import { PLUGIN_ID } from './constants';
import { init as initApp } from './app';
import { init as initApi } from './api';
import type { AppContext } from './context';

export interface PluginDependencies {
  stats: StatsPluginStart;
}

export class PositionsPlugin implements Plugin<void, PluginDependencies> {
  id = PLUGIN_ID;

  private pluginDeps: PluginDependencies | undefined;

  setup(core: Core) {
    const context: AppContext = {
      i18n: core.i18n,
      httpClient: core.httpClient,
      getPlugins: () => {
        if (!this.pluginDeps) {
          throw new Error(`Plugins dependencies missing`);
        }
        return this.pluginDeps;
      },
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
    const { routes } = initApp(context);

    core.router.registerRoutes(routes);
  }

  start(core: Core, plugins: PluginDependencies): PluginStart {
    this.pluginDeps = plugins;

    return {
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
  }
}

export default PositionsPlugin;
