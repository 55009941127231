import { Center, Grid, Loader, Stack, Text, Button, Title, rem } from '@mantine/core';
import { FC, useEffect, useMemo, useState } from 'react';
import type { UseInfiniteQueryResult } from '@tanstack/react-query';
import type {
  ListMessagesQueryParams,
  ListMessagesResponse,
  MessageError,
  MessageSuccess,
} from '../../shared_imports';
import type { MessageEnhanced } from '../../types';
import { Messages } from './messages';
import { DetailsPanel } from './details-panel';

interface Props {
  children?: React.ReactNode;
  useMessages: (
    params: ListMessagesQueryParams,
    options?: { autoRefetch?: boolean }
  ) => UseInfiniteQueryResult<ListMessagesResponse>;
  /**
   * Use the idx prop to reset the message selection state.
   * Each time the idx changes the message selection is reset.
   */
  idx?: number;
  autoRefetch?: boolean;
  waitingIndicator?: boolean;
  onIncomingMessages?: (messages: MessageEnhanced[]) => void;
}

export const MessagesInspector: FC<Props> = ({
  useMessages,
  autoRefetch,
  waitingIndicator = false,
  idx = 0,
  children,
  onIncomingMessages,
}) => {
  const [selectedMessage, setSelectedMessage] = useState<MessageEnhanced | null>(null);
  const {
    data: messagesResponse,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isRefetching,
    isLoading,
  } = useMessages({ limit: '10' }, { autoRefetch });

  const messages = useMemo(() => {
    if (!messagesResponse) {
      return [];
    }

    return messagesResponse.pages.reduce<Array<MessageSuccess | MessageError>>((acc, page) => {
      return [...acc, ...page.messages];
    }, []);
  }, [messagesResponse]);

  const loadMore = () => {
    fetchNextPage();
  };

  useEffect(() => {
    setSelectedMessage(null);
  }, [idx]);

  return (
    <Grid gutter="xl">
      <Grid.Col sm={7}>
        <Stack mt="md">
          {isLoading && (
            <Center>
              <Loader variant="dots" />
            </Center>
          )}
          {waitingIndicator && !isLoading && (
            <>
              <Center mx="auto">
                <Loader size="xs" variant="bars" />
              </Center>
              <Text color="dimmed" align="center" size="sm" mb="sm">
                Waiting for messages...
              </Text>
            </>
          )}
          {!waitingIndicator && !isLoading && messages.length === 0 && (
            <Center mt={rem(32)}>
              <Title order={3} style={{ textAlign: 'center' }} color="dimmed">
                No messages
              </Title>
            </Center>
          )}
          <div>
            <Messages
              messages={messages}
              selectedMessage={selectedMessage}
              onSelect={(message) => {
                setSelectedMessage((prev) =>
                  prev?.timestamp === message.timestamp ? null : message
                );
              }}
              onIncomingMessages={onIncomingMessages}
            />
          </div>
          {hasNextPage && (
            <Center mt={32}>
              <Button variant="default" onClick={loadMore} loading={isFetching && !isRefetching}>
                Load more
              </Button>
            </Center>
          )}
        </Stack>
      </Grid.Col>
      <Grid.Col sm={5}>
        <DetailsPanel
          children={children}
          message={selectedMessage}
          onCloseMessage={() => {
            setSelectedMessage(null);
          }}
          isLoadingMessages={isLoading}
          hasMessages={messages.length > 0}
        />
      </Grid.Col>
    </Grid>
  );
};
