import React, { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { Text } from '@mantine/core';
import type { XchangeConnectorId } from '../../shared_imports';
import { useCore } from '../../core';

interface Props {
  /** If we already calculated the roi we can pass it */
  roi?: string;
  withColor?: boolean;
  positivePrefix?: string;
  /** Required if "roi" is not provided */
  assetPair?: string;
  /** Required if "roi" is not provided */
  xchange?: XchangeConnectorId | 'paper';
  /** Required if "roi" is not provided */
  openPrice?: string;
}

function RoiComp({
  roi: roiProp,
  assetPair,
  xchange = 'paper',
  positivePrefix = '',
  openPrice,
  withColor,
}: Props) {
  // Validate props
  if (!roiProp && (!assetPair || !openPrice || !xchange)) {
    throw new Error(
      '<Roi /> component requires either (1) "roi" or (2) "assetPair", "openPrice" and "xchange" props to be provided'
    );
  }

  const {
    assets: { pricesService },
  } = useCore();

  // Add roi internal state (string)
  const [roi, setRoi] = useState<BigNumber | undefined>(roiProp ? BigNumber(roiProp) : undefined);

  let prefix = '';
  if (roi && roi.isGreaterThanOrEqualTo(0)) {
    prefix = positivePrefix;
  }

  const isZero = () => {
    if (!roi) {
      return true;
    }

    const float = parseFloat(roi.toFixed(2));
    return float === 0 || Object.is(float, -0);
  };

  const getColor = (value?: BigNumber): 'red' | 'green' | undefined => {
    if (!value || !withColor) {
      return undefined;
    }
    if (isZero() || value.isGreaterThanOrEqualTo(0)) {
      return 'green';
    }
    return 'red';
  };

  useEffect(() => {
    if (!assetPair || !openPrice || !xchange) {
      return () => undefined;
    }

    pricesService.addAssetPairs(xchange, [assetPair]);

    const subscription = pricesService
      .getRoi$(assetPair, openPrice, { format: 'bigNumber' })
      .subscribe((roiValue) => {
        setRoi(roiValue);
      });

    return () => subscription.unsubscribe();
  }, [pricesService, assetPair, openPrice, xchange]);

  // Make sure we don't render "-0.00%"
  const value = !roi || isZero() ? '0.00' : roi.toFixed(2);

  return (
    <Text span color={getColor(roi)}>
      {`${prefix}${value}%`}
    </Text>
  );
}

export const Roi = React.memo(RoiComp);
