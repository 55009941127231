import { useMutation } from '@tanstack/react-query';

import type { UserAction } from '../../shared_imports';
import type { UserService } from './user-service';

export const getMutations = ({ user }: { user: UserService }) => {
  // User
  const useExecuteAction = () => {
    return useMutation({
      mutationFn: ({ action }: { action: UserAction }) => {
        return user.executeAction(action);
      },
    });
  };

  return {
    useExecuteAction,
  };
};

export type UserMutations = ReturnType<typeof getMutations>;
