import type { UseFormReturnType } from '@mantine/form';
import { useCallback, useRef, useState } from 'react';
import type { ChangeEvent, FC } from 'react';
import { TextInput } from '@mantine/core';
import { secondsToTimespan, stringTimespanToSeconds } from '../utils';

interface Props {
  fieldPath: string;
  form: UseFormReturnType<any>;
  label?: string;
  description?: string;
  defaultValue?: number;
  placeholder?: string;
  required?: boolean;
  onChange?: (seconds?: number | null) => void;
  showFormErrors?: boolean;
}

export const TimespanInput: FC<Props> = ({
  fieldPath,
  form,
  label,
  description,
  defaultValue,
  required,
  placeholder = 'e.g 30s, 1m, 15m, 1h10m',
  showFormErrors = true,
  onChange,
}) => {
  const shouldValidate = useRef(false);
  const [timeSpanValue, setTimeSpanValue] = useState(
    defaultValue === undefined ? '' : secondsToTimespan(defaultValue)
  );
  const { setFieldValue, errors, clearFieldError } = form;

  const updateFormValue = useCallback(
    (nextValue: number | null | undefined) => {
      setFieldValue(fieldPath, nextValue);
      if (onChange) {
        onChange(nextValue);
      }
    },
    [setFieldValue, fieldPath, onChange]
  );

  const parseTimeSpan = useCallback(
    (timeSpanStr = timeSpanValue) => {
      let nextValue: number | null | undefined;
      if (timeSpanStr.trim() === '') {
        nextValue = undefined;
      } else {
        nextValue = stringTimespanToSeconds(timeSpanStr);
      }
      return nextValue;
    },
    [timeSpanValue]
  );

  const onTimespanChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      shouldValidate.current = true;
      clearFieldError(fieldPath);
      const { value } = e.currentTarget;
      const formValue = parseTimeSpan(value);
      updateFormValue(formValue);
      setTimeSpanValue(value);
    },
    [clearFieldError, fieldPath, parseTimeSpan, updateFormValue]
  );

  return (
    <TextInput
      label={label}
      description={description}
      placeholder={placeholder}
      value={timeSpanValue}
      onChange={onTimespanChange}
      error={showFormErrors && errors[fieldPath]}
      required={required}
    />
  );
};
