import { useCallback, type FC } from 'react';
import { Text, Title } from '@mantine/core';

import { useFormContext } from '../create-edit-trade-config-form';
import { FormSection } from '../form-section';
import { FormDescriptionRow } from '../form-description-row';
import { TradeConfigIndicators } from '../trade-config-indicators';
import { WebhookUrlPreview } from '../webhook-url-preview';
import { WebhookMessageExamples } from '../webhook-message-examples';
import { TradeConfigExitType, defaultExitType } from '../trade-config-exit-type';
import { TradeConfigConditionsClose } from '../trade-config-conditions-close';

interface Props {
  testModeOn: boolean;
  webhookId?: string;
  showFormErrors: boolean;
}

export const StepClosePosition: FC<Props> = ({ testModeOn, webhookId, showFormErrors }) => {
  const { values, setValues } = useFormContext();

  const {
    __internal__: {
      closeSettings: { trigger },
    },
  } = values;

  const onToggleCloseWithMessage = useCallback(
    (on: boolean) => {
      setValues((prev) => {
        return {
          ...prev,
          __internal__: {
            ...prev.__internal__!,
            closeSettings: {
              ...prev.__internal__!.closeSettings,
              trigger: on ? 'webhook' : 'manual',
              sameIndicatorAsOpen: on,
            },
          },
          exitType: on ? defaultExitType : prev.exitType,
        };
      });
    },
    [setValues]
  );

  return (
    <>
      <FormDescriptionRow>
        <FormDescriptionRow.Description>
          <Title order={2}>Close position</Title>
          <Text>Configure how and when to close a position.</Text>
        </FormDescriptionRow.Description>

        <FormDescriptionRow.Content>
          <FormSection
            title="Close with message"
            description={
              <>
                <Text color="dimmed" size="sm">
                  Close positions sending message(s) to your webhook.
                  <br />
                  Turn it off if you prefer to close positions manually.
                </Text>
              </>
            }
            isToggeable
            toggleDefaultValue={trigger === 'webhook'}
            titleOrder={3}
            onToggle={onToggleCloseWithMessage}
          />

          {trigger === 'webhook' ? (
            <>
              <TradeConfigIndicators side="close" showFormErrors={showFormErrors} />

              <FormSection title="Trigger" titleOrder={3} withSeparator>
                <WebhookUrlPreview webhookId={webhookId} testModeOn={testModeOn} />
                <WebhookMessageExamples
                  formValues={values}
                  side="close"
                  timeSpan={values.indicatorsCloseTimeSpan}
                />
              </FormSection>

              <TradeConfigExitType titleOrder={3} withSeparator />

              <TradeConfigConditionsClose showFormErrors={showFormErrors} />
            </>
          ) : (
            <Text>You will manually close the open positions from your dashboard.</Text>
          )}
        </FormDescriptionRow.Content>
      </FormDescriptionRow>
    </>
  );
};
