import { type FC, useCallback } from 'react';
import { Grid, Text, type TitleOrder } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { TimespanInput } from '../../../../components';
import { useFormContext } from './create-edit-trade-config-form';
import { FormSection } from './form-section';

interface Props {
  titleOrder?: TitleOrder;
  withSeparator?: boolean;
  showFormErrors?: boolean;
}

export const TradeConfigConditionsClose: FC<Props> = ({
  titleOrder = 3,
  withSeparator,
  showFormErrors = false,
}) => {
  const isSmall = useMediaQuery('(max-width: 48em)');
  const form = useFormContext();
  const {
    values: { conditions },
    setFieldValue,
    clearFieldError,
  } = form;

  const defaultIsExpanded = conditions?.close?.minTimeBetweenTrades !== undefined;

  const resetFields = useCallback(() => {
    setFieldValue('conditions.close.minTimeBetweenTrades', undefined);
    clearFieldError('conditions.close.minTimeBetweenTrades');
  }, [clearFieldError, setFieldValue]);

  return (
    <FormSection
      title="Conditions"
      description="Add conditions that must pass to close a position."
      isToggeable
      toggleDefaultValue={defaultIsExpanded}
      titleOrder={titleOrder}
      withSeparator={withSeparator}
      onToggle={(on) => {
        if (!on) {
          resetFields();
        }
      }}
      isLast
    >
      <Grid gutter={isSmall ? 0 : undefined}>
        {/* Time between orders */}
        <Grid.Col sm={5}>
          <Text>Time between orders</Text>
          <Text size="xs" color="dimmed">
            The minimum time that needs to pass since the previous sell order.
          </Text>
        </Grid.Col>
        <Grid.Col sm={7}>
          <TimespanInput
            label="Time between orders"
            fieldPath="conditions.close.minTimeBetweenTrades"
            form={form}
            defaultValue={conditions?.close?.minTimeBetweenTrades}
            showFormErrors={showFormErrors}
          />
        </Grid.Col>
      </Grid>
    </FormSection>
  );
};
