import { FC, useCallback, useEffect, useRef } from 'react';
import { Alert, Button, NumberInput, Text, Modal } from '@mantine/core';
import { IconAlertCircle, IconArrowUp } from '@tabler/icons-react';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCoreState } from '../../../core';
import type { BalanceItem, PaperBalanceItem } from '../shared_imports';
import { useApp } from '../context';
import { useHttpErrorNotification } from '../../../utils';

export const useAddBalance = () => {
  const queryClient = useQueryClient();
  const { getPlugins } = useApp();
  const { portfolio } = getPlugins();
  const [coreState] = useCoreState();

  return useMutation({
    mutationFn: (
      arg:
        | {
            amount: number;
            capitalId: string;
            action: 'increase';
          }
        | {
            amount: number;
            quote: string;
            action: 'create';
          }
    ) => {
      return portfolio.getApi({ coreState }).api.addBalance(arg);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['balances'],
      });
    },
  });
};

interface Props {
  quote: string;
  isLoading?: boolean;
  balance?: BalanceItem | PaperBalanceItem;
  allowIncrease?: boolean;
}

export const BalanceController: FC<Props> = ({
  balance,
  allowIncrease = true,
  isLoading = false,
  quote,
}) => {
  const [{ testModeOn }] = useCoreState();
  const isAddingBalanceRef = useRef(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [amountValue, setAmountValue] = useInputState(0);

  const balanceValueToNumber = Number.parseFloat(balance?.value ?? '0');
  const hasEnoughBalance = Math.ceil(balanceValueToNumber) > 0;
  const {
    mutate: addBalanceMutate,
    isLoading: isAddingBalance,
    error: addBalanceError,
  } = useAddBalance();
  useHttpErrorNotification(addBalanceError, 'Error adding balance');

  const addBalance = useCallback(() => {
    if (amountValue === 0) {
      close();
      return;
    }

    const { capitalId } = (balance as PaperBalanceItem | undefined) ?? {};
    isAddingBalanceRef.current = true;
    if (capitalId) {
      addBalanceMutate({ action: 'increase', amount: amountValue, capitalId });
    } else {
      addBalanceMutate({ action: 'create', amount: amountValue, quote });
    }
  }, [addBalanceMutate, amountValue, balance, close, quote]);

  const renderIncreaseBalance = () => {
    if (!allowIncrease) {
      return null;
    }

    if (testModeOn && hasEnoughBalance) {
      return (
        <Button
          variant="subtle"
          compact
          size="xs"
          pl={0}
          mt="sm"
          leftIcon={<IconArrowUp size="1rem" />}
          onClick={open}
        >
          Increase balance
        </Button>
      );
    }

    return null;
  };

  const renderNoBalanceWarning = () => {
    if (!isLoading && !hasEnoughBalance) {
      return (
        <Alert
          mt="sm"
          mb="sm"
          icon={<IconAlertCircle size="1rem" />}
          color="yellow"
          variant="outline"
        >
          <Text>You don't have balance to trade this pair.</Text>
          {testModeOn && (
            <Button variant="outline" color="yellow" mt="sm" compact onClick={open}>
              Add balance
            </Button>
          )}
        </Alert>
      );
    }
    return null;
  };

  useEffect(() => {
    if (!isAddingBalance && isAddingBalanceRef.current) {
      isAddingBalanceRef.current = false;
      setAmountValue(0);
      close();
    }
  }, [isAddingBalance, close, setAmountValue]);

  return (
    <div>
      {renderIncreaseBalance()}
      {renderNoBalanceWarning()}

      <Modal opened={opened} onClose={close} title={`Add ${quote} balance`}>
        <NumberInput
          label="Amount"
          decimalSeparator="."
          precision={2}
          value={amountValue}
          onChange={(value) => {
            setAmountValue(value === '' ? 0 : value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              addBalance();
            }
          }}
          required
          data-autofocus
        />
        <Button
          fullWidth
          onClick={() => {
            addBalance();
          }}
          loading={isAddingBalance}
          mt="md"
        >
          Submit
        </Button>
      </Modal>
    </div>
  );
};
