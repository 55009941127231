import type { Plugin } from './types';

export class AppPlugins {
  private _plugins = new Map<string, Plugin<any, any>>();

  registerPlugin(plugin: Plugin<any, any>) {
    if (this._plugins.has(plugin.id)) {
      throw new Error(`Plugin [${plugin.id}] has already been registered.`);
    }

    this._plugins.set(plugin.id, plugin);
  }

  get plugins() {
    return this._plugins;
  }

  get(id: string) {
    return this._plugins.get(id);
  }
}

export default AppPlugins;
