import { ReactNode, useState } from 'react';
import type { FC } from 'react';
import {
  Box,
  Flex,
  type MantineSize,
  Switch,
  Text,
  Title,
  type TitleOrder,
  Divider,
} from '@mantine/core';

interface Props {
  children?: ReactNode;
  description?: ReactNode;
  isContentVisible?: boolean;
  isLast?: boolean;
  isToggeable?: boolean;
  onToggle?: (isOn: boolean) => void;
  switchSize?: MantineSize;
  showDescription?: 'always' | 'whenHidden';
  title?: string;
  /** The title order (h1, h2, h3...) */
  titleOrder?: TitleOrder;
  toggleDefaultValue?: boolean;
  withSeparator?: boolean;
}

export const FormSection: FC<Props> = ({
  children,
  description,
  isContentVisible: _isContentVisible,
  isLast = false,
  isToggeable = false,
  onToggle,
  showDescription = 'always',
  switchSize,
  title,
  titleOrder = 2,
  toggleDefaultValue,
  withSeparator = false,
}) => {
  const isControlled = _isContentVisible !== undefined;
  const [isContentVisiblesState, setIsContentVisible] = useState(
    toggleDefaultValue ?? !isToggeable
  );
  const isContentVisible = isControlled ? _isContentVisible : isContentVisiblesState;

  const renderDescription = () => {
    if (!description) {
      return null;
    }

    if (isContentVisible && showDescription === 'whenHidden') {
      return null;
    }

    if (typeof description === 'string') {
      return (
        <Text color="dimmed" size="sm">
          {description}
        </Text>
      );
    }

    return description;
  };

  return (
    <>
      <Box
        component="section"
        sx={(theme) => {
          return {
            marginBottom: isLast || withSeparator ? 0 : `calc(${theme.spacing.xl} * 2)`,
          };
        }}
      >
        <Box mb="sm">
          <Flex align="center" gap="md">
            {isToggeable && (
              <Switch
                checked={isContentVisible}
                onChange={(event) => {
                  const isOn = event.currentTarget.checked;
                  setIsContentVisible(isOn);
                  if (onToggle) {
                    onToggle(isOn);
                  }
                }}
                size={switchSize}
              />
            )}
            {title && <Title order={titleOrder}>{title}</Title>}
          </Flex>
          {renderDescription()}
        </Box>

        {isContentVisible && children}
      </Box>

      {withSeparator && (
        <Divider
          sx={(theme) => {
            return {
              margin: `calc(${theme.spacing.xl} * 1.5) 0`,
            };
          }}
        />
      )}
    </>
  );
};
