import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Flex, Overlay } from '@mantine/core';
import { PageHeader } from './pageHeader';
import type { NavLink } from './pageHeader';
import { Footer } from './footer';

interface Props {
  mainNavLinks: NavLink[];
}

export const Layout: FC<Props> = ({ mainNavLinks }) => {
  const [isMobileMenuOpened, setMobileMenuOpened] = useState(false);

  return (
    <>
      <Flex direction="column" justify="space-between" sx={{ height: '100vh' }}>
        <div>
          <PageHeader mainNavLinks={mainNavLinks} onMobileMenuOpened={setMobileMenuOpened} />
          <Outlet />
        </div>
        <Footer />
      </Flex>
      {isMobileMenuOpened && <Overlay color="#000" opacity={0.45} blur={3} zIndex={2} />}
    </>
  );
};

export default Layout;
