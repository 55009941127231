import type { Core, CoreState } from '../../../core';
import type {
  ListPositionsResponse,
  ListPositionsQueryParams,
  ClosePositionsResponse,
} from '../../../shared_imports';
import { stripUndefinedValues } from '../../../utils';

import { init as initQueries } from './queries';

const initApi = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const getPositions = (
    params: ListPositionsQueryParams = {},
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<ListPositionsResponse>('/positions', stripUndefinedValues(params), {
      userAccount: testModeOn ? 'paper' : 'root',
    });
  };

  const closePosition = (
    id: number,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.post<ClosePositionsResponse>(`/positions/${id}/close`, undefined, {
      userAccount: testModeOn ? 'paper' : 'root',
    });
  };

  const api = {
    getPositions,
    closePosition,
  };

  return api;
};

export const init = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const api = initApi({ httpClient, coreState });
  const queries = initQueries({ api, coreState });

  return {
    api,
    queries,
  };
};

export type API = ReturnType<typeof initApi>;
