import { useRef, type FC, useEffect, useCallback } from 'react';
import { Alert, Box, Flex, Group, Stack, Switch, Text, Title } from '@mantine/core';

import {
  IconAlertCircle,
  IconBrandTelegram,
  IconFileSpreadsheet,
  IconMailForward,
} from '@tabler/icons-react';
import { useSearchParams } from 'react-router-dom';

import { useFormContext } from '../create-edit-trade-config-form';
import { AddGsheetConnector } from '../../../shared_imports';
import { FormDescriptionRow } from '../form-description-row';
import { useTradeConfigFormContext } from '../create-edit-trade-config';

interface Props {
  showFormErrors: boolean;
}

export const StepOutputActions: FC<Props> = ({ showFormErrors }) => {
  const { getInputProps, values, clearFieldError } = useFormContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const accessTokenFromQuery = useRef<string | undefined>(undefined);

  const { userConnectors, gsheetConnector } = useTradeConfigFormContext();

  const gsheetEnabled = values.pipesConfig.gsheet.enabled;

  const gsheetFileTitle = gsheetConnector?.files?.[0]?.title;
  const userConnectorsLoaded = Array.isArray(userConnectors);
  if (!accessTokenFromQuery.current) {
    accessTokenFromQuery.current = searchParams.get('access_token') ?? undefined;
  }

  const clearQueryParams = useCallback(() => {
    [...searchParams.entries()].forEach(([key]) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  }, [setSearchParams, searchParams]);

  useEffect(() => {
    clearFieldError('pipesConfig.gsheet.enabled');
  }, [clearFieldError, gsheetConnector]);

  useEffect(() => {
    return () => {
      clearQueryParams();
    };
  }, [clearQueryParams]);

  const { error: gsheetSwitchError, ...gsheetSwitchProps } = getInputProps(
    'pipesConfig.gsheet.enabled',
    {
      type: 'checkbox',
    }
  );

  return (
    <>
      <FormDescriptionRow>
        <FormDescriptionRow.Description>
          <Title order={2}>Output</Title>
          <Text>Where should we forward the positions?</Text>
        </FormDescriptionRow.Description>

        <FormDescriptionRow.Content>
          {/* Google sheet */}
          <Stack
            sx={(theme) => ({
              paddingBottom: theme.spacing.md,
              marginBottom: theme.spacing.md,
              borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[3]
              }`,
            })}
            spacing="sm"
          >
            <Flex justify="space-between">
              <Group>
                <IconFileSpreadsheet />
                <Text>Google sheet</Text>
              </Group>
              <Flex direction="column" align="flex-end">
                <Switch {...gsheetSwitchProps} size="xs" />
                {showFormErrors && !!gsheetSwitchError && (
                  <Text mt="sm" color="red" size="xs">
                    {gsheetSwitchError}
                  </Text>
                )}
              </Flex>
            </Flex>
            {gsheetEnabled && (
              <>
                {gsheetConnector ? (
                  <>
                    {gsheetFileTitle ? (
                      <Text color="dimmed" ff="monospace">
                        {gsheetFileTitle}
                      </Text>
                    ) : (
                      <Box>
                        <AddGsheetConnector
                          buttonVariant="outline"
                          accessTokenFromQuery={accessTokenFromQuery.current}
                          userConnectors={userConnectors}
                        />
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    {userConnectorsLoaded && (
                      <Alert
                        icon={<IconAlertCircle size="1rem" />}
                        title="Google sheet connector missing"
                        color="yellow"
                      >
                        <Text mb="md">
                          You need to authorize WeTradeTime to access one of your Google Sheets.
                        </Text>
                        <AddGsheetConnector buttonVariant="outline" withBackgroundColor={false} />
                      </Alert>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>

          {/* Email */}
          <Flex
            justify="space-between"
            sx={(theme) => ({
              paddingBottom: theme.spacing.md,
              marginBottom: theme.spacing.md,
              borderBottom: `1px solid ${
                theme.colorScheme === 'dark' ? theme.colors.gray[7] : theme.colors.gray[3]
              }`,
            })}
          >
            <Group>
              <IconMailForward />
              <Text>
                Email{' '}
                <Text span size="sm" fw={700}>
                  (soon)
                </Text>
              </Text>
            </Group>
            <Switch disabled size="xs" />
          </Flex>

          {/* Telegram */}
          <Flex justify="space-between">
            <Group>
              <IconBrandTelegram />

              <Text>
                Telegram{' '}
                <Text span size="sm" fw={700}>
                  (soon)
                </Text>
              </Text>
            </Group>
            <Switch disabled size="xs" />
          </Flex>
        </FormDescriptionRow.Content>
      </FormDescriptionRow>
    </>
  );
};
