import { BehaviorSubject, Observable, map } from 'rxjs';
import BigNumber from 'bignumber.js';

import type {
  BulkGetAssetPairsPricesBody,
  GetAssetPairsPricesResponse,
  XchangeConnectorId,
} from '../../shared_imports';
import { RESULT_DECIMALS } from '../../constants';
import { INTERVAL_REFRESH_LIVE_PRICE } from '../constants';
import type { Core } from '../core';
import type { CoreState } from '..';

export class AssetsPriceService {
  private lastFetchTime = 0;

  private isLoggedIn = false;

  private debounceTimeout: NodeJS.Timeout | null = null;

  private intervalTimeout: NodeJS.Timeout | null = null;

  private _assetPrices$ = new BehaviorSubject<GetAssetPairsPricesResponse>({});

  private assetPairsByXchange: Record<string, Set<string>> = {};

  private assetPairsRequested: Set<string> = new Set<string>();

  private http: Core['httpClient'];

  constructor({ http }: { http: Core['httpClient'] }) {
    this.http = http;
  }

  public setup({ coreState$ }: { coreState$: Observable<CoreState> }) {
    coreState$.subscribe((state) => {
      this.isLoggedIn = state.auth.isLoggedIn ?? false;

      // Whenever the window loses focus we stop fetching prices
      if (state.isWindowFocused) {
        // Prevent users from focusing in and out of the window and triggering a fetch
        if (Date.now() - this.lastFetchTime > INTERVAL_REFRESH_LIVE_PRICE) {
          this.fetchAssetPrices();
        }
        this.setIntervalFetchPrice();
      } else {
        clearInterval(this.intervalTimeout!);
      }
    });
  }

  public addAssetPairs = (xchange: XchangeConnectorId | 'paper', _assetPairs: string[]) => {
    const assetPairs = _assetPairs.filter((assetPair) => {
      return assetPair.trim().length > 0;
    });

    if (assetPairs.length === 0) {
      return;
    }

    if (this.debounceTimeout) {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = null;
    }

    this.assetPairsByXchange[xchange] = this.assetPairsByXchange[xchange] ?? new Set<string>();

    assetPairs.forEach((assetPair) => {
      if (!this.exists(assetPair)) {
        this.assetPairsByXchange[xchange]!.add(assetPair);
        this.assetPairsRequested.add(assetPair);
      }
    });

    if (this.assetPairsRequested.size > 0) {
      // Group multiple parallel request into one (wait 300ms before executing...)
      this.debounceTimeout = setTimeout(() => {
        this.fetchAssetPrices();
        this.setIntervalFetchPrice();
        this.assetPairsRequested.clear();
        this.debounceTimeout = null;
      }, 300);
    }
  };

  private async fetchAssetPrices() {
    if (!this.isLoggedIn) {
      return;
    }
    const body = Object.entries(this.assetPairsByXchange).reduce<BulkGetAssetPairsPricesBody>(
      (acc, [xchange, assetPairs]) => {
        acc[xchange] = Array.from(assetPairs);
        return acc;
      },
      {}
    );
    if (Object.keys(body).length === 0) {
      return;
    }

    this.lastFetchTime = Date.now();

    const prices = await this.http.post<GetAssetPairsPricesResponse, BulkGetAssetPairsPricesBody>(
      '/crypto/asset-pairs/bulk-get-price',
      { body }
    );
    if (prices) {
      this._assetPrices$.next(prices);
    }
  }

  /**
   * Checks if the asset pair exists in the assetPairsByXchange object
   * @param assetPair The asset pair to check
   */
  private exists(assetPair: string) {
    return Object.values(this.assetPairsByXchange).some((set) => set.has(assetPair));
  }

  // Setup interval to fetch prices every 5 seconds
  private setIntervalFetchPrice() {
    if (this.intervalTimeout) {
      clearInterval(this.intervalTimeout);
    }

    this.intervalTimeout = setInterval(() => {
      if (!this.debounceTimeout && Object.keys(this.assetPairsByXchange).length > 0) {
        this.fetchAssetPrices();
      }
    }, INTERVAL_REFRESH_LIVE_PRICE);
  }

  get assetPrices$() {
    return this._assetPrices$.asObservable();
  }

  public calculateRoi(
    openPrice: string,
    currentPrice: string,
    options: { format: 'bigNumber' }
  ): BigNumber;

  public calculateRoi(
    openPrice: string,
    currentPrice: string,
    options?: { format?: 'string' }
  ): string;

  // Note: this is the trick to avoid the TS error. We need to add this extra overload
  // "The call would have succeeded against this implementation, but implementation
  // signatures of overloads are not externally visible."
  public calculateRoi<O>(openPrice: string, currentPrice: string, options: O): string | BigNumber;

  public calculateRoi(
    openPrice: string,
    currentPrice: string,
    { format = 'string' }: { format?: 'string' | 'bigNumber' } = {}
  ) {
    const openPriceBN = BigNumber(openPrice);
    const currentPriceBN = BigNumber(currentPrice);

    if (openPriceBN.isZero() || currentPriceBN.isZero()) {
      return format === 'bigNumber' ? BigNumber(0) : '0';
    }

    const roi = currentPriceBN.dividedBy(openPriceBN).minus(1).multipliedBy(100);

    if (format === 'bigNumber') {
      return roi;
    }

    const toString = roi.toFixed(2);
    return toString === '-0.00' ? '0.00' : toString;
  }

  public calculatePl(openPrice: string, currentPrice: string, size: string) {
    const openPriceBN = BigNumber(openPrice);
    const currentPriceBN = BigNumber(currentPrice);
    if (openPriceBN.isZero() || currentPriceBN.isZero()) {
      return '';
    }
    const value = currentPriceBN.minus(openPriceBN).multipliedBy(size).toFixed(RESULT_DECIMALS);
    if (parseFloat(value) === 0 && value.startsWith('-')) {
      return '0';
    }
    return value;
  }

  getPrice$(assetPair: string): Observable<string> {
    return this.assetPrices$.pipe(
      map((prices) => prices[assetPair]),
      map((assetPrice) => {
        const { price } = assetPrice ?? { price: '0' };
        return price;
      })
    );
  }

  public getRoi$(
    assetPair: string,
    openPrice: string,
    options: { format: 'bigNumber' }
  ): Observable<BigNumber>;

  public getRoi$(
    assetPair: string,
    openPrice: string,
    options?: { format?: 'string' }
  ): Observable<string>;

  getRoi$(
    assetPair: string,
    openPrice: string,
    options?: { format?: 'string' | 'bigNumber' }
  ): Observable<string | BigNumber> {
    return this.assetPrices$.pipe(
      map((prices) => prices[assetPair.toUpperCase()]),
      map((assetPrice) => {
        const { price } = assetPrice ?? { price: '0' };
        return this.calculateRoi(openPrice, price, options);
      })
    );
  }

  getPl$(assetPair: string, openPrice: string, size: string): Observable<string> {
    return this.assetPrices$.pipe(
      map((prices) => prices[assetPair.toUpperCase()]),
      map((assetPrice) => {
        const { price } = assetPrice ?? { price: '0' };
        return this.calculatePl(openPrice, price, size);
      })
    );
  }
}
