import { ENV } from '../constants';
import type { State } from './core';

const getDefaultValueTestMode = () => {
  const value = window.localStorage.getItem('testModeOn');
  if (value) {
    try {
      return JSON.parse(value);
    } catch (env) {
      return false;
    }
  }
  return false;
};

export const stateDefaultValue: State = {
  user: null,
  auth: {
    isLoggedIn: null,
  },
  testModeOn: getDefaultValueTestMode(),
  isWindowFocused: true,
};

export const INTERVAL_REFRESH_LIVE_PRICE = ENV === 'dev' ? 10000 : 5000;

export const DELETED_USER_LOCAL_STORAGE_KEY = 'deletedUser';

export const PUBLIC_WEBSITE = process.env.REACT_APP_PUBLIC_WEBSITE ?? 'https://wetradetime.com';
