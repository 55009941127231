import BigNumber from 'bignumber.js';

export const isPlainObject = (o: unknown): o is Record<string, unknown> => {
  return typeof o === 'object' && o !== null && !Array.isArray(o);
};

export const stripUndefinedValues = <T>(o: T): Required<T> | undefined => {
  if (o === undefined || !isPlainObject(o)) {
    return undefined;
  }

  return Object.entries(o).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc;
    }
    return {
      ...acc,
      [key]: value,
    };
  }, {} as Required<T>);
};

export const isBrowser = () => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined';
};

/**
 * Chunks an array in smaller chunks.
 */
export const chunkArray = <T extends any[]>(array: T, size: number): T[] => {
  const chunkedArray: T[] = [];
  let index = 0;

  while (index < array.length) {
    chunkedArray.push(array.slice(index, size + index) as T);
    index += size;
  }

  return chunkedArray;
};

/**
 * Test if localStorage API is available
 * From https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#Feature-detecting_localStorage
 * @returns {boolean}
 */
export function localStorageAvailable(): boolean {
  try {
    const x = `wetradetime/local-storage:${new Date().toISOString()}`;
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (e) {
    return (
      isBrowser() &&
      e instanceof DOMException &&
      // everything except Firefox
      (e.code === 22 ||
        // Firefox
        e.code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === 'QuotaExceededError' ||
        // Firefox
        e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
      // acknowledge QuotaExceededError only if there's something already stored
      localStorage &&
      localStorage.length !== 0
    );
  }
}

/**
 * NOTE: This code is a copy of the same util in the dashboard/client code base
 *       There is no way currently to share code between the client and the api so if this
 *       utils is updated we need to update it in the client.
 * Parse timestamp to an object of hour, minute and seconds
 * e.g "15m30s" => { "m": 15, "s": 30 }
 */
export const parseTimespan = (
  value: string,
  units: Array<'h' | 'm' | 's'> = ['h', 'm', 's'],
  agg: { h?: number; m?: number; s?: number } = {}
): { h?: number; m?: number; s?: number } => {
  const [unit] = units;
  if (unit === undefined) {
    return agg;
  }

  if (!value) {
    return parseTimespan(value, units.slice(1), agg);
  }

  const [num, rest] = value.split(unit);

  if (num === undefined || rest === undefined) {
    return parseTimespan(value, units.slice(1), agg);
  }

  const nextAgg = { ...agg, [unit]: parseInt(num, 10) };

  const remainderIsInteger = /^(\d+)$/.test(rest);

  const nextUnit = units[1];
  if (remainderIsInteger && nextUnit !== undefined) {
    nextAgg[nextUnit] = parseInt(rest, 10);
    return nextAgg;
  }

  return parseTimespan(rest, units.slice(1), nextAgg);
};

/**
 * NOTE: This code is a copy of the same util in API (workspace/api/src/common/utils.ts).
 *       There is no way currently to share code between the client and the api so if this
 *       utils is updated we need to update it in the API.
 * Parse a time span in human readable format back to seconds
 * @param timespan The time span to parse
 * @returns The time span in human readable format
 */
export const stringTimespanToSeconds = (timespan: string) => {
  const regEx =
    /^(\d+s)$|^(\d+m)$|^(\d+h)$|^(\d+m\d+s?)$|^(\d+h\d+s)$|^(\d+h\d+m?)$|^(\d+h\d+m\d+s)$/gm;
  const res = regEx.exec(timespan);

  if (res === null) {
    return res;
  }

  const parsed = parseTimespan(timespan);

  return Object.entries(parsed).reduce((acc, [unit, value]) => {
    if (unit === 'h') {
      return acc + value * 3600;
    }
    if (unit === 'm') {
      return acc + value * 60;
    }
    return acc + value;
  }, 0);
};

/**
 * NOTE: This code is a copy of the same util in API (workspace/api/src/common/utils.ts).
 *       There is no way currently to share code between the client and the api so if this
 *       utils is updated we need to update it in the API.
 * Convert seconds into a human readable time span
 * @param _seconds The second to convert
 * @returns The seconds converted to human readable format
 */
export const secondsToTimespan = (_seconds?: number) => {
  if (_seconds === undefined) {
    return '';
  }

  const hours = Math.floor(_seconds / 3600);
  const minutes = Math.floor((_seconds - hours * 3600) / 60);
  const seconds = _seconds - minutes * 60 - hours * 60 * 60;
  let timestamp = '';
  if (hours) {
    timestamp += `${hours}h`;
  }
  if (minutes) {
    timestamp += `${minutes}m`;
  }
  if (seconds) {
    timestamp += `${seconds}s`;
  }
  return timestamp;
};

export const renderDecimalValue = (
  value: string,
  { quote = '', decimals: _decimals = 5 }: { quote?: string; decimals?: number } = {}
): string => {
  const prefix = quote === 'USD' ? '$' : '';

  const bNumber = new BigNumber(value);

  if (bNumber.isNaN()) {
    return value;
  }

  if (bNumber.isZero()) {
    return `${prefix}0.00`;
  }

  const decimals = ['USD', 'EUR'].includes(quote) ? 2 : _decimals;

  const formatted = bNumber.toFormat(decimals, {
    decimalSeparator: '.',
    prefix,
    groupSeparator: ',',
    groupSize: 3,
    secondaryGroupSize: 0,
  });

  return formatted;
};
