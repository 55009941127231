import { useEffect, useState } from 'react';
import {
  createStyles,
  Header,
  Container,
  Group,
  Flex,
  Burger,
  Paper,
  Transition,
  // Avatar,
  UnstyledButton,
  Text,
  Menu,
  MediaQuery,
  ActionIcon,
  useMantineColorScheme,
  Switch,
  Box,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconLogout,
  IconSettings,
  IconChevronDown,
  IconSun,
  IconMoonStars,
} from '@tabler/icons-react';
import { Link, useNavigate, useMatches } from 'react-router-dom';

import { useCore, useCoreState } from '../core';
import { useClickOutside } from '../utils/use-click-outside';

const useStyles = createStyles((theme, { testModeOn }: { testModeOn: boolean }) => {
  return {
    root: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      borderColor: testModeOn ? theme.colors.red[5] : undefined,
      zIndex: 3,
    },

    // --- Mobile menu
    mainNavMobile: {
      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },

    dropdown: {
      position: 'absolute',
      top: 64,
      left: 0,
      right: 0,
      zIndex: 3,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
      borderTopWidth: 0,
      overflow: 'hidden',

      [theme.fn.largerThan('sm')]: {
        display: 'none',
      },
    },

    // --- Top bar
    topBar: {
      display: 'flex',
      width: '100%',
      justifyContent: 'flex-end',
      marginTop: theme.spacing.xs,
    },

    user: {
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      borderRadius: theme.radius.sm,
      transition: 'background-color 100ms ease',

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
      },

      [theme.fn.smallerThan('xs')]: {
        display: 'none',
      },
    },

    userActive: {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
    },

    mainNavDesktop: {
      [theme.fn.smallerThan('sm')]: {
        display: 'none',
      },
    },

    // --- Main menu
    menu: {
      display: 'flex',
      width: '100%',
      height: '100%',
      alignItems: 'center',
    },

    link: {
      display: 'block',
      lineHeight: 1,
      padding: '8px 12px',
      borderRadius: theme.radius.sm,
      textDecoration: 'none',
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
      fontSize: theme.fontSizes.sm,
      fontWeight: 500,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
      },

      [theme.fn.smallerThan('sm')]: {
        borderRadius: 0,
        padding: theme.spacing.md,
      },
    },

    linkActive: {
      '&, &:hover': {
        backgroundColor: theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .background,
        color: theme.fn.variant({ variant: 'light', color: theme.primaryColor }).color,
      },
    },

    labelActive: {
      color: theme.colors.red[5],
    },

    paperTradeIndicator: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
      height: theme.spacing.md,
      lineHeight: theme.spacing.md,
      bottom: `calc(${theme.spacing.md} * -1)`,
      padding: '0 4px',
      fontSize: theme.fontSizes.xs,
      backgroundColor: theme.colors.red[5],
      color: theme.white,
      borderRadius: `0 0 ${theme.radius.sm} ${theme.radius.sm}`,
    },
  };
});

export interface NavLink {
  label: string;
  link: string;
}

interface Props {
  mainNavLinks: NavLink[];
  onMobileMenuOpened: (isOpened: boolean) => void;
}

export function PageHeader({ mainNavLinks, onMobileMenuOpened }: Props) {
  const { auth } = useCore();
  const [{ user, testModeOn }, set] = useCoreState();
  const matches = useMatches();
  const currentRoutePathname = matches[matches.length - 1]!.pathname;

  const [opened, { toggle, close }] = useDisclosure(false);
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const ref = useClickOutside((e) => {
    if (e.target.classList.contains('mantine-Burger-burger')) {
      return;
    }
    e.stopPropagation();
    close();
  });
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { classes, cx, theme } = useStyles({ testModeOn });
  const navigate = useNavigate();
  const isMobileScreen = useMediaQuery(`(max-width: ${theme.breakpoints.xs}px)`);

  const dark = colorScheme === 'dark';
  const headerHeight = isMobileScreen ? 64 : 86;

  const logOut = () => {
    auth.logout();
  };

  const renderPaperTradeSwitch = () => {
    return (
      <Switch
        label="Test mode"
        color="red"
        labelPosition="left"
        size="xs"
        checked={testModeOn}
        onChange={(e) => {
          set.testModeOn(e.currentTarget.checked);
        }}
        classNames={{ labelWrapper: testModeOn ? classes.labelActive : undefined }}
      />
    );
  };

  const items = mainNavLinks.map((link) => (
    <Link
      key={link.link}
      to={link.link}
      className={cx(classes.link, {
        [classes.linkActive]: currentRoutePathname.includes(link.link),
      })}
      onClick={() => {
        close();
      }}
    >
      {link.label}
    </Link>
  ));

  useEffect(() => {
    onMobileMenuOpened(opened);
  }, [onMobileMenuOpened, opened]);

  return (
    <Header height={headerHeight} className={classes.root}>
      {/* Top bar */}
      <MediaQuery smallerThan="sm" styles={{ display: 'none' }}>
        <Container size="lg" className={classes.topBar} px="xl">
          <Flex align="center" gap="sm">
            <ActionIcon
              variant="outline"
              sx={{ color: dark ? theme.colors.yellow[5] : theme.colors.blue[5] }}
              color={dark ? 'yellow' : 'blue'}
              onClick={() => toggleColorScheme()}
              title="Toggle color scheme"
              size="sm"
            >
              {dark ? <IconSun size={16} /> : <IconMoonStars size={16} />}
            </ActionIcon>
            <Menu
              width={260}
              position="bottom-end"
              transitionProps={{ transition: 'pop-top-right' }}
              onClose={() => setUserMenuOpened(false)}
              onOpen={() => setUserMenuOpened(true)}
              offset={0}
            >
              <Menu.Target>
                <UnstyledButton
                  className={cx(classes.user, { [classes.userActive]: userMenuOpened })}
                >
                  <Group spacing={7}>
                    {/* <Avatar src={user.image} alt={user.name} radius="xl" size={20} /> */}
                    <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                      {user?.username}
                    </Text>
                    <IconChevronDown size={12} stroke={1.5} />
                  </Group>
                </UnstyledButton>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  icon={<IconSettings size={14} stroke={1.5} />}
                  onClick={() => navigate('/profile')}
                >
                  Profile
                </Menu.Item>
                <Menu.Item
                  icon={<IconLogout size={14} stroke={1.5} />}
                  onClick={() => {
                    logOut();
                  }}
                >
                  Logout
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Container>
      </MediaQuery>

      {/* Main menu */}
      <Container size="lg" className={classes.menu} px="xl">
        <Flex justify="space-between" w="100%" align="flex-end" className={classes.mainNavDesktop}>
          <Group spacing={5}>{items}</Group>
          {renderPaperTradeSwitch()}
        </Flex>

        <Flex justify="space-between" align="flex-end" w="100%" className={classes.mainNavMobile}>
          <Burger opened={opened} onClick={toggle} size="sm" />
          {renderPaperTradeSwitch()}
        </Flex>

        <Transition transition="fade" duration={100} mounted={opened} timingFunction="easeOut">
          {(styles) => (
            <Paper ref={ref} className={classes.dropdown} withBorder style={styles}>
              <>
                {items}
                <Box
                  mt="lg"
                  p="md"
                  sx={{
                    borderTop: `1px solid ${
                      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
                    }`,
                  }}
                >
                  <Flex justify="space-between">
                    <Text size="sm">Dark mode</Text>
                    <Switch
                      checked={colorScheme === 'dark'}
                      onChange={() => {
                        toggleColorScheme();
                      }}
                    />
                  </Flex>
                </Box>
              </>
            </Paper>
          )}
        </Transition>
      </Container>

      {testModeOn && <Box className={classes.paperTradeIndicator}>Test mode</Box>}
    </Header>
  );
}

export default PageHeader;
