// import home from './home';
import positions from './positions';
import portfolio from './portfolio';
import trades from './trades';
import connectors from './connectors';
import user from './user';
import webhook from './webhook';
import stats from './stats';

const plugins = {
  // home,
  positions,
  portfolio,
  trades,
  user,
  connectors,
  webhook,
  stats,
};

export default plugins;

export type { UserPluginStart } from './user';
