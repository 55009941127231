import type { Core, CoreState, Plugin } from '../../core';
import { PLUGIN_ID } from './constants';
import type { PluginStart } from './types';
import { init as initApp } from './app';
import { init as initApi } from './api';
import type { AppContext } from './context';

export class WebhookPlugin implements Plugin {
  id = PLUGIN_ID;

  setup(core: Core) {
    const context: AppContext = {
      i18n: core.i18n,
      httpClient: core.httpClient,
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
    const { routes } = initApp(context);
    core.router.registerRoutes(routes);
  }

  start(core: Core): PluginStart {
    return {
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
  }
}

export default WebhookPlugin;
