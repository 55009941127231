import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApp } from '../context';
import type {
  CreateTradeConfigPayload,
  ListTradeConfigsResponse,
  TradeConfig,
  UpdateTradeConfigPayload,
} from '../shared_imports';
import { useCoreState } from '../../../core';

export const useCreateTradeConfig = () => {
  const queryClient = useQueryClient();
  const { getApi } = useApp();
  const [coreState] = useCoreState();

  return useMutation({
    mutationFn: ({
      tradeName,
      tradeConfig,
    }: {
      tradeName: string;
      tradeConfig: CreateTradeConfigPayload;
    }) => {
      return getApi({ coreState }).api.create(tradeName, tradeConfig);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['tradeConfigs'],
      });
      queryClient.invalidateQueries({
        queryKey: ['stats', 'trades'],
      });
    },
  });
};

export const useUpdateTradeConfig = () => {
  const queryClient = useQueryClient();
  const { getApi } = useApp();
  const [coreState] = useCoreState();

  return useMutation({
    mutationFn: ({
      tradeName,
      tradeConfig,
    }: {
      tradeName: string;
      tradeConfig: UpdateTradeConfigPayload;
    }) => {
      return getApi({ coreState }).api.update(tradeName, tradeConfig);
    },
    onMutate: async ({ tradeName, tradeConfig }) => {
      await queryClient.cancelQueries({ queryKey: ['tradeConfigs'] });

      // Snapshot the previous value
      const previousTradeConfigs = queryClient.getQueryData(['tradeConfigs']);

      // Optimistically update to the new value
      queryClient.setQueryData<{ pages: Array<ListTradeConfigsResponse> }>(
        ['tradeConfigs'],
        (old) => {
          if (!old) {
            return old;
          }

          const list = old !== undefined ? old.pages?.[0] ?? [] : [];
          const updatedList = list.map((config) => {
            if (config.tradeName === tradeName) {
              const { outputs, status } = tradeConfig;
              const [output] = outputs ?? [];
              const { xchange } = output ?? {};
              const [previsouOutput] = config.outputs;

              const merged: TradeConfig = {
                ...config,
                status: status ?? config.status,
                outputs: [
                  {
                    ...previsouOutput,
                    xchange: xchange ?? previsouOutput.xchange,
                  },
                ],
                conditions: {
                  ...config.conditions,
                  ...tradeConfig.conditions,
                },
              };

              return merged;
            }
            return config;
          });

          const updated = {
            ...old,
            pages: [updatedList],
          };
          return updated;
        }
      );

      // Return a context object with the snapshotted value
      return { previousTradeConfigs, tradeName, tradeConfig };
    },
    // If the mutation fails,
    // use the context returned from onMutate to roll back
    onError: (_, __, context) => {
      queryClient.setQueryData(['tradeConfigs'], context?.previousTradeConfigs ?? []);
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tradeConfigs'] });
      queryClient.invalidateQueries({
        queryKey: ['stats', 'trades'],
      });
    },
  });
};

export const useDeleteTradeConfig = ({ testModeOn }: { testModeOn: boolean }) => {
  const { getApi } = useApp();
  const [coreState] = useCoreState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      tradeName,
      closePositions = false,
    }: {
      tradeName: string;
      closePositions?: boolean;
    }) => {
      return getApi({ coreState }).api.deleteTradeConfig(tradeName, { testModeOn, closePositions });
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['tradeConfigs', testModeOn] });
      // Prevent showing previous webhook messages for tradeConfig with same name (if re-created)
      queryClient.removeQueries({
        queryKey: ['webhook', 'messages'],
        exact: false,
      });
      queryClient.invalidateQueries({
        queryKey: ['stats', 'trades'],
      });
    },
  });
};
