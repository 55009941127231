import type { Core, CoreState } from '../../../core';
import type {
  GetPositionsStatsQueryParams,
  GetPositionsStatsResponse,
  GetTradeConfigStatsResponse,
  ListTradeConfigStatsQueryParams,
  ListTradeConfigStatsResponse,
  GetGlobalTradesStatsQueryParams,
  GetGlobalTradesStatsResponse,
} from '../shared_imports';
import { stripUndefinedValues } from '../../../utils';

import { init as initQueries } from './queries';

const initApi = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const listTradeConfigStats = (
    params: ListTradeConfigStatsQueryParams,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<ListTradeConfigStatsResponse>(
      '/stats/trade-configs',
      stripUndefinedValues(params),
      {
        userAccount: testModeOn ? 'paper' : 'root',
      }
    );
  };

  const getTradeConfigStats = (
    tradeId: string,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<GetTradeConfigStatsResponse>(
      `/stats/trade-configs/${tradeId}`,
      undefined,
      {
        userAccount: testModeOn ? 'paper' : 'root',
      }
    );
  };

  const getPositionsStats = (
    params: GetPositionsStatsQueryParams,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<GetPositionsStatsResponse>(
      '/stats/positions',
      stripUndefinedValues(params),
      {
        userAccount: testModeOn ? 'paper' : 'root',
      }
    );
  };

  const getGlobalTradesStats = (
    params: GetGlobalTradesStatsQueryParams,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<GetGlobalTradesStatsResponse>(
      '/stats/trades',
      stripUndefinedValues(params),
      {
        userAccount: testModeOn ? 'paper' : 'root',
      }
    );
  };

  const api = {
    listTradeConfigStats,
    getTradeConfigStats,
    getPositionsStats,
    getGlobalTradesStats,
  };

  return api;
};

export const init = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const api = initApi({ httpClient, coreState });
  const queries = initQueries({ api, coreState });

  return {
    api,
    queries,
  };
};

export type API = ReturnType<typeof initApi>;
