import { Box, Title } from '@mantine/core';
import type { FC } from 'react';
import { SummaryTrade, type Props as SummaryTradeProps } from '../summary-trade';

export const Summary: FC<SummaryTradeProps> = (props) => {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        borderRadius: theme.radius.md,
      })}
    >
      <Box
        sx={(theme) => ({
          marginTop: theme.spacing.lg,
          overflowX: 'hidden',
          padding: theme.spacing.lg,
          position: 'sticky',
          top: 0,
        })}
      >
        {props.showContent && (
          <Title order={3} mb="md" size="h4">
            Summary
          </Title>
        )}
        <SummaryTrade {...props} />
      </Box>
    </Box>
  );
};
