import { Alert, type ButtonProps, Space, Box } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { BehaviorSubject, lastValueFrom, first } from 'rxjs';
import { notifications } from '@mantine/notifications';
import { useHttpErrorNotification } from '../../../../utils';

import { useConnectorsContext } from '../../context';
import type {
  CreateGsheetConnectorResponse,
  GsheetConnector,
  ListConnectorResponse,
} from '../../shared_imports';
import { GdriveFilepicker } from './gdrive_filepicker';
import { SelectedFile } from './selected-file';
import { useGdriveFilePicker } from './use-gsheet-picker';

const API_KEY = 'AIzaSyDfCzbySgCVQaE8hCcRSfiiVJtZc9ZxqJ4';
const APP_ID = '357862276673';

const fileTypeToMimetypeMap = {
  spreadsheet: 'application/vnd.google-apps.spreadsheet',
};

interface Props {
  userConnectors?: ListConnectorResponse | null;
  onConnectorCreated?: () => void;
  withHelpText?: boolean;
  buttonVariant?: ButtonProps['variant'];
  withBackgroundColor?: boolean;
  accessTokenFromQuery?: string;
}

const authorizationUrl$ = new BehaviorSubject<string | undefined>(undefined);

export const AddGsheetConnector: FC<Props> = ({
  userConnectors,
  onConnectorCreated,
  withHelpText,
  buttonVariant,
  accessTokenFromQuery: _accessTokenFromQuery,
  withBackgroundColor = true,
}) => {
  const autoOpenFilePicker = useRef(true);
  const queryClient = useQueryClient();
  const { getApi } = useConnectorsContext();
  const {
    api,
    queries: { useGoogleSheetAccessToken, useGoogleSheetDetails },
  } = getApi();

  const [sheetForPositions, setSheetForPositions] = useState<string | null>(null);
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const error = searchParams.get('error') ?? undefined;
  let accessTokenFromQuery = _accessTokenFromQuery;
  if (!accessTokenFromQuery) {
    accessTokenFromQuery = searchParams.get('access_token') ?? undefined;
  }

  const gsheetConnector =
    !!userConnectors &&
    (userConnectors.find(({ id }) => id === 'gsheet') as GsheetConnector | undefined);
  const hasConnector = Boolean(gsheetConnector);

  const createConnector = useMutation({
    mutationFn: api.createConnector,
    onSuccess: () => {
      if (onConnectorCreated) {
        onConnectorCreated();
      }
    },
  });
  const updateConnector = useMutation({
    mutationFn: api.updateConnector,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['connectors'] });

      if (onConnectorCreated) {
        onConnectorCreated();
      }

      notifications.show({
        title: 'Success!',
        message: `Connected to Google sheet successfully.`,
      });
    },
  });

  const { data: createConnectorData, mutate: createConnectorMutate } = createConnector;
  const authorizationUrl = (createConnectorData as CreateGsheetConnectorResponse)?.authorizationUrl;

  const getAuthorizationUrl = useCallback(() => {
    authorizationUrl$.next(undefined);

    const redirectUri = `${window.location.origin}${location.pathname}?connectAccount=gsheet`;

    createConnectorMutate({
      id: 'gsheet',
      payload: { redirectUri },
    });

    // Calling createConnector.mutate will return an "authorizationUrl" in the response.
    // which will update the authorizationUrl$ BehaviorSubject and resolve the promise.
    return lastValueFrom(authorizationUrl$.asObservable().pipe(first(Boolean)));
  }, [location, createConnectorMutate]);

  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  const [accessToken, setAccessToken] = useState<string | undefined>(accessTokenFromQuery);

  const { isReady, selectFile: selectGdriveFile } = useGdriveFilePicker({
    apiKey: API_KEY,
    appId: APP_ID,
    fileMimeType: fileTypeToMimetypeMap.spreadsheet,
    getAuthorizationUrl,
    onSelectFile: setSelectedFile,
    accessToken,
  });

  const selectFile = useCallback(async () => {
    if (selectGdriveFile) {
      setSelectedFile(null);
      await selectGdriveFile();
    }
  }, [selectGdriveFile]);

  const clearQueryParams = useCallback(() => {
    [...searchParams.entries()].forEach(([key]) => {
      searchParams.delete(key);
    });
    setSearchParams(searchParams);
  }, [setSearchParams, searchParams]);

  const connectGoogleSheet = useCallback(() => {
    if (!selectedFile) {
      return;
    }

    const { id, isWeTradeTimeTemplate, title } = selectedFile;

    updateConnector.mutate({
      id: 'gsheet',
      payload: {
        files: [
          {
            id,
            isWeTradeTimeTemplate,
            title,
            sheets:
              !isWeTradeTimeTemplate && sheetForPositions
                ? {
                    positions: sheetForPositions,
                  }
                : undefined,
          },
        ],
      },
    });
    clearQueryParams();
  }, [selectedFile, sheetForPositions, updateConnector, clearQueryParams]);

  const {
    data: accessTokenData,
    isFetching: isLoadingToken,
    error: gsheetAccessTokenError,
  } = useGoogleSheetAccessToken(hasConnector, accessToken);

  const {
    data: fileDetails,
    isFetching: isLoadingFileDetails,
    error: gsheetFileDetailsError,
  } = useGoogleSheetDetails(selectedFile?.id, accessToken);

  useHttpErrorNotification(gsheetAccessTokenError);
  useHttpErrorNotification(gsheetFileDetailsError);

  useEffect(() => {
    authorizationUrl$.next(authorizationUrl);
  }, [authorizationUrl]);

  useEffect(() => {
    setAccessToken((prev) => {
      // Only set it if we don't have it already
      if (prev !== undefined) {
        return prev;
      }
      return accessTokenFromQuery ?? accessTokenData?.accessToken;
    });
  }, [accessTokenFromQuery, accessTokenData?.accessToken]);

  useEffect(() => {
    if (fileDetails) {
      setSelectedFile((prev) => ({
        ...prev,
        ...fileDetails,
      }));
    }
  }, [fileDetails]);

  useEffect(() => {
    if (accessTokenFromQuery && selectGdriveFile && autoOpenFilePicker.current) {
      autoOpenFilePicker.current = false;
      selectGdriveFile();
    }
  }, [selectGdriveFile, accessTokenFromQuery]);

  return (
    <Box
      sx={(theme) => {
        if (withBackgroundColor) {
          return {
            backgroundColor:
              theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
            padding: theme.spacing.xl,
            borderRadius: theme.radius.md,
          };
        }
        return {};
      }}
    >
      {Boolean(error) && (
        <>
          <Alert icon={<IconAlertCircle size={16} />} title="Awww!" color="red">
            Could not add account. {error}
          </Alert>
          <Space h="md" />
        </>
      )}
      {selectedFile === null ? (
        <GdriveFilepicker
          isAuthenticated={Boolean(accessToken)}
          isLoading={isLoadingToken || !isReady}
          selectFile={() => selectFile()}
          withHelpText={withHelpText}
          buttonVariant={buttonVariant}
        />
      ) : (
        <SelectedFile
          selectedFile={selectedFile}
          selectFile={() => selectFile()}
          connectGoogleSheet={connectGoogleSheet}
          isConnectingFile={updateConnector.status === 'loading'}
          isLoading={isLoadingFileDetails || isLoadingToken}
          sheetForPositions={sheetForPositions}
          onSheetForPositionsChange={setSheetForPositions}
          buttonVariant={buttonVariant}
        />
      )}
    </Box>
  );
};
