import type { MantineTheme } from '@mantine/core';
import type {
  Message,
  WebhookMessageStep,
  SavePositionStep,
  PositionClosed,
  PositionOpen,
  GsheetRowMeta,
} from '../../shared_imports';
import type { MessageEnhanced, WebhookStepAction } from '../../types';

export const isOpenPosition = (position: PositionOpen | PositionClosed): position is PositionOpen =>
  position.status === 'open';

const isCreateOrUpdatePosition = (step?: WebhookMessageStep): step is SavePositionStep =>
  step !== undefined && (step.action === 'createPosition' || step.action === 'updatePosition');

export const isMessageSuccessfullTrade = (
  message: Message
):
  | { status: 'success'; position: PositionOpen | PositionClosed }
  | { status: 'error'; position: null } => {
  const step = message.steps.find(
    ({ action }) => action === 'createPosition' || action === 'updatePosition'
  );

  if (!isCreateOrUpdatePosition(step)) {
    return {
      status: 'error' as const,
      position: null,
    };
  }

  if (!step || step.status === 'error' || step.result === undefined) {
    return {
      status: 'error' as const,
      position: null,
    };
  }

  return {
    status: 'success' as const,
    position: step.result.position,
  };
};

export const getGsheetRowInserted = (message: Message) => {
  const step = message.steps.find(({ action }) => action === 'insertRowGoogleSheet');
  if (step?.status === 'success' && step.result) {
    return step.result.row as GsheetRowMeta;
  }

  return null;
};

export const getMessageColor = (status: Message['status'], theme: MantineTheme) => {
  const successColor = theme.colorScheme === 'dark' ? theme.colors.green[9] : theme.colors.green[6];
  const errorColor = theme.colorScheme === 'dark' ? theme.colors.red[9] : theme.colors.red[5];

  return status === 'success' ? successColor : errorColor;
};

const stepsHiddenFromUi: WebhookStepAction[] = ['openPositionStart', 'initOpenClosePosition'];

export const enhanceMessage = (message: Message): MessageEnhanced => {
  const stepsFiltered = message.steps.filter(({ action }) => !stepsHiddenFromUi.includes(action));

  return {
    ...message,
    steps: stepsFiltered.map((step, i) => {
      let duration = 0;
      const nextStep = stepsFiltered[i + 1];

      if (step.status !== 'skipped' && nextStep && nextStep.status !== 'skipped') {
        duration = Math.round(nextStep.timestamp - step.timestamp);
      }

      return { ...step, duration };
    }),
  };
};
