import { useCallback, useEffect, useRef, useState } from 'react';
import { useScript } from '../../../../utils';
import type { SelectedFile } from './selected-file';

interface Props {
  appId: string;
  apiKey: string;
  fileMimeType: string;
  onSelectFile: (file: SelectedFile) => void;
  getAuthorizationUrl: () => Promise<string | undefined>;
  accessToken?: string;
}

export const useGdriveFilePicker = ({
  apiKey,
  appId,
  fileMimeType,
  onSelectFile,
  getAuthorizationUrl,
  accessToken,
}: Props) => {
  const [gisInited, setGisInited] = useState(Boolean(window.google));
  const [pickerInited, setPickerInited] = useState(Boolean(window.google));

  const picker = useRef<any>(null);

  const onPickerApiLoad = useCallback(() => {
    setPickerInited(true);
  }, []);

  const onApiLoad = useCallback(() => {
    window.gapi.load('picker', onPickerApiLoad);
  }, [onPickerApiLoad]);

  const pickerCallback = useCallback(
    async (filePickerEvent: any) => {
      const { google, gapi } = window;
      if (!google || !gapi) {
        return;
      }

      if (filePickerEvent.action === google.picker.Action.PICKED) {
        const document = filePickerEvent[google.picker.Response.DOCUMENTS][0];

        if (document) {
          const fileId = document[google.picker.Document.ID];
          const mimeType = document[google.picker.Document.MIME_TYPE];
          const fileName = document[google.picker.Document.NAME];

          if (mimeType !== fileMimeType) {
            // setError(`The selected file is not a valid Google Sheet.`);
            // TODO: Improve error handling
            return;
          }

          onSelectFile({
            id: fileId,
            title: fileName,
            isWeTradeTimeTemplate: false,
            sheets: [],
          });

          picker.current.setVisible(false);
        }
      }
    },
    [fileMimeType, onSelectFile]
  );

  const openFilePicker = useCallback(
    (token = accessToken) => {
      const { google } = window;
      if (!google) {
        return;
      }

      if (!picker.current) {
        const view = new google.picker.View(google.picker.ViewId.SPREADSHEETS);
        view.setMimeTypes(fileMimeType);

        picker.current = new google.picker.PickerBuilder()
          .enableFeature(google.picker.Feature.NAV_HIDDEN)
          .setDeveloperKey(apiKey)
          .setAppId(appId)
          .setOAuthToken(token)
          .addView(view)
          .addView(new google.picker.DocsUploadView())
          .setCallback(pickerCallback)
          .build();
      }

      picker.current.setVisible(true);
    },
    [apiKey, appId, accessToken, fileMimeType, pickerCallback]
  );

  const gisLoaded = useCallback(() => {
    setGisInited(true);
  }, []);

  const selectFile = useCallback(async () => {
    if (accessToken === undefined) {
      const uri = await getAuthorizationUrl();
      if (uri) {
        window.location.href = uri;
      }
    } else {
      // We have a accessToken... open the file picker
      openFilePicker(accessToken);
    }
  }, [accessToken, openFilePicker, getAuthorizationUrl]);

  const doLoadGoogleScripts = useCallback(() => {
    return Boolean(window.google) === false;
  }, []);

  useScript('https://apis.google.com/js/api.js', onApiLoad, { predicate: doLoadGoogleScripts });
  useScript('https://accounts.google.com/gsi/client', gisLoaded, {
    predicate: doLoadGoogleScripts,
  });

  useEffect(() => {
    // Destroy picker on unmount
    return () => {
      if (picker.current) {
        picker.current.dispose();
      }
    };
  }, []);

  if (!pickerInited || !gisInited) {
    return {
      isReady: false,
    };
  }

  return {
    isReady: true,
    selectFile,
  };
};
