import lodable from '@loadable/component';
import { Outlet, RouteObject } from 'react-router-dom';

import { AppProvider } from './context';
import type { AppContext } from './context';
import { BotDetails } from './components/details';
import { ConnectorsProvider } from './shared_imports';

const Trades = lodable(() => import('./components/trades'), {
  resolveComponent: (components) => components.Trades,
});

const CreateEditTradeConfig = lodable(() => import('./components/create-edit-form'), {
  resolveComponent: (components) => components.CreateEditTradeConfig,
});

export const init = (ctx: AppContext) => {
  const { i18n } = ctx;
  const { getPlugins } = ctx;

  const getConnectorsApi = () => {
    const { connectors } = getPlugins();
    return {
      api: connectors.api,
      queries: connectors.queries,
    };
  };

  const App = () => {
    return (
      <ConnectorsProvider getApi={getConnectorsApi}>
        <AppProvider {...ctx}>
          <Outlet />
        </AppProvider>
      </ConnectorsProvider>
    );
  };

  const route: RouteObject = {
    path: '/signals',
    element: <App />,
    children: [
      {
        index: true,
        element: <Trades />,
      },
      {
        path: 'new',
        element: <CreateEditTradeConfig />,
      },
      {
        path: 'view/:name',
        element: <BotDetails />,
      },
      {
        path: 'edit/:name',
        element: <CreateEditTradeConfig />,
      },
    ],
  };

  return {
    routes: {
      route,
      mainNavLabel: i18n.formatMessage({
        id: 'pageHeader.tradesLinkLabel',
        defaultMessage: 'Signals',
      }),
      mainNavOrder: 2,
    },
  };
};

export default init;
