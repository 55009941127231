import {
  Anchor,
  Box,
  Center,
  Container,
  Paper,
  Space,
  Text,
  rem,
  useMantineTheme,
} from '@mantine/core';
import type { FC } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

import { WeTradeTimeLogo } from '../../../components';

export const AuthPage: FC = () => {
  const { pathname } = useLocation();
  const theme = useMantineTheme();

  const renderHelpText = () => {
    switch (pathname) {
      case '/auth/login':
        return (
          <Text color="dimmed" size="sm">
            During the beta phase, registration is limited and requires a registration code. If you
            have a code you can{' '}
            <Anchor to="register" component={Link}>
              register here
            </Anchor>
            .
          </Text>
        );
      case '/auth/register':
        return (
          <Text color="dimmed" size="sm">
            If you already have a user you can{' '}
            <Anchor to="login" component={Link}>
              login here
            </Anchor>
            .
          </Text>
        );
      case '/auth/password-reset':
        return (
          <Text color="dimmed" size="sm">
            <Anchor to="login" component={Link}>
              Back to login
            </Anchor>
          </Text>
        );
      default:
        return null;
    }
  };

  return (
    <Container size={420} mt={rem(64)}>
      <Center>
        <WeTradeTimeLogo colorScheme={theme.colorScheme} width={240} />
      </Center>

      <Paper withBorder shadow="md" p={30} mt={30} radius="sm">
        <Outlet />
        <Space h="30" />
      </Paper>

      <Box mt="lg" mb="lg">
        {renderHelpText()}
      </Box>
    </Container>
  );
};
