import type { PluginInitializer } from '../../core';
import { PLUGIN_ID } from './constants';
import { PortfolioPlugin } from './plugin';

export type { PluginStart as PortfolioPluginStart } from './types';

export const init: PluginInitializer = () => {
  const plugin = new PortfolioPlugin();

  return {
    id: PLUGIN_ID,
    plugin,
  };
};

export default init;
