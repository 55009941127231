import lodable from '@loadable/component';
import type { RouteObject } from 'react-router-dom';

import { AppProvider } from './context';
import type { AppContext } from './context';

const PositionsLazy = lodable(() => import('./components/positions'), {
  resolveComponent: (components) => components.Positions,
});

export const init = (ctx: AppContext) => {
  const { i18n } = ctx;

  const App = () => {
    return (
      <AppProvider {...ctx}>
        <PositionsLazy />
      </AppProvider>
    );
  };

  const route: RouteObject = {
    path: '/positions',
    element: <App />,
  };

  return {
    routes: {
      route,
      mainNavLabel: i18n.formatMessage({
        id: 'pageHeader.positionsLinkLabel',
        defaultMessage: 'Positions',
      }),
      mainNavOrder: 3,
    },
  };
};

export default init;
