import { type FC, forwardRef } from 'react';
import { Grid, Select, Text, type TitleOrder } from '@mantine/core';

import { useFormContext } from './create-edit-trade-config-form';
import { FormSection } from './form-section';
import type { TradeExitType } from '../../shared_imports';

export const defaultExitType = 'PL';

export const exitTypeSelectItems: Array<{
  value: TradeExitType;
  label: string;
  description: string;
}> = [
  {
    value: 'FIFO',
    label: `FIFO`,
    description: 'First in first out',
  },
  {
    value: 'LIFO',
    label: `LIFO`,
    description: 'Last in first out',
  },
  {
    value: defaultExitType,
    label: `P/L`,
    description: 'Highest profit',
  },
  {
    value: 'ROI',
    label: `ROI`,
    description: 'Highest ROI (%)',
  },
];

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Text size="sm">{label}</Text>
      <Text size="xs" opacity={0.65}>
        {description}
      </Text>
    </div>
  )
);

interface Props {
  titleOrder?: TitleOrder;
  withSeparator?: boolean;
}

export const TradeConfigExitType: FC<Props> = ({ titleOrder = 3, withSeparator }) => {
  const form = useFormContext();

  return (
    <FormSection title="Exit type" titleOrder={titleOrder} withSeparator={withSeparator}>
      <Grid align="center">
        {/* Exit type (FIFO, LIFO, ROI...) */}
        <Grid.Col span={5}>
          <Text size="xs" color="dimmed">
            Configure the position to close when a 'close' message is received.
          </Text>
        </Grid.Col>
        <Grid.Col span={7}>
          <Select
            label="Type"
            itemComponent={SelectItem}
            {...form.getInputProps('exitType')}
            data={exitTypeSelectItems}
          />
        </Grid.Col>
      </Grid>
    </FormSection>
  );
};
