import type { PluginInitializer } from '../../core';
import { PLUGIN_ID } from './constants';
import { UserPlugin } from './plugin';
import type { PluginDependencies } from './plugin';

export type { PluginStart as UserPluginStart } from './types';

export const init: PluginInitializer<void, PluginDependencies> = () => {
  const plugin = new UserPlugin();

  return {
    id: PLUGIN_ID,
    plugin,
    requiredPlugins: ['connectors'],
  };
};

export default init;
