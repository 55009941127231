import { useQuery } from '@tanstack/react-query';
import type { BaseError, User } from '../../../core';

import type { API } from './api';

export const init = ({ api }: { api: API }) => {
  function useMe() {
    return useQuery<User | null, BaseError>(['users', 'me'], () => {
      return api.getMe();
    });
  }

  return {
    useMe,
  };
};

export type Queries = ReturnType<typeof init>;
