import { BehaviorSubject, type Observable } from 'rxjs';
import { QueryClient } from '@tanstack/react-query';

import { ENV } from '../constants';
import { AppPlugins } from './plugins/appPlugins';
import { routes } from './routes';
import { I18n, init as initi18n } from './i18n';
import { stateDefaultValue } from './constants';
import { init as initRouter } from './router';
import { init as initAuth } from './auth';
import type { Authentication } from './auth';
import { getHttpClient } from './http';
import type { HttpClient } from './http';
import initPlugins from './plugins';
import type { PluginRouterDefinition } from './plugins';
import type { Router, User } from './types';
import { UserService, getMutations as getUserMutations, type UserMutations } from './user';
import { AssetsPriceService } from './assets';

export interface PluginsApis {
  [id: string]: {
    start: unknown;
    setup: unknown;
  };
}

export interface State {
  user: User | null;
  auth: {
    isLoggedIn: boolean | null;
  };
  testModeOn: boolean;
  isWindowFocused: boolean;
}

const initState = ({
  isLoggedIn$,
  user$,
}: {
  isLoggedIn$: Observable<boolean>;
  user$: Observable<User | null>;
}) => {
  const state$ = new BehaviorSubject<State>(stateDefaultValue);

  const set = {
    user: (user: User | null) => {
      state$.next({ ...state$.value, user });
    },
    testModeOn: (isOn: boolean) => {
      state$.next({ ...state$.value, testModeOn: isOn });
    },
    isLoggedIn: (isLoggedIn: boolean) => {
      state$.next({ ...state$.value, auth: { ...state$.value.auth, isLoggedIn } });
    },
    isWindowFocused: (isWindowFocused: boolean) => {
      state$.next({ ...state$.value, isWindowFocused });
    },
  };

  isLoggedIn$.subscribe(set.isLoggedIn);
  user$.subscribe(set.user);

  return {
    set,
    state$: state$.asObservable(),
  };
};

export type CoreState = ReturnType<typeof initState>;

export interface Core {
  i18n: I18n;
  httpClient: HttpClient;
  auth: Authentication;
  router: {
    registerRoutes: (routeDef: PluginRouterDefinition) => void;
    get: (defaultRoute?: string) => Router;
  };
  plugins: {
    register: AppPlugins['registerPlugin'];
    map: AppPlugins['plugins'];
    get: AppPlugins['get'];
    apis?: PluginsApis;
  };
  state: CoreState;
  queryClient: QueryClient;
  mutations: {
    user: UserMutations;
  };
  assets: {
    pricesService: AssetsPriceService;
  };
}

const isDevEnv = ENV === 'dev';

// In dev we don't want to retry at close interval as it breaks the sam local-api
// by risking concurrent requests.
const queryRetryDelay = isDevEnv ? 7 : 3;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: !isDevEnv,
      retry: () => !isDevEnv,
      retryDelay: (attemptIndex) => Math.min(1000 * queryRetryDelay ** attemptIndex, 30000),
    },
  },
});

export const init = (): Core => {
  const httpClient: HttpClient = getHttpClient({
    apiEndpoint: process.env.REACT_APP_API_ENDPOINT ?? '',
  });

  const auth = initAuth({ http: httpClient, queryClient });
  const user = new UserService({ http: httpClient, isLoggedIn$: auth.isLoggedIn$() });
  const state = initState({ isLoggedIn$: auth.isLoggedIn$(), user$: user.user$ });

  const pricesService = new AssetsPriceService({ http: httpClient });

  auth.setup({ coreState: state });
  user.setup({ coreState: state });
  pricesService.setup({ coreState$: state.state$ });

  const appPlugins = new AppPlugins();

  const { i18n } = initi18n({ locale: 'en', messages: {} });
  const { registerRoutes, getRouter } = initRouter({ coreRoutes: routes });

  const core: Core = {
    plugins: {
      register: appPlugins.registerPlugin.bind(appPlugins),
      map: appPlugins.plugins,
      get: appPlugins.get.bind(appPlugins),
    },
    router: {
      registerRoutes,
      get: getRouter,
    },
    i18n,
    httpClient,
    auth,
    queryClient,
    state,
    mutations: {
      user: getUserMutations({ user }),
    },
    assets: {
      pricesService,
    },
  };

  initPlugins(core);

  return core;
};

export type AppCore = Omit<Core, 'plugins' | 'router'>;

export default init;
