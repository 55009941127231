import lodable from '@loadable/component';
import { Outlet, RouteObject } from 'react-router-dom';

import { AppProvider } from './context';
import type { AppContext } from './context';

const Messages = lodable(() => import('./components/messages'), {
  resolveComponent: (components) => components.Messages,
});

export const init = (ctx: AppContext) => {
  const { i18n } = ctx;

  const App = () => {
    return (
      <AppProvider {...ctx}>
        <Outlet />
      </AppProvider>
    );
  };

  const route: RouteObject = {
    path: '/messages',
    element: <App />,
    children: [
      {
        index: true,
        element: <Messages />,
      },
    ],
  };

  return {
    routes: {
      route,
      mainNavLabel: i18n.formatMessage({
        id: 'pageHeader.messagesLinkLabel',
        defaultMessage: 'Messages',
      }),
      mainNavOrder: 3,
    },
  };
};

export default init;
