import { useEffect } from 'react';

/**
 * Dynamically add a script tag on the document
 *
 * @param url The script url
 */
export const useScript = (
  url: string,
  onLoad?: (lib: unknown) => void,
  {
    predicate,
    removeFromDOMonUnmount = false,
  }: {
    removeFromDOMonUnmount?: boolean;
    predicate?: () => boolean;
  } = {}
) => {
  useEffect(() => {
    if (predicate && predicate() === false) {
      return () => undefined;
    }

    const script = document.createElement('script');

    script.src = url;
    script.async = true;
    script.defer = true;

    if (onLoad) {
      script.onload = onLoad;
    }

    document.body.appendChild(script);

    return () => {
      if (removeFromDOMonUnmount) {
        document.body.removeChild(script);
      }
    };
  }, [onLoad, url, predicate, removeFromDOMonUnmount]);
};
