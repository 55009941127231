import type { PluginInitializer } from '../../core';
import { PLUGIN_ID } from './constants';
import { WebhookPlugin } from './plugin';

export type { PluginStart as WebhookPluginStart } from './types';

export const init: PluginInitializer = () => {
  const plugin = new WebhookPlugin();

  return {
    id: PLUGIN_ID,
    plugin,
  };
};

export default init;
