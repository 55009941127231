import type { FC } from 'react';
import { Box, Center, Text, useMantineTheme } from '@mantine/core';
import { Message } from './message';
import type { MessageEnhanced } from '../../types';

interface Props {
  message?: MessageEnhanced | null;
  onCloseMessage: () => void;
  children?: React.ReactNode;
  isLoadingMessages: boolean;
  hasMessages: boolean;
}

export const DetailsPanel: FC<Props> = ({
  message,
  onCloseMessage,
  children,
  isLoadingMessages,
  hasMessages,
}) => {
  const hasDefaultContent = Boolean(children);
  const theme = useMantineTheme();

  const renderContent = () => {
    if (isLoadingMessages) {
      return (
        <Center h="100%">
          <Text color="dimmed" size="sm">
            Loading...
          </Text>
        </Center>
      );
    }

    if (hasDefaultContent || message) {
      return (
        <>
          {message && <Message message={message} onCloseMessage={onCloseMessage} />}
          <Box sx={{ display: message ? 'none' : 'block', height: '100%' }}>{children}</Box>
        </>
      );
    }

    return (
      <Center h="100%">
        <Text color="dimmed">{hasMessages ? 'Select a message in the list' : 'No messages'}</Text>
      </Center>
    );
  };

  return (
    <Box
      sx={{
        height: `calc(100% - ${theme.spacing.lg})`,
        minHeight: '200px',
      }}
    >
      <Box
        sx={{
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
          borderRadius: theme.radius.md,
          height: '100%',
          marginTop: theme.spacing.lg,
          maxHeight: '720px',
          overflowY: 'auto',
          padding: theme.spacing.md,
          position: 'sticky',
          top: 0,
        }}
      >
        {renderContent()}
      </Box>
    </Box>
  );
};
