import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import type { Core } from './core';

export const renderApp = ({ core }: { core: Core }) => {
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <App core={core} />
    </React.StrictMode>
  );
};

export default renderApp;
