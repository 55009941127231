import plugins from '../../plugins';
import type { Core, PluginsApis } from '../core';
import type { PluginDefinition } from './types';

interface PluginDefinitionMap {
  [pluginId: string]: PluginDefinition<any>;
}

const getPluginDefinitions = (core: Core): PluginDefinitionMap => {
  const pluginDefinitions: { [pluginId: string]: PluginDefinition<any, any> } = {};

  Object.values(plugins).forEach((initializer) => {
    const def = initializer();
    core.plugins.register(def.plugin);
    pluginDefinitions[def.id] = def;
  });

  return pluginDefinitions;
};

export const init = (core: Core) => {
  // Public interface of all the plugins
  const pluginApis: Partial<PluginsApis> = {};

  const getPluginApi = ({
    id,
    depTree = [],
    pluginDefinitions,
    action = 'setup',
  }: {
    id: string;
    depTree?: string[];
    pluginDefinitions: PluginDefinitionMap;
    action?: 'setup' | 'start';
  }) => {
    if (depTree.includes(id)) {
      throw new Error(`Circular dependency detected. Loading [${id}], tree [${depTree}]`);
    }

    const getPluginDefinition = (pluginId: string) => {
      const def = pluginDefinitions[id];

      if (!def) {
        throw new Error(`Unknown plugin [${pluginId}]`);
      }

      return def;
    };

    const getPluginDependencies = (pluginDef: PluginDefinition<any, any>) => {
      if (pluginDef.requiredPlugins && pluginDef.requiredPlugins.length > 0) {
        const dependencies = pluginDef.requiredPlugins.reduce((acc, depPluginId) => {
          const api = getPluginApi({ id: depPluginId, pluginDefinitions, action });
          return {
            ...acc,
            [depPluginId]: api,
          };
        }, {} as { [id: string]: any });

        return dependencies;
      }

      return undefined;
    };

    if (!pluginApis[id]) {
      pluginApis[id] = {
        setup: undefined,
        start: undefined,
      };
    }

    if (!pluginApis[id]![action]) {
      const dependencies = getPluginDependencies(getPluginDefinition(id));
      const api = getPluginDefinition(id).plugin[action](core, dependencies as any);
      pluginApis[id]![action] = (api as unknown as object) || {};
    }

    return pluginApis[id]![action];
  };

  const pluginDefinitions = getPluginDefinitions(core);

  // Setup plugins
  Object.keys(pluginDefinitions).forEach((id) => {
    getPluginApi({ id, pluginDefinitions, action: 'setup' });
  });

  // Start plugins
  Object.keys(pluginDefinitions).forEach((id) => {
    getPluginApi({ id, pluginDefinitions, action: 'start' });
  });

  return {
    pluginApis,
    plugins: core.plugins.map,
  };
};

export default init;

// Notes
// If needed, when instantiating the plugins we could
// pass some context (env mode, package info...)
/**
  interface EnvironmentMode {
    name: 'development' | 'production';
    dev: boolean;
    prod: boolean;
  }

  interface PackageInfo {
    version: string;
    branch: string;
    buildNum: number;
    buildSha: string;
    dist: boolean;
  }
*/
