import type { FC, ReactNode } from 'react';
import { Container, type ContainerProps } from '@mantine/core';

interface Props {
  children: ReactNode;
  containerProps?: ContainerProps;
}

export const Page: FC<Props> = ({ children, containerProps }) => {
  return (
    <>
      <Container size="lg" {...containerProps} px="xl">
        {children}
      </Container>
    </>
  );
};
