import { BehaviorSubject, first, lastValueFrom, type Observable } from 'rxjs';
import type { Core, CoreState } from '../core';
import type { UserAction, UserActionsResponse, UserFetchResponse } from '../../shared_imports';
import type { User } from '../types';

export class UserService {
  private _user$ = new BehaviorSubject<User | null>(null);

  private http: Core['httpClient'];

  private coreState: CoreState | undefined;

  constructor({
    http,
    isLoggedIn$,
  }: {
    http: Core['httpClient'];
    isLoggedIn$: Observable<boolean>;
  }) {
    this.http = http;

    isLoggedIn$.subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.fetchUserProfile();
      } else {
        this._user$.next(null);
      }
    });
  }

  public setup({ coreState }: { coreState: CoreState }) {
    this.coreState = coreState;
  }

  public setUser = (user: User | null) => {
    this._user$.next(user);
  };

  public async executeAction(action: UserAction) {
    if (!this.coreState) {
      throw new Error('Core state not provided.');
    }
    const { testModeOn } = await lastValueFrom(this.coreState.state$.pipe(first()));

    await this.http.post<UserActionsResponse>(`/users/me/${action}`, undefined, {
      userAccount: testModeOn ? 'paper' : 'root',
    });
    await this.fetchUserProfile();
  }

  private async fetchUserProfile() {
    const user = await this.http.get<UserFetchResponse>('/users/me');
    if (user) {
      this._user$.next(user);
    }
  }

  get user$() {
    return this._user$.asObservable();
  }
}
