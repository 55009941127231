import BaseError from './baseError';
// import { httpStatusCodes } from './httpStatusCodes';
import type { ErrorArgs } from './types';

export class HttpError extends BaseError {
  constructor(
    message: string,
    { statusCode = 400, name = 'Bad request.', data, type, code }: ErrorArgs = {}
  ) {
    super(message, { statusCode, name, data, type, code });
  }
}
