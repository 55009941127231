import { FC, useState } from 'react';
import format from 'date-fns/format';

import { IconX } from '@tabler/icons-react';
import { ActionIcon, rem } from '@mantine/core';
import type { PositionClosed, PositionOpen } from '../../../shared_imports';
import { renderDecimalValue } from '../../../utils';
import type { Position } from '../types';

const isClosePosition = (pos: PositionOpen | PositionClosed): pos is PositionClosed =>
  pos.status === 'closed';

export interface ColumnConfig {
  pair?: {
    visible?: boolean;
    width?: string;
  };
  date?: {
    visible?: boolean;
    width?: string;
  };
  pl?: {
    visible?: boolean;
    width?: string;
  };
  roi?: {
    visible?: boolean;
    width?: string;
  };
  trigger?: {
    visible?: boolean;
    width?: string;
  };
  rowActions?: {
    visible?: boolean;
    width?: string;
  };
}

export const defaultColumnConfig: ColumnConfig = {
  pair: {
    visible: true,
    width: '15%',
  },
  date: {
    visible: true,
  },
  pl: {
    visible: true,
    width: '15%',
  },
  roi: {
    visible: true,
    width: '15%',
  },
  trigger: {
    visible: true,
    width: '15%',
  },
  rowActions: {
    visible: true,
    width: rem(30),
  },
};

interface Props {
  position: Position;
  onSelectPosition: (position: Position) => void;
  closePosition: (position: Position) => void;
  isBeingUpdated: boolean;
  className?: string;
  columnConfig: ColumnConfig;
}

export const PositionRow: FC<Props> = ({
  position,
  columnConfig,
  onSelectPosition,
  closePosition,
  className,
  isBeingUpdated,
}) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isClose = isClosePosition(position);
  const date = isClose ? position.closedAt : position.openedAt;
  const { result: roi, pl } = position; // TODO: calculate ROI for open positions

  return (
    <tr
      key={position.id}
      onClick={() => {
        onSelectPosition(position);
      }}
      onMouseEnter={() => setIsMouseOver(true)}
      onMouseLeave={() => setIsMouseOver(false)}
      className={className}
      style={{ cursor: isBeingUpdated ? 'default' : undefined }}
    >
      {!!columnConfig.pair?.visible && <td>{position.ticker}</td>}
      {!!columnConfig.date?.visible && <td>{format(new Date(date), 'MMM do pp')}</td>}
      {!!columnConfig.pl?.visible && <td>{renderDecimalValue(pl, { quote: position.quote })}</td>}
      {!!columnConfig.roi?.visible && <td>{`${renderDecimalValue(roi, { decimals: 2 })}%`}</td>}
      {!!columnConfig.trigger?.visible && <td>{position.tradeName}</td>}
      {!!columnConfig.rowActions?.visible && position.status === 'open' && (
        <td style={{ width: columnConfig.rowActions.width, textAlign: 'right', padding: 0 }}>
          {isMouseOver && !isBeingUpdated && (
            <ActionIcon
              variant="subtle"
              onClick={(e) => {
                e.stopPropagation();
                closePosition(position);
              }}
              size="sm"
            >
              <IconX size="1rem" />
            </ActionIcon>
          )}
        </td>
      )}
    </tr>
  );
};
