import type { Core, Plugin } from '../../core';
// import { init as initRoute } from './route';
import { PLUGIN_ID } from './constants';
import type { PluginStart } from './types';
import { init as initApi } from './api';

export class PortfolioPlugin implements Plugin {
  id = PLUGIN_ID;

  setup() {
    return {};
  }

  start(core: Core): PluginStart {
    return {
      getApi: ({ coreState }) => initApi({ httpClient: core.httpClient, coreState }),
    };
  }
}

export default PortfolioPlugin;
