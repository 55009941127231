import reportWebVitals from './reportWebVitals';
import { init as initCore } from './core';

import { renderApp } from './renderApp';

const start = () => {
  const core = initCore();

  renderApp({ core });
};

start();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
