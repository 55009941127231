import type { PluginInitializer } from '../../core';
import { PLUGIN_ID } from './constants';
import { TradesPlugin, type PluginDependencies } from './plugin';

export const init: PluginInitializer<void, PluginDependencies> = () => {
  const plugin = new TradesPlugin();

  return {
    id: PLUGIN_ID,
    plugin,
    requiredPlugins: ['portfolio', 'connectors', 'webhook', 'stats', 'positions'],
  };
};

export type { PluginStart as TradesPluginStart } from './types';

export default init;
