import { Box, Drawer, type DrawerProps } from '@mantine/core';

function DrawerContentHeader({ title }: { title?: string }) {
  return (
    <Drawer.Header>
      <Drawer.Title>{title}</Drawer.Title>
      <Drawer.CloseButton />
    </Drawer.Header>
  );
}

function DrawerContentMain({ children }: { children: React.ReactNode }) {
  return (
    <Box sx={{ flex: 1 }}>
      <Drawer.Body>{children}</Drawer.Body>
    </Box>
  );
}

function DrawerContentFooter({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing.md,
        borderTop: `1px solid ${
          theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
        }`,
      })}
    >
      {children}
    </Box>
  );
}

export function CustomDrawer({
  opened,
  onClose,
  position,
  padding,
  size,
  scrollAreaComponent,
  children,
}: Omit<DrawerProps, 'title'>) {
  return (
    <Drawer.Root
      opened={opened}
      onClose={onClose}
      position={position}
      padding={padding}
      size={size}
      scrollAreaComponent={scrollAreaComponent}
    >
      <Drawer.Overlay />
      <Drawer.Content>
        <div style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column' }}>
          {children}
        </div>
      </Drawer.Content>
    </Drawer.Root>
  );
}

CustomDrawer.Header = DrawerContentHeader;
CustomDrawer.Main = DrawerContentMain;
CustomDrawer.Footer = DrawerContentFooter;
