import { ColorScheme, createStyles } from '@mantine/core';
import { ReactComponent as Logo } from '../logo.svg';

const useStyles = createStyles((theme) => ({
  logo: {
    fontFamily: 'monospace',
    color: theme.colors.teal[4],
    fontSize: theme.fontSizes.sm,
    paddingLeft: '3px',
    paddingTop: '3px',
  },
}));

export const WeTradeTimeLogo = ({
  colorScheme,
  withBetaInfo = true,
  withAppVersion = false,
  width = 600,
}: {
  colorScheme: ColorScheme;
  withBetaInfo?: boolean;
  withAppVersion?: boolean;
  width?: number;
}) => {
  const { classes } = useStyles();

  return (
    <span style={{ display: 'flex' }}>
      <Logo
        width={width}
        height="auto"
        fill="#fff"
        style={
          colorScheme === 'dark'
            ? {
                // Add a filter to color the SVG in white
                // source: https://stackoverflow.com/a/53336754/5642633
                filter:
                  'invert(100%) sepia(100%) saturate(0%) hue-rotate(45deg) brightness(105%) contrast(102%)',
              }
            : undefined
        }
      />
      {withBetaInfo && (
        <>
          {' '}
          <span className={classes.logo}>
            ({`${`beta${withAppVersion ? ` · ${process.env.REACT_APP_VERSION}` : ''}`}`})
          </span>
        </>
      )}
    </span>
  );
};
