import { Button, Text, Container, createStyles, rem, Title } from '@mantine/core';
import type { FC, ReactNode } from 'react';

const useStyles = createStyles((theme) => ({
  wrapper: {
    position: 'relative',
    paddingTop: rem(120),
    paddingBottom: rem(80),

    [theme.fn.smallerThan('sm')]: {
      paddingTop: rem(80),
      paddingBottom: rem(60),
    },
  },

  inner: {
    position: 'relative',
    zIndex: 1,
  },

  title: {
    textAlign: 'center',
    fontWeight: 800,
    fontSize: rem(40),
    letterSpacing: -1,
    color: theme.colorScheme === 'dark' ? theme.white : theme.black,
    marginBottom: theme.spacing.xs,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,

    [theme.fn.smallerThan('xs')]: {
      fontSize: rem(28),
      textAlign: 'left',
    },
  },

  description: {
    textAlign: 'center',

    [theme.fn.smallerThan('xs')]: {
      textAlign: 'left',
      fontSize: theme.fontSizes.md,
    },
  },

  controls: {
    marginTop: theme.spacing.lg,
    display: 'flex',
    justifyContent: 'center',

    [theme.fn.smallerThan('xs')]: {
      flexDirection: 'column',
    },
  },

  control: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing.md,
    },

    [theme.fn.smallerThan('xs')]: {
      height: rem(42),
      fontSize: theme.fontSizes.md,

      '&:not(:first-of-type)': {
        marginTop: theme.spacing.md,
        marginLeft: 0,
      },
    },
  },
}));

interface Props {
  title: string | ReactNode;
  description: string;
  actions?: Array<{ label: string; handler: () => void }>;
}

export const EmptyPrompt: FC<Props> = ({ title, description, actions }) => {
  const { classes } = useStyles();

  const [mainAction, secondAction] = actions ?? [];

  return (
    <Container className={classes.wrapper} size={1400}>
      <div className={classes.inner}>
        <Title className={classes.title}>{title}</Title>

        <Container p={0} size={600}>
          <Text size="lg" color="dimmed" className={classes.description}>
            {description}
          </Text>
        </Container>

        {Boolean(actions) && actions!.length > 0 && (
          <div className={classes.controls}>
            {secondAction && (
              <Button
                className={classes.control}
                size="lg"
                variant="default"
                color="gray"
                onClick={secondAction.handler}
              >
                {secondAction.label}
              </Button>
            )}
            <Button className={classes.control} size="lg" onClick={mainAction!.handler}>
              {mainAction!.label}
            </Button>
          </div>
        )}
      </div>
    </Container>
  );
};
