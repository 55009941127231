import { Box, Divider, Title } from '@mantine/core';
import type { FC } from 'react';
import BigNumber from 'bignumber.js';
import deepEqual from 'react-fast-compare';

import type { GsheetConnector } from '../../../shared_imports';
import { RESULT_DECIMALS, SIZE_DECIMALS } from '../../../constants';
import {
  renderDecimalValue,
  secondsToTimespan,
  stripUndefinedValues,
  useCurrentPrice,
} from '../../../utils';
import { KeyValue } from '../../../components';
import type {
  BalanceItem,
  PaperBalanceItem,
  TradeAllocation,
  TradeConfig,
  TradeExitType,
} from '../shared_imports';
import { defaultAllocationValue } from './create-edit-form/trade-config-order-size';
import { BalanceController } from './balance-controller';
import { defaultExitType, exitTypeSelectItems } from './create-edit-form/trade-config-exit-type';

const getExitTypeLabel = (exitType: TradeExitType) => {
  if (exitType === 'manual') {
    return 'Manual';
  }

  if (exitType === 'ROI' || exitType === 'PL') {
    const item = exitTypeSelectItems.find(({ value }) => value === exitType);
    return item?.description ?? exitType;
  }

  return exitType; // We leave FIFO and LIFO as it is a known trade term
};

const getSizeAtCurrentPrice = ({
  amount,
  currentPrice,
}: {
  amount: number;
  currentPrice?: string;
}) => {
  if (!currentPrice || currentPrice === '0') {
    return '';
  }

  return BigNumber(amount).div(BigNumber(currentPrice)).toFixed(SIZE_DECIMALS);
};

const getCostAtCurrentPrice = ({ size, currentPrice }: { size: number; currentPrice?: string }) => {
  if (!currentPrice || currentPrice === '0') {
    return '';
  }

  return BigNumber(size).times(BigNumber(currentPrice)).toFixed(RESULT_DECIMALS);
};

export interface Props {
  tradeConfig: Omit<TradeConfig, 'tradeId'>;
  balance?: BalanceItem | PaperBalanceItem;
  isLoadingBalances?: boolean;
  showContent?: boolean;
  showStatus?: boolean;
  sameCloseIndicatorAsOpen?: boolean;
  withBalanceController?: boolean;
  gsheetConnector?: GsheetConnector;
}

export const SummaryTrade: FC<Props> = ({
  tradeConfig,
  balance,
  gsheetConnector,
  isLoadingBalances = false,
  showContent = true,
  showStatus = true,
  withBalanceController = true,
  sameCloseIndicatorAsOpen: sameCloseIndicatorAsOpenProp,
}) => {
  const { tradeName, outputs, asset, quote, ticker, pipesConfig } = tradeConfig;
  const openConditions = tradeConfig.conditions?.open;
  const closeConditions = tradeConfig.conditions?.close;
  const [
    {
      xchange: { id: xchange },
      allocation,
      exitType,
    },
  ] = outputs;
  const isActive = tradeConfig?.status !== 'off';
  const price = useCurrentPrice({ assetPair: ticker, xchange });

  const getText = (allocationType: TradeAllocation, allocationValue: number) => {
    switch (allocationType) {
      case 'percCurrentCapital': {
        const amount = BigNumber(allocationValue).times(balance?.value ?? 0);
        const sizeValue = getSizeAtCurrentPrice({
          amount: amount.toNumber(),
          currentPrice: price,
        });

        return {
          sizeConfig: `${(allocationValue * 100).toFixed(0)}%`,
          sizeConfigSuffix: ' (curr. balance)',
          sizeLabel: 'Size (next order)',
          sizeValue,
          costLabel: 'Cost (next order)',
          costValue: amount.toFixed(RESULT_DECIMALS),
        };
      }
      case 'fixAmount':
        return {
          sizeConfig: 'Fix amount',
          sizeConfigSuffix: ` (${quote})`,
          sizeLabel: 'Size (estimated)',
          sizeValue: getSizeAtCurrentPrice({
            amount: allocationValue,
            currentPrice: price,
          }),
          costLabel: 'Cost',
          costValue: allocationValue.toString(),
        };
      case 'fixSize':
        return {
          sizeConfig: 'Fix size',
          sizeLabel: 'Size',
          sizeValue: allocationValue,
          costLabel: 'Cost (next order)',
          costValue: getCostAtCurrentPrice({ size: allocationValue, currentPrice: price }),
        };
      default:
        throw new Error(`Unknown allocation type: ${allocationType}`);
    }
  };

  // Order size
  const allocationType = allocation?.type ?? defaultAllocationValue.type;
  const allocationValue = allocation?.value ? allocation.value : defaultAllocationValue.value / 100;
  const { sizeConfig, sizeConfigSuffix, sizeLabel, sizeValue, costLabel, costValue } = getText(
    allocationType,
    allocationValue
  );

  // Exit type
  const exitTypeValue = exitType ?? defaultExitType;

  // Indicators
  let openIndicators = openConditions?.types?.required ?? [];
  openIndicators = openIndicators.filter((indicator) => indicator.trim() !== '');
  const openIndicatorsTimespan = secondsToTimespan(openConditions?.types?.maxDelayBetweenTypes);
  let closeIndicators = closeConditions?.types?.required ?? [];
  closeIndicators = closeIndicators.filter((indicator) => indicator.trim() !== '');
  const closeIndicatorsTimespan = secondsToTimespan(closeConditions?.types?.maxDelayBetweenTypes);

  const _sameCloseIndicatorAsOpen =
    deepEqual(openIndicators, closeIndicators) &&
    openIndicatorsTimespan === closeIndicatorsTimespan;
  const sameCloseIndicatorAsOpen = sameCloseIndicatorAsOpenProp ?? _sameCloseIndicatorAsOpen;

  // Conditions
  const { types: _t1, ...openConditionsWithoutType } = openConditions ?? {};
  const { types: _t2, ...closeConditionsWithoutType } = closeConditions ?? {};
  const parsedOpenConditions = stripUndefinedValues(openConditionsWithoutType);
  const parsedCloseConditions = stripUndefinedValues(closeConditionsWithoutType);
  const hasOpenConditions = parsedOpenConditions && Object.keys(parsedOpenConditions).length > 0;
  const hasCloseConditions = parsedCloseConditions && Object.keys(parsedCloseConditions).length > 0;

  // Output
  let gsheetOutput: { title: string } | undefined;
  if (gsheetConnector && pipesConfig?.gsheet?.enabled !== false) {
    const title = gsheetConnector.files?.[0]?.title;
    if (title) {
      gsheetOutput = { title };
    }
  }
  const hasOutput = !!gsheetOutput;

  return (
    <Box pb="lg">
      {showContent && (
        <>
          <Box>
            <KeyValue label="Signal name" value={tradeName} />

            {/* -------- Asset ---------- */}
            <Title mt="md" mb="sm" size="sm">
              Asset
            </Title>
            {showStatus && <KeyValue label="Status" value={isActive ? 'Active' : 'Paused'} />}
            <KeyValue label="Asset" value={asset} />
            <KeyValue
              label="Quote"
              value={quote}
              valueSuffix={
                balance ? `(balance: ${renderDecimalValue(balance.value, { quote })})` : undefined
              }
            />
            {Boolean(price) && price !== '0' && (
              <KeyValue label="Current price" value={renderDecimalValue(price, { quote })} />
            )}

            {withBalanceController && !isLoadingBalances && quote && (
              <BalanceController balance={balance} quote={quote} />
            )}
          </Box>

          {(openIndicators.length > 0 || closeIndicators.length > 0) && (
            <>
              {/* -------- Indicators ---------- */}
              <Divider mt="md" mb="md" variant="dotted" />

              <Title mt="md" mb="sm" size="sm">
                Indicators
              </Title>

              {openIndicators.length > 0 && (
                <>
                  <KeyValue
                    label={sameCloseIndicatorAsOpen ? 'Open & close' : 'Open'}
                    value={openIndicators.join(', ')}
                  />
                  <KeyValue
                    label={sameCloseIndicatorAsOpen ? 'Time span' : 'Open time span'}
                    value={openIndicatorsTimespan}
                  />
                </>
              )}

              {!sameCloseIndicatorAsOpen && closeIndicators.length > 0 && (
                <>
                  <KeyValue label="Close" value={closeIndicators.join(', ')} />
                  <KeyValue label="Close time span" value={closeIndicatorsTimespan} />
                </>
              )}
            </>
          )}

          {/* -------- Order ---------- */}
          <Divider mt="md" mb="md" variant="dotted" />

          <Title mt="md" mb="sm" size="sm">
            Order
          </Title>

          <Box>
            {xchange !== 'paper' && <KeyValue label="Exchange" value={xchange} />}
            <KeyValue label="Size config" value={sizeConfig} valueSuffix={sizeConfigSuffix} />
            {Boolean(sizeLabel) && (
              <KeyValue label={sizeLabel} value={sizeValue} valueSuffix={asset} />
            )}
            <KeyValue label={costLabel} value={renderDecimalValue(costValue, { quote })} />
            {exitTypeValue !== 'manual' && (
              <KeyValue label="Exit type" value={getExitTypeLabel(exitTypeValue)} />
            )}
          </Box>

          {/* -------- Triggers ---------- */}
          <Divider mt="md" mb="md" variant="dotted" />
          <Title mt="md" mb="sm" size="sm">
            Trigger
          </Title>
          <KeyValue
            label={exitType === 'manual' ? 'Open' : 'Open & close'}
            value="Webhook message"
          />
          {exitType === 'manual' && <KeyValue label="Close" value="Manual" />}

          {/* -------- Conditions ---------- */}
          {(hasOpenConditions || hasCloseConditions) && (
            <>
              <Divider mt="md" mb="md" variant="dotted" />
              <Title mt="md" mb="sm" size="sm">
                Conditions
              </Title>
            </>
          )}

          {hasOpenConditions && (
            <>
              <Box>
                {parsedOpenConditions?.maxOpenPositions && (
                  <KeyValue
                    label="Max open positions"
                    value={parsedOpenConditions.maxOpenPositions}
                  />
                )}
                {parsedOpenConditions?.minTimeBetweenTrades && (
                  <KeyValue
                    label="Min time between open orders"
                    value={secondsToTimespan(parsedOpenConditions.minTimeBetweenTrades)}
                  />
                )}
                {parsedOpenConditions?.confirmations?.min && (
                  <KeyValue label="Confirmations" value={parsedOpenConditions.confirmations.min} />
                )}
              </Box>
            </>
          )}

          {hasCloseConditions && (
            <>
              <Box>
                {parsedCloseConditions?.minTimeBetweenTrades && (
                  <KeyValue
                    label="Min time between close orders"
                    value={secondsToTimespan(parsedCloseConditions.minTimeBetweenTrades)}
                  />
                )}
              </Box>
            </>
          )}

          {/* -------- Output ---------- */}
          {hasOutput && (
            <>
              <Divider mt="md" mb="md" variant="dotted" />
              <Title mt="md" mb="sm" size="sm">
                Output
              </Title>
              {gsheetOutput && (
                <KeyValue
                  label="Google sheet"
                  value={gsheetOutput.title}
                  textValueProps={{
                    ff: 'monospace',
                  }}
                />
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
};
