import type { Core, CoreState } from '../../../core';
import type {
  CreateTradeConfigPayload,
  CreateTradeConfigResponse,
  DeleteTradeConfigResponse,
  GetTradeConfigResponse,
  ListTradeConfigsQueryParams,
  ListTradeConfigsResponse,
  UpdateTradeConfigPayload,
  UpdateTradeConfigResponse,
  GetXchangeAssetPairsResponse,
  GetAssetPairQueryParams,
} from '../shared_imports';
import { stripUndefinedValues } from '../../../utils';

import { init as initQueries } from './queries';

type AssetPair = GetXchangeAssetPairsResponse[number];
const cacheAssetPairs: { [key: string]: AssetPair } = {};

const initApi = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const getTradeConfigs = (
    params: ListTradeConfigsQueryParams = {},
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<ListTradeConfigsResponse>(
      '/trade-configs',
      stripUndefinedValues(params),
      { userAccount: testModeOn ? 'paper' : 'root' }
    );
  };

  const getTradeConfig = (
    tradeName: string,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<GetTradeConfigResponse>(`/trade-configs/${tradeName}`, undefined, {
      userAccount: testModeOn ? 'paper' : 'root',
    });
  };

  const create = (
    tradeName: string,
    tradeConfig: CreateTradeConfigPayload,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.post<CreateTradeConfigResponse, CreateTradeConfigPayload>(
      `/trade-configs/${tradeName}`,
      {
        body: tradeConfig,
      },
      { userAccount: testModeOn ? 'paper' : 'root' }
    );
  };

  const update = (
    tradeName: string,
    tradeConfig: UpdateTradeConfigPayload,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.put<UpdateTradeConfigResponse, UpdateTradeConfigPayload>(
      `/trade-configs/${tradeName}`,
      {
        body: tradeConfig,
      },
      { userAccount: testModeOn ? 'paper' : 'root' }
    );
  };

  const deleteTradeConfig = (
    tradeName: string,
    {
      testModeOn = coreState.testModeOn,
      closePositions = false,
    }: { testModeOn?: boolean; closePositions?: boolean } = {}
  ) => {
    const params = closePositions ? new URLSearchParams({ closePositions: 'true' }) : '';

    return httpClient.delete<DeleteTradeConfigResponse>(`/trade-configs/${tradeName}?${params}`, {
      userAccount: testModeOn ? 'paper' : 'root',
    });
  };

  const getAssetPairs = (
    assetPairs: string[],
    params?: GetAssetPairQueryParams,
    { signal }: { signal?: AbortSignal } = {}
  ) => {
    const parsed = assetPairs.map((assetPair) => assetPair.trim()).filter(Boolean);

    if (parsed.length === 0) {
      return Promise.resolve(null);
    }

    const notInCache = parsed.filter((assetPair) => !cacheAssetPairs[assetPair]);
    const inCache = parsed.filter((assetPair) => cacheAssetPairs[assetPair]);

    const assetPairsInCache = inCache.map((value) => cacheAssetPairs[value]);

    if (notInCache.length === 0) {
      return Promise.resolve(assetPairsInCache);
    }

    return httpClient
      .get<GetXchangeAssetPairsResponse>(
        `/crypto/asset-pairs/${notInCache.join(',')}`,
        stripUndefinedValues(params),
        { signal }
      )
      .then((response) => {
        response.forEach((assetPair) => {
          cacheAssetPairs[assetPair.assetPair] = assetPair;
        });
        return [...assetPairsInCache, ...response] as GetXchangeAssetPairsResponse;
      });
  };

  const api = {
    getTradeConfigs,
    getTradeConfig,
    create,
    update,
    deleteTradeConfig,
    getAssetPairs,
  };

  return api;
};

export const init = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const api = initApi({ httpClient, coreState });
  const queries = initQueries({ api, coreState });

  return {
    api,
    queries,
  };
};

export type ApiWithQueries = ReturnType<typeof init>;
export type API = ReturnType<typeof initApi>;
