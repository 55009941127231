import { FC, Fragment, useEffect, useMemo, useRef } from 'react';
import { Flex, Group, type MantineTheme, Text, useMantineTheme, Stack } from '@mantine/core';
import { IconCircleCheck, IconCircleX } from '@tabler/icons-react';
import format from 'date-fns/format';
import { useMediaQuery } from '@mantine/hooks';

import type { Message } from '../../shared_imports';
import type { MessageEnhanced } from '../../types';
import { enhanceMessage, getMessageColor, isMessageSuccessfullTrade } from './utils';

const messageTextStyle =
  ({
    size,
    dimmed = false,
    isMobile = true,
  }: {
    size: 'sm' | 'xs';
    dimmed?: boolean;
    isMobile?: boolean;
  }) =>
  (theme: MantineTheme) => {
    let color: string | undefined;
    if (dimmed) {
      color = theme.colorScheme === 'dark' ? theme.colors.dark[3] : theme.colors.gray[6];
    }
    return {
      fontFamily: theme.fontFamilyMonospace,
      fontSize: theme.fontSizes[size],
      color,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      flexBasis: isMobile ? '75%' : '85%',
    };
  };

const activeStyle = (theme: MantineTheme) => ({
  backgroundColor: theme.colorScheme === 'dark' ? theme.colors.teal[8] : theme.colors.teal[1],
});

interface Props {
  messages: Message[];
  onSelect: (msg: MessageEnhanced) => void;
  onIncomingMessages?: (messages: MessageEnhanced[]) => void;
  selectedMessage: Message | null;
}

export const Messages: FC<Props> = ({
  messages,
  selectedMessage,
  onSelect,
  onIncomingMessages,
}) => {
  const theme = useMantineTheme();
  const currentMessages = useRef<Set<number>>(new Set());
  const newMessages = useRef<MessageEnhanced[] | null>([]);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`);

  const messagesEnhanced = useMemo(() => {
    return messages.map((m) => {
      if (!currentMessages.current.has(m.timestamp)) {
        if (!newMessages.current) newMessages.current = [];
        newMessages.current.push(enhanceMessage(m));
      }
      currentMessages.current.add(m.timestamp);
      return enhanceMessage(m);
    });
  }, [messages]);

  useEffect(() => {
    if (onIncomingMessages && newMessages.current) {
      onIncomingMessages(newMessages.current);
    }
    newMessages.current = null;
  }, [messagesEnhanced, onIncomingMessages]);

  return (
    <>
      {messagesEnhanced.map((message, i) => {
        const { timestamp } = message;
        const { status } = isMessageSuccessfullTrade(message);
        const isLast = i === messages.length - 1;

        return (
          <Fragment key={timestamp}>
            <Flex
              justify="space-between"
              align="center"
              pt="xs"
              pb="xs"
              onClick={() => onSelect(message)}
              sx={{
                cursor: 'pointer',
                '&:hover': {
                  ...activeStyle(theme),
                },
                padding: theme.spacing.sm,
                borderBottom: isLast
                  ? undefined
                  : `1px solid ${
                      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[4]
                    }`,
                ...(selectedMessage?.timestamp === timestamp ? activeStyle(theme) : {}),
              }}
            >
              <Group sx={{ overflow: 'hidden', flex: 1 }}>
                {status === 'success' ? (
                  <IconCircleCheck color={getMessageColor(status, theme)} />
                ) : (
                  <IconCircleX color={getMessageColor(status, theme)} />
                )}
                <Text sx={messageTextStyle({ size: 'xs', isMobile })}>{message.text}</Text>
              </Group>
              <Stack spacing={0} align="flex-end">
                <Text sx={messageTextStyle({ size: 'xs' })}>{format(timestamp, 'kk:mm:ss')}</Text>
                <Text sx={messageTextStyle({ size: 'xs', dimmed: true })}>
                  {format(timestamp, 'MMM do')}
                </Text>
              </Stack>
            </Flex>
          </Fragment>
        );
      })}
    </>
  );
};
