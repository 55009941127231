import { type FC, useEffect, useCallback, useRef } from 'react';
import {
  ActionIcon,
  Box,
  Button,
  Grid,
  Group,
  Stack,
  Switch,
  Text,
  TextInput,
  createStyles,
} from '@mantine/core';
import { randomId, useFocusTrap, useMediaQuery } from '@mantine/hooks';
import { IconPlus, IconTrash } from '@tabler/icons-react';
import { FormSection } from './form-section';
import { useFormContext } from './create-edit-trade-config-form';
import { TimespanInput } from '../../../../components';
import { useSyncIndicators } from './use-sync-indicators';

const useStyles = createStyles((theme) => ({
  labelWrapper: {
    fontSize: theme.fontSizes.md,
  },
}));

const newIndicator = () => ({
  name: '',
  key: randomId(),
});

const MAX_CONFIG_TYPES = 10;
const noOp = () => undefined;

interface Props {
  side: 'open' | 'close';
  showFormErrors?: boolean;
}

export const TradeConfigIndicators: FC<Props> = ({ side, showFormErrors = false }) => {
  // Sync "open" and "close" indicators
  useSyncIndicators();

  const form = useFormContext();
  const { setFieldValue, insertListItem, removeListItem, values, validateField, clearFieldError } =
    form;

  const { classes } = useStyles();
  const dynamicFocusTrapRef = useFocusTrap();
  const focusTrapRef = useRef<(instance: HTMLElement | null) => void>(noOp);
  const reValidateIndicators = useRef(false);
  const isLargeScreen = useMediaQuery('(min-width: 75em)');

  let isFormVisible = side === 'open';
  if (side === 'close') {
    isFormVisible = values.__internal__.closeSettings.sameIndicatorAsOpen === false;
  }

  const {
    __internal__: { multipleIndicators },
  } = values;
  const indicators = side === 'open' ? values.indicatorsOpen : values.indicatorsClose;
  const timeSpanValue =
    side === 'open' ? values.indicatorsOpenTimeSpan : values.indicatorsCloseTimeSpan;
  const indicatorsFormPath = side === 'open' ? 'indicatorsOpen' : 'indicatorsClose';
  const indicatorsTimeSpanPath =
    side === 'open' ? 'indicatorsOpenTimeSpan' : 'indicatorsCloseTimeSpan';

  const withMultipleIndicators = multipleIndicators[side];
  const isAddIndicatorDisabled = indicators && indicators.some(({ name }) => name.trim() === '');

  const addIndicator = useCallback(() => {
    reValidateIndicators.current = true;
    focusTrapRef.current = dynamicFocusTrapRef;
    insertListItem(indicatorsFormPath, newIndicator());
  }, [insertListItem, indicatorsFormPath, dynamicFocusTrapRef]);

  const removeIndicator = useCallback(
    (index: number) => {
      reValidateIndicators.current = true;
      removeListItem(indicatorsFormPath, index);
    },
    [removeListItem, indicatorsFormPath]
  );

  const fields =
    indicators === undefined
      ? undefined
      : indicators.map((item, index) => (
          <Group key={item.key} mt="xs" ref={focusTrapRef.current}>
            <TextInput
              placeholder="Indicator name"
              required
              sx={{ flex: 1 }}
              {...form.getInputProps(`${indicatorsFormPath}.${index}.name`)}
              error={
                showFormErrors && form.getInputProps(`${indicatorsFormPath}.${index}.name`).error
              }
              onChange={(e) => {
                const { value } = e.currentTarget;
                const valueSerialized = value.trim().replace(/[^a-zA-Z0-9]/g, '');
                setFieldValue(`${indicatorsFormPath}.${index}.name`, valueSerialized);
              }}
              onKeyDown={(e) => {
                const isLast = indicators && index === indicators.length - 1;
                const { value } = e.currentTarget;
                if (e.key === 'Enter' && isLast && value.trim().length > 0) {
                  e.preventDefault();
                  addIndicator();
                }
              }}
            />

            <ActionIcon
              color="red"
              onClick={() => removeIndicator(index)}
              disabled={indicators && indicators.length <= 2}
            >
              <IconTrash size="1rem" />
            </ActionIcon>
          </Group>
        ));

  const renderForm = () => {
    return (
      <Box
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
          padding: theme.spacing.md,
          borderRadius: theme.radius.md,
        })}
      >
        <Grid gutter={isLargeScreen ? undefined : 0}>
          {side === 'close' && (
            <Grid.Col span={12}>
              <Stack mb={isFormVisible ? 'md' : undefined}>
                <Switch
                  {...form.getInputProps('__internal__.closeSettings.sameIndicatorAsOpen', {
                    type: 'checkbox',
                  })}
                  size="xs"
                  label="Same as when opening a position"
                  classNames={classes}
                />
              </Stack>
            </Grid.Col>
          )}
          {isFormVisible && (
            <>
              <Grid.Col lg={5}>
                <Stack>
                  <Switch
                    {...form.getInputProps(`__internal__.multipleIndicators.${side}`, {
                      type: 'checkbox',
                    })}
                    size="xs"
                    label="Multiple indicators"
                    classNames={classes}
                  />
                  <Text size="xs" color="dimmed">
                    {`Requires the confluence of multiple indicators to ${side} a position.`}
                  </Text>
                </Stack>
              </Grid.Col>
              {withMultipleIndicators && fields && fields.length > 0 && (
                <Grid.Col lg={7}>
                  {fields}

                  {fields.length < MAX_CONFIG_TYPES && (
                    <Group mt="md">
                      <Button
                        onClick={addIndicator}
                        leftIcon={<IconPlus size="1rem" />}
                        variant="outline"
                        size="xs"
                        disabled={isAddIndicatorDisabled}
                      >
                        Add indicator
                      </Button>
                    </Group>
                  )}
                </Grid.Col>
              )}
              {withMultipleIndicators && fields && fields.length >= 2 && (
                <>
                  <Grid.Col lg={5} pt={isLargeScreen ? 0 : 'lg'}>
                    <Text>Time span</Text>
                    <Text size="xs" color="dimmed">
                      {`The maximum time span for all indicators to trigger ${
                        side === 'open' ? 'an "open"' : 'a "close"'
                      } signal.`}
                    </Text>
                  </Grid.Col>
                  <Grid.Col lg={7}>
                    <TimespanInput
                      label="Time span value"
                      fieldPath={indicatorsTimeSpanPath}
                      form={form}
                      defaultValue={timeSpanValue}
                      showFormErrors={showFormErrors}
                      required
                    />
                  </Grid.Col>
                </>
              )}
            </>
          )}
        </Grid>
      </Box>
    );
  };

  useEffect(() => {
    if (withMultipleIndicators && (indicators === undefined || indicators.length === 0)) {
      if (indicators === undefined || indicators.length === 0) {
        setFieldValue(indicatorsFormPath, [newIndicator(), newIndicator()]);
      } else {
        addIndicator();
      }
    } else if (!withMultipleIndicators && indicators && indicators.length > 0) {
      reValidateIndicators.current = false;

      if (indicators) {
        indicators.forEach((_, index) => {
          clearFieldError(`${indicatorsFormPath}.${index}.name`);
        });
      }

      clearFieldError(indicatorsTimeSpanPath);
      setFieldValue(indicatorsFormPath, []);
      setFieldValue(indicatorsTimeSpanPath, undefined);
    }
  }, [
    withMultipleIndicators,
    addIndicator,
    setFieldValue,
    clearFieldError,
    indicators,
    dynamicFocusTrapRef,
    indicatorsFormPath,
    indicatorsTimeSpanPath,
  ]);

  useEffect(() => {
    if (!reValidateIndicators.current) {
      return;
    }

    indicators?.forEach((_, index) => {
      validateField(`${indicatorsFormPath}.${index}.name`);
    });

    reValidateIndicators.current = false;
  }, [indicators, validateField, indicatorsFormPath]);

  useEffect(() => {
    // We are only interested in setting the focus when adding an indicator.
    // Once it is added, we disable the focus trap. This way when we toggle on/off
    // the "multiple indicators" switch, the focus trap will not be triggered.
    focusTrapRef.current = noOp;
  }, [indicators]);

  return (
    <FormSection
      title="Indicators"
      description={`Configure the indicators that will trigger the ${
        side === 'open' ? 'opening' : 'closing'
      } of a position.`}
      titleOrder={3}
    >
      {renderForm()}
    </FormSection>
  );
};
