import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApp } from '../context';

export const useDeleteMe = () => {
  const { getApi } = useApp();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => {
      return getApi().api.deleteMe();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['users', 'me'] });
    },
  });
};
