import React, { useEffect, useState } from 'react';
import type { XchangeConnectorId } from '../../shared_imports';
import { useCore } from '../../core';
import { renderDecimalValue } from '../../utils';

interface Props {
  pl?: string;
  quote: string;
  /** Required if "pl" is not provided */
  assetPair?: string;
  /** Required if "pl" is not provided */
  xchange?: XchangeConnectorId | 'paper';
  /** Required if "pl" is not provided */
  openPrice?: string;
  /** Required if "pl" is not provided */
  size?: string;
}

function PlComp({ pl: plProp = '', assetPair, xchange = 'paper', openPrice, size, quote }: Props) {
  // Validate props
  if (!plProp && (!assetPair || !openPrice || !xchange || !size)) {
    throw new Error(
      '<Pl /> component requires either (1) "roi" or (2) "assetPair", "openPrice", "size and "xchange" props to be provided'
    );
  }
  const {
    assets: { pricesService },
  } = useCore();

  const [pl, setPl] = useState(plProp);

  useEffect(() => {
    if (!assetPair || !openPrice || !xchange || !size) {
      return () => undefined;
    }
    pricesService.addAssetPairs(xchange, [assetPair]);

    const subscription = pricesService.getPl$(assetPair, openPrice, size).subscribe((plValue) => {
      setPl(plValue);
    });

    return () => subscription.unsubscribe();
  }, [pricesService, assetPair, openPrice, xchange, size]);

  return <span>{renderDecimalValue(pl, { quote })}</span>;
}

export const Pl = React.memo(PlComp);
