import { FC, useCallback, useEffect, useState } from 'react';
import { Table, createStyles, Text, Center, Title, rem } from '@mantine/core';
import { modals } from '@mantine/modals';
import { useSearchParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import type { EnhancedPositionOpen, PluginStart, Position } from '../types';
import { Th } from '../../../components';
import { ColumnConfig, PositionRow, defaultColumnConfig } from './position_row';
import { PositionDetailsDrawer } from './position_detail_drawer';
import { useClosePosition } from '../api';

const useStyles = createStyles((theme) => ({
  tBody: {
    '& > tr': {
      cursor: 'pointer',
    },
  },
  isBeingUpdated: {
    color: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3],
  },
}));

export type SortBy = 'timestamp' | 'pl' | 'roi' | 'pair' | 'asset' | 'signal';

interface Props {
  columnConfig?: ColumnConfig;
  defaultSortOrder?: 'asc' | 'desc';
  doUpdateUrlSearch?: boolean;
  doShowTableWhenEmpty?: boolean;
  getApi: PluginStart['getApi'];
  isLoading?: boolean;
  onSortChange?: (sortBy: SortBy, sortOrder: 'asc' | 'desc') => void;
  positions?: Position[] | null;
  positionsStatus: 'open' | 'closed';
  sortBy?: SortBy;
  sortEnabled?: {
    asset?: boolean;
    pl?: boolean;
    roi?: boolean;
    signal?: boolean;
  };
  sortOrder?: 'asc' | 'desc';
}

export const PositionsTable: FC<Props> = ({
  columnConfig: _columnConfig = {},
  defaultSortOrder = 'desc',
  doUpdateUrlSearch = false,
  doShowTableWhenEmpty = true,
  getApi,
  onSortChange,
  positions,
  positionsStatus,
  sortBy = 'timestamp',
  sortEnabled,
  sortOrder = 'desc',
  isLoading = false,
}) => {
  const {
    asset: isSortableByAsset = true,
    pl: isSortableByPl = true,
    roi: isSortableByRoi = true,
    signal: isSortableBySignal = true,
  } = sortEnabled ?? {};

  const columnConfig = { ...defaultColumnConfig, ..._columnConfig };

  const { classes } = useStyles();
  const [selectedPosition, setSelectedPosition] = useState<Position | null>(null);
  const closePositionMutation = useClosePosition(getApi);
  const [searchParams, setSearchParams] = useSearchParams();

  const totalPositions = positions?.length ?? 0;
  const isEmpty = !isLoading && totalPositions === 0;
  const showTable = !isEmpty || doShowTableWhenEmpty;

  const closePosition = useCallback(
    (position: Pick<EnhancedPositionOpen, 'openedAt' | 'id'>) => {
      modals.openConfirmModal({
        title: `Close position`,
        centered: false,
        children: <Text size="sm">Are you sure you want to close this position?</Text>,
        labels: { confirm: 'Close position', cancel: "No don't close it" },
        confirmProps: { color: 'red' },
        onConfirm: () => {
          setSelectedPosition(null);
          closePositionMutation.mutate({ positionId: position.id });
        },
      });
    },
    [closePositionMutation]
  );

  const handleSortChange = useCallback(
    (updated: SortBy) => {
      let nextSortOder: 'asc' | 'desc' = sortOrder === 'asc' ? 'desc' : 'asc';

      if (doUpdateUrlSearch) {
        if (sortBy !== updated) {
          searchParams.set('sortBy', updated);
          searchParams.set('sortOrder', defaultSortOrder);
          nextSortOder = defaultSortOrder;
        } else {
          searchParams.set('sortOrder', nextSortOder);
        }

        setSearchParams(searchParams);
      }

      if (onSortChange) {
        onSortChange(updated, nextSortOder);
      }
    },
    [
      doUpdateUrlSearch,
      searchParams,
      setSearchParams,
      sortBy,
      sortOrder,
      defaultSortOrder,
      onSortChange,
    ]
  );

  useEffect(() => {
    if (closePositionMutation.data) {
      const [position] = closePositionMutation.data.positions;
      if (position) {
        notifications.show({
          title: 'Done.',
          message: `Position #${position.id} has been closed.`,
        });
      }
    }
  }, [closePositionMutation.data]);

  return (
    <>
      {showTable && (
        <Table highlightOnHover>
          <thead>
            <tr>
              {!!columnConfig.pair?.visible && (
                <Th
                  sorted={sortBy === 'pair'}
                  reversed={sortOrder === 'asc'}
                  isActive={isSortableByAsset}
                  onSort={() => {
                    handleSortChange('pair');
                  }}
                  width={columnConfig.pair.width}
                >
                  Pair
                </Th>
              )}

              {!!columnConfig.date?.visible && (
                <Th
                  sorted={sortBy === 'timestamp' || !sortBy}
                  reversed={sortOrder === 'asc'}
                  onSort={() => {
                    handleSortChange('timestamp');
                  }}
                  width={columnConfig.date.width}
                >
                  {positionsStatus === 'open' ? 'Open' : 'Close'} date
                </Th>
              )}

              {!!columnConfig.pl?.visible && (
                <Th
                  sorted={sortBy === 'pl'}
                  reversed={sortOrder === 'asc'}
                  onSort={() => {
                    handleSortChange('pl');
                  }}
                  width={columnConfig.pl.width}
                  isActive={isSortableByPl}
                >
                  P/L
                </Th>
              )}

              {!!columnConfig.roi?.visible && (
                <Th
                  sorted={sortBy === 'roi'}
                  reversed={sortOrder === 'asc'}
                  onSort={() => {
                    handleSortChange('roi');
                  }}
                  width={columnConfig.roi.width}
                  isActive={isSortableByRoi}
                >
                  ROI
                </Th>
              )}

              {!!columnConfig.trigger?.visible && (
                <Th
                  sorted={sortBy === 'signal'}
                  reversed={sortOrder === 'asc'}
                  isActive={isSortableBySignal}
                  width={columnConfig.trigger.width}
                  onSort={() => {
                    handleSortChange('signal');
                  }}
                >
                  Trigger
                </Th>
              )}

              {!!columnConfig.rowActions?.visible && positionsStatus === 'open' && (
                <th
                  style={{ width: columnConfig.rowActions.width, textAlign: 'right', padding: 0 }}
                ></th>
              )}
            </tr>
          </thead>
          <tbody className={classes.tBody}>
            {positions &&
              positions.map((position) => {
                const isBeingUpdated =
                  closePositionMutation.variables?.positionId === position.id &&
                  closePositionMutation.isLoading;

                return (
                  <PositionRow
                    key={position.id}
                    position={position}
                    onSelectPosition={() => setSelectedPosition(position)}
                    columnConfig={columnConfig}
                    closePosition={closePosition}
                    isBeingUpdated={isBeingUpdated}
                    className={isBeingUpdated ? classes.isBeingUpdated : undefined}
                  />
                );
              })}
          </tbody>
        </Table>
      )}

      {isEmpty && (
        <Center mt={doShowTableWhenEmpty ? rem(32) : 'md'}>
          <Title order={3} style={{ textAlign: 'center' }} color="dimmed" size="md">
            No positions
          </Title>
        </Center>
      )}

      <PositionDetailsDrawer
        opened={selectedPosition !== null}
        close={() => setSelectedPosition(null)}
        position={selectedPosition}
        closePosition={closePosition}
      />
    </>
  );
};
