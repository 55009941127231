import { BehaviorSubject } from 'rxjs';

import type { QueryClient } from '@tanstack/react-query';
import type {
  LoginPayload,
  LoginQueryParams,
  LoginResponse,
  LogoutResponse,
  PasswordResetPayload,
  PasswordResetRequestPayload,
  PasswordResetRequestResponse,
  PasswordResetResponse,
  RegisterPayload,
  RegisterResponse,
  VerifyRegistrationPayload,
  VerifyRegistrationResponse,
} from '../../shared_imports';
import { stripUndefinedValues } from '../../utils';
import type { HttpClient, RequestInterceptor, ResponseInterceptor } from '../http';
import { originalFetch } from '../http';
import type { CoreState } from '../core';

export interface AuthAccess {
  accessToken: string;
}

let refreshPromise: Promise<{ status: 'success' }> | null = null;

export const init = ({ http, queryClient }: { http: HttpClient; queryClient: QueryClient }) => {
  const cookieObj = new URLSearchParams(
    document.cookie.replaceAll('&', '%26').replaceAll('; ', '&')
  );

  const isLoggedInDefaultValue = Boolean(cookieObj.get('sessionId'));

  let coreState: CoreState | undefined;

  const isLoggedIn = new BehaviorSubject<boolean>(isLoggedInDefaultValue);
  const observable = isLoggedIn.asObservable();

  const isLoggedIn$ = () => {
    return observable;
  };

  const onLogOut = () => {
    queryClient.clear();
    document.cookie = 'sessionId=;secure;maxAge=1;path=/';
    isLoggedIn.next(false);
    if (coreState) {
      coreState.set.testModeOn(false);
    }
    // TODO: Add extension point to plugin can register sync handler to run here...
    // e.g. we want to delete the LOCALSTORAGE_KEY from tradeConfig that stores the "signal-form"
  };

  const requestInterceptor: RequestInterceptor = async (resource, config) => {
    if (refreshPromise) {
      await refreshPromise;
      if (!isLoggedIn.value) {
        // Abort request we are not logged in
        return null;
      }
    }
    return [resource, config];
  };

  const responseInterceptor: ResponseInterceptor = async (response, request) => {
    const hasAuthError = response.status === 401 || response.status === 403;

    if (hasAuthError) {
      const isRefreshOrLoginResponse =
        response.url.includes('/auth/refresh') || response.url.includes('/auth/login');
      const canBeRefreshed =
        (request[1].headers as Record<string, string>)?.['Auth-Refresh'] !== 'false';

      if (!isRefreshOrLoginResponse && canBeRefreshed) {
        // Try to get a new accessToken
        if (!refreshPromise) {
          refreshPromise = originalFetch(
            http.addEndpoint('/auth/refresh'),
            http.getDefaultInit()
          ).then((res) => res.json());
        }

        const tokenRefresh: { status: 'success' } = await refreshPromise;
        refreshPromise = null;

        if (tokenRefresh.status === 'success') {
          // Re-execute original request
          const [resource, config] = request;
          // Add a header to make sure we don't enter in a refresh loop
          config.headers = { ...config.headers, 'Auth-Refresh': 'false' };
          return fetch(resource, config);
        }
      }

      onLogOut();
    }
    return response;
  };

  http.addRequestInterceptor(requestInterceptor);
  http.addResponseInterceptor(responseInterceptor);

  const setup = ({ coreState: _coreState }: { coreState: CoreState }) => {
    coreState = _coreState;
  };

  const login = async (
    credentials: LoginPayload,
    queryParams: LoginQueryParams = {}
  ): Promise<boolean> => {
    const response = await http.post<LoginResponse, LoginPayload>('/auth/login', {
      body: credentials,
      queryParams: stripUndefinedValues(queryParams),
    });

    const result = response.status === 'success';

    isLoggedIn.next(result);
    return result;
  };

  const register = async (body: RegisterPayload) => {
    const response = await http.post<RegisterResponse, RegisterPayload>('/auth/register', {
      body,
      headers: {
        'Auth-Refresh': 'false',
      },
    });
    return response;
  };

  const verifyRegistration = async (body: VerifyRegistrationPayload) => {
    const response = await http.post<VerifyRegistrationResponse, VerifyRegistrationPayload>(
      '/auth/register/verify',
      {
        body,
      }
    );
    return response;
  };

  const requestPasswordReset = async (body: PasswordResetRequestPayload) => {
    const response = await http.post<PasswordResetRequestResponse, PasswordResetRequestPayload>(
      '/auth/password-reset-request',
      {
        body,
      }
    );
    return response;
  };

  const passwordReset = async (body: PasswordResetPayload) => {
    const response = await http.post<PasswordResetResponse, PasswordResetPayload>(
      '/auth/password-reset',
      {
        body,
      }
    );
    return response;
  };

  const logout = async () => {
    onLogOut();
    await http.get<LogoutResponse>('/auth/logout');
  };

  return {
    isLoggedIn$,
    setup,
    login,
    logout,
    register,
    verifyRegistration,
    passwordReset,
    requestPasswordReset,
  };
};

export type Authentication = ReturnType<typeof init>;
