import { useEffect, useState } from 'react';
import { useCore } from '../core';
import type { XchangeConnectorId } from '../shared_imports';

export const useCurrentPrice = ({
  assetPair,
  xchange = 'paper',
}: {
  assetPair: string;
  xchange: XchangeConnectorId | 'paper';
}) => {
  const {
    assets: { pricesService },
  } = useCore();

  const [price, setPrice] = useState('');

  useEffect(() => {
    pricesService.addAssetPairs(xchange, [assetPair]);

    const subscription = pricesService.getPrice$(assetPair).subscribe((priceValue) => {
      setPrice(priceValue);
    });

    return () => subscription.unsubscribe();
  }, [pricesService, assetPair, xchange]);

  return price;
};
