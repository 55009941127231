import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Center, Loader } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import { notifications } from '@mantine/notifications';
import { useDebouncedValue } from '@mantine/hooks';

import type { GsheetConnector } from '../../../../shared_imports';
import { useHttpErrorNotification } from '../../../../utils';
import { useCoreState } from '../../../../core';
import { NotFoundTitle } from '../../../../components';
import type {
  CreateTradeConfigPayload,
  TradeConfigOutputPayload,
  BalanceItem,
  PaperBalanceItem,
  TradeConfig,
  TradeConfigStatus,
  ListConnectorResponse,
  TradeConfigConditions,
} from '../../shared_imports';
import { Page } from '../../shared_imports';
import { useApp } from '../../context';
import { useCreateTradeConfig, useUpdateTradeConfig } from '../../api';
import { CreateEditTradeConfigForm, type FormValues } from './create-edit-trade-config-form';

export const LOCALSTORAGE_KEY = 'signal-form';

const formValuesToPayload = (values: FormValues): CreateTradeConfigPayload => {
  const {
    ticker,
    xchange,
    allocation,
    exitType,
    indicatorsOpen,
    indicatorsOpenTimeSpan,
    indicatorsClose,
    indicatorsCloseTimeSpan,
    pipesConfig,
    __internal__: { closeSettings },
  } = values;

  const output: TradeConfigOutputPayload = {
    xchange: {
      id: xchange ?? 'paper',
    },
  };

  // For now that's the only reason to include the config object in the payload
  const includePipeConfig = () => pipesConfig?.gsheet?.enabled === false;

  const payload: CreateTradeConfigPayload & {
    conditions: { open?: TradeConfigConditions['open']; close?: TradeConfigConditions['close'] };
  } = {
    ticker,
    outputs: [output],
    pipesConfig: includePipeConfig() ? pipesConfig : {},
    conditions: {},
  };

  if (allocation) {
    const { type, value } = allocation;
    output.allocation = {
      type,
      value: type === 'percCurrentCapital' ? value / 100 : value,
    };
  }

  const updateConditions = (
    side: 'open' | 'close',
    conditions:
      | Partial<Required<TradeConfigConditions['open']>>
      | Partial<Required<TradeConfigConditions['close']>>
  ) => {
    if (!payload.conditions[side]) {
      payload.conditions[side] = {};
    }
    payload.conditions[side] = {
      ...payload.conditions[side],
      ...conditions,
    };
  };

  if (values.conditions?.open) {
    updateConditions('open', values.conditions.open);
  }
  if (values.conditions?.close) {
    updateConditions('close', values.conditions.close);
  }

  if (indicatorsOpen && indicatorsOpen.length > 0) {
    updateConditions('open', {
      types: {
        required: indicatorsOpen.map(({ name }) => name),
        maxDelayBetweenTypes: indicatorsOpenTimeSpan ?? 0,
      },
    });
  }

  if (indicatorsClose && indicatorsClose.length > 0) {
    updateConditions('close', {
      types: {
        required: indicatorsClose.map(({ name }) => name),
        maxDelayBetweenTypes: indicatorsCloseTimeSpan ?? 0,
      },
    });
  }

  if (closeSettings.trigger === 'manual') {
    output.exitType = 'manual';
    delete payload.conditions?.close;
  } else if (exitType) {
    output.exitType = exitType;
  }

  return payload;
};

// Convert the form values to a valid TradeConfig object that we can use in the Summary component.
const formValuesToTradeConfig = (
  values?: FormValues,
  {
    assetPairMeta,
    status,
  }: {
    assetPairMeta?: { base: string; quote: string; assetPair: string } | null;
    status?: TradeConfigStatus;
  } = {}
): Omit<TradeConfig, 'tradeId'> | undefined => {
  if (!values) {
    return undefined;
  }

  const { outputs: _outputs, ...payload } = formValuesToPayload(values);

  let outputs: TradeConfig['outputs'];
  if (_outputs && _outputs.length > 0) {
    const { xchange, user, allocation, exitType } = _outputs[0]!;

    outputs = [
      {
        xchange: xchange ?? { id: 'paper' },
        user: user ? { ...user, username: '' } : { username: '', uuid: '' },
        allocation,
        exitType,
      },
    ];
  } else {
    outputs = [{ xchange: { id: 'paper' }, user: { username: '', uuid: '' } }];
  }

  return {
    tradeName: values.tradeName,
    createdAt: new Date().toDateString(),
    updatedAt: new Date().toDateString(),
    asset: assetPairMeta?.base ?? '',
    quote: assetPairMeta?.quote ?? '',
    status: status ?? 'on',
    outputs,
    ...payload,
    ticker: assetPairMeta?.assetPair ?? '',
  };
};

interface FormContext {
  tradeConfig?: Omit<TradeConfig, 'tradeId'>;
  isEdit: boolean;
  balance?: BalanceItem | PaperBalanceItem;
  isLoadingBalances: boolean;
  userConnectors?: ListConnectorResponse | null;
  gsheetConnector?: GsheetConnector;
}

const tradeConfigFormContext = createContext<FormContext | undefined>(undefined);

export const useTradeConfigFormContext = () => {
  const context = useContext(tradeConfigFormContext);
  if (context === undefined) {
    throw new Error(
      'useTradeConfigFormContext must be used within a TradeConfigFormContextProvider'
    );
  }
  return context;
};

export const CreateEditTradeConfig = () => {
  const [coreState] = useCoreState();
  const { testModeOn, user } = coreState;
  const navigate = useNavigate();
  const { getApi, getPlugins } = useApp();
  const { portfolio, connectors } = getPlugins();
  const { useTradeConfig, useAssetPairs } = getApi({ coreState }).queries;
  const { name: tradeNameParams } = useParams();

  const isEdit = tradeNameParams !== undefined;

  const [formValues, setFormValues] = useState<FormValues | undefined>();

  const {
    mutate: createMutate,
    data: createMutateResponse,
    error: createMutateError,
    isLoading: createMutateIsLoading,
  } = useCreateTradeConfig();

  const {
    mutate: updateMutate,
    data: updateMutateResponse,
    error: updateMutateError,
    isLoading: updateMutateIsLoading,
  } = useUpdateTradeConfig();

  const {
    data: tradeConfig,
    isLoading: isLoadingTradeConfig,
    error: errorFetchTradeConfig,
  } = useTradeConfig(tradeNameParams);

  let { xchange, ticker } = formValues ?? {};
  xchange = xchange ?? tradeConfig?.outputs[0]?.xchange.id;
  ticker = ticker ?? tradeConfig?.ticker ?? '';

  const [debouncedAssetPair] = useDebouncedValue(ticker, 500);
  const willLoadAssetPair = ticker !== debouncedAssetPair;

  const assetPairToArray = useMemo(() => {
    if (isEdit || !debouncedAssetPair) {
      // In edit mode we already know the asset and quote, no need to fetch it
      return [];
    }
    return [debouncedAssetPair];
  }, [debouncedAssetPair, isEdit]);

  const {
    data: assetPairs,
    error: assetPairError,
    isFetching: _isFetchingAssetPair,
  } = useAssetPairs({ xchange }, assetPairToArray);

  const {
    balances: { data: userBalances, isLoading: _isLoadingBalances },
    connectors: { data: userConnectors, isLoading: isLoadingConnectors },
  } = portfolio
    .getApi({ coreState })
    .queries.useBalances(
      { useConnectors: connectors.queries.useConnectors },
      testModeOn ? ['paper'] : [xchange]
    );

  const isFetchingAssetPair =
    (willLoadAssetPair && ticker.trim().length > 0) || _isFetchingAssetPair;
  const isLoadingBalances = (_isLoadingBalances || isLoadingConnectors) && !userBalances;

  const formSubmitResponse = createMutateResponse || updateMutateResponse;
  const formSubmitError = createMutateError || updateMutateError;
  const isBeingSubmitted = createMutateIsLoading || updateMutateIsLoading;
  useHttpErrorNotification(formSubmitError, 'Error creating signal');

  let assetPairMeta: { quote: string; base: string; assetPair: string } | undefined;

  if (isEdit && tradeConfig) {
    assetPairMeta = {
      base: tradeConfig.asset,
      quote: tradeConfig.quote,
      assetPair: tradeConfig.ticker,
    };
  } else if (!isEdit && !isFetchingAssetPair) {
    const assetPairData = assetPairs?.[0];
    if (assetPairData) {
      assetPairMeta = { ...assetPairData };
    }
  }

  let balance: BalanceItem | PaperBalanceItem | undefined;
  if (userBalances && assetPairMeta) {
    const balances = userBalances[xchange ?? 'paper'];
    balance = balances?.[assetPairMeta.quote];
  }

  const gsheetConnector = userConnectors?.find(({ id }) => id === 'gsheet') as
    | GsheetConnector
    | undefined;

  const onSubmitForm = useCallback(
    (updatedFormValues: FormValues) => {
      const payload = formValuesToPayload(updatedFormValues);
      const { tradeName } = updatedFormValues;

      if (isEdit) {
        const { ticker: _t, ...rest } = payload;
        updateMutate({
          tradeName,
          tradeConfig: rest,
        });
      } else {
        createMutate({
          tradeName,
          tradeConfig: payload,
        });
      }
    },
    [createMutate, isEdit, updateMutate]
  );

  const ctx = useMemo<FormContext>(() => {
    return {
      tradeConfig: formValuesToTradeConfig(formValues, {
        assetPairMeta,
        status: tradeConfig?.status,
      }),
      balance,
      userConnectors,
      gsheetConnector,
      isEdit,
      isLoadingBalances,
    };
  }, [
    assetPairMeta,
    isEdit,
    formValues,
    tradeConfig,
    userConnectors,
    gsheetConnector,
    balance,
    isLoadingBalances,
  ]);

  useEffect(() => {
    if (formSubmitResponse) {
      notifications.show({
        title: 'Awesome!',
        message: `Signal ${isEdit ? 'updated' : 'created'} successfully.`,
      });
      window.localStorage.removeItem(LOCALSTORAGE_KEY);
      navigate('/signals');
    }
  }, [formSubmitResponse, navigate, isEdit]);

  if (!user) {
    return null;
  }

  if (!isLoadingTradeConfig && errorFetchTradeConfig && errorFetchTradeConfig.statusCode === 404) {
    return <NotFoundTitle />;
  }

  const webhookId = testModeOn
    ? user.children.find(({ username }) => username === 'paper')?.meta.webhookId
    : user.meta.webhookId;

  return (
    <tradeConfigFormContext.Provider value={ctx}>
      <Page>
        {isLoadingConnectors || !userConnectors || (isEdit && isLoadingTradeConfig) ? (
          <Center sx={(theme) => ({ marginTop: `calc(${theme.spacing.lg} * 2)` })}>
            <Loader variant="dots" />
          </Center>
        ) : (
          <CreateEditTradeConfigForm
            isBeingSubmitted={isBeingSubmitted}
            testModeOn={testModeOn}
            webhookId={webhookId}
            onFormValuesChange={setFormValues}
            onSubmitForm={onSubmitForm}
            isFetchingAssetPair={isFetchingAssetPair}
            isLoadingConnectors={isLoadingConnectors}
            assetPairMeta={assetPairMeta}
            tradeConfig={tradeConfig}
            assetPairError={assetPairError}
          />
        )}
      </Page>
    </tradeConfigFormContext.Provider>
  );
};
