import { createContext, useContext } from 'react';
import type { FC, ReactNode } from 'react';

import type { API, Queries } from './api';
import type { CoreState, HttpClient, I18n } from '../../core';

export interface AppContext {
  i18n: I18n;
  httpClient: HttpClient;
  getApi: (args: { coreState: CoreState }) => {
    api: API;
    queries: Queries;
  };
}

const appContext = createContext<AppContext | null>(null);
const ContextProvider = appContext.Provider;

export const AppProvider: FC<AppContext & { children: ReactNode }> = ({ children, ...rest }) => {
  return <ContextProvider value={rest}>{children}</ContextProvider>;
};

export const useApp = () => {
  const ctx = useContext(appContext);
  if (!ctx) {
    throw new Error(`useContext() must be used within a <AppProvider />.`);
  }
  return ctx;
};
