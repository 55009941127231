import deepEqual from 'react-fast-compare';
import { useEffect, useMemo, useRef, useCallback } from 'react';

import { useFormContext } from './create-edit-trade-config-form';

export const useSyncIndicators = () => {
  const form = useFormContext();
  const { values, setValues } = form;
  const {
    indicatorsOpen,
    indicatorsOpenTimeSpan,
    __internal__: {
      closeSettings: { sameIndicatorAsOpen },
    },
  } = values;

  const prevValuesToCopy = useRef({ indicatorsOpenTimeSpan, indicatorsOpen });

  const valuesToCopy = useMemo(() => {
    const nextValue = { indicatorsOpenTimeSpan, indicatorsOpen };
    if (!deepEqual(prevValuesToCopy.current, nextValue)) {
      prevValuesToCopy.current = nextValue;
    }
    return prevValuesToCopy.current;
  }, [indicatorsOpenTimeSpan, indicatorsOpen]);

  const copyValues = useCallback(() => {
    if (!sameIndicatorAsOpen) {
      return;
    }

    setValues((prev) => {
      return {
        ...prev,
        indicatorsClose: prev.indicatorsOpen,
        indicatorsCloseTimeSpan: prev.indicatorsOpenTimeSpan,
        __internal__: {
          ...prev.__internal__!,
          multipleIndicators: {
            ...prev.__internal__!.multipleIndicators,
            close: prev.__internal__!.multipleIndicators.open,
          },
        },
      };
    });
  }, [setValues, sameIndicatorAsOpen]);

  useEffect(() => {
    copyValues();
  }, [copyValues, valuesToCopy]);
};
