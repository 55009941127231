import type { FC, ReactNode } from 'react';
import { Alert, Space } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import type { BaseError } from '../core';

interface Props {
  error: BaseError | null;
  title?: string;
  children?: ReactNode;
}

export const ApiErrorAlert: FC<Props> = ({ title, error, children }) => {
  if (!error) {
    return null;
  }

  const { message } = error;
  const code = error.code ? `Code: ${error.code}` : undefined;

  return (
    <Alert
      icon={<IconAlertCircle size="1rem" />}
      title={title ?? 'Bummer!'}
      color="red"
      sx={(theme) => ({ marginTop: `calc(${theme.spacing.lg} * 2)` })}
    >
      {children && (
        <>
          {children}
          <Space />
        </>
      )}
      <div>
        Error: {error.name}
        {message ? ` - ${message}` : ''}
        {code ? (
          <>
            <br />
            {code}
          </>
        ) : null}
      </div>
    </Alert>
  );
};
