import type { Core, Plugin } from '../../core';
import type { ConnectorsPluginStart } from '../connectors';
import { init as initRoute } from './route';
import { init as initApi } from './api';
import { PLUGIN_ID } from './constants';
import type { PluginStart } from './types';

export interface PluginDependencies {
  connectors: ConnectorsPluginStart;
}

export class UserPlugin implements Plugin<void, PluginDependencies> {
  id = PLUGIN_ID;

  private pluginDeps: PluginDependencies | undefined;

  setup(core: Core) {
    const routes = initRoute({
      getPlugins: () => {
        if (!this.pluginDeps) {
          throw new Error(`Plugins dependencies missing`);
        }
        return this.pluginDeps;
      },
      getApi: () => initApi({ httpClient: core.httpClient }),
    });
    core.router.registerRoutes(routes);
  }

  start(_: Core, plugins: PluginDependencies): PluginStart {
    this.pluginDeps = plugins;

    return {};
  }
}

export default UserPlugin;
