import { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Flex, Title, Badge, Group, Text, Loader, Center } from '@mantine/core';

import BigNumber from 'bignumber.js';
import type {
  BalanceItem,
  ListMessagesQueryParams,
  PaperBalanceItem,
  XchangeConnectorId,
} from '../../../../shared_imports';
import { ApiErrorAlert, MessagesInspector, NotFoundTitle, Roi } from '../../../../components';
import { useCoreState } from '../../../../core';
import { Page } from '../../shared_imports';
import { DetailsPanel } from './details-panel';
import { useApp } from '../../context';
import { RESULT_DECIMALS } from '../../../../constants';
import type { MessageEnhanced } from '../../../../types';

export const BotDetails = () => {
  const [coreState] = useCoreState();
  const { getPlugins, getApi } = useApp();
  const [idx, setIdx] = useState(0);
  const { useTradeConfig } = getApi({ coreState }).queries;
  const { portfolio, connectors, webhook, stats } = getPlugins();

  const lastFetchStatsTime = useRef(Date.now());
  const refetchStatsTimeout = useRef<NodeJS.Timeout>();

  const { testModeOn } = coreState;

  const { name: tradeName } = useParams();
  const { data: tradeConfig, isLoading, error: errorFetchTradeConfig } = useTradeConfig(tradeName);
  const { data: tradeConfigStats, refetch: refetchTradeConfigStats } = stats
    .getApi({ coreState })
    .queries.useTradeConfigStats(tradeConfig?.tradeId);

  let xchange: XchangeConnectorId | 'paper' | undefined;
  let xchangeForBalances: Array<typeof xchange> | undefined;
  if (tradeConfig) {
    const [output] = tradeConfig.outputs;
    xchange = output.xchange.id;
    xchangeForBalances = [xchange];
  }
  const isActive = tradeConfig?.status !== 'off';

  const useMessages = (params: ListMessagesQueryParams, options?: { autoRefetch?: boolean }) => {
    return webhook
      .getApi({ coreState })
      .queries.useMessagesTradeConfig({ ...params, tradeId: tradeConfig?.tradeId }, options);
  };

  const {
    balances: { data: userBalances },
  } = portfolio
    .getApi({ coreState })
    .queries.useBalances(
      { useConnectors: connectors.queries.useConnectors },
      testModeOn ? ['paper'] : xchangeForBalances
    );
  let balance: BalanceItem | PaperBalanceItem | undefined;

  if (tradeConfig && userBalances && xchange) {
    const balances = userBalances[xchange];
    const noBalance: BalanceItem | PaperBalanceItem = testModeOn
      ? {
          type: 'paper',
          value: '0',
          capitalId: '',
        }
      : {
          type: 'exchange',
          value: '0',
        };
    balance = balances?.[tradeConfig.quote] ?? noBalance;
  }

  const scheduleRefetchStats = useCallback(() => {
    const now = Date.now();
    const minTimeBetweenRequests = 1500;

    const proceed = () => {
      if (refetchStatsTimeout.current) {
        clearTimeout(refetchStatsTimeout.current);
      }
      refetchStatsTimeout.current = undefined;
      lastFetchStatsTime.current = now;
      refetchTradeConfigStats();
    };

    if (now - lastFetchStatsTime.current < minTimeBetweenRequests || refetchStatsTimeout.current) {
      if (refetchStatsTimeout.current) {
        clearTimeout(refetchStatsTimeout.current);
      }
      refetchStatsTimeout.current = setTimeout(() => {
        proceed();
      }, minTimeBetweenRequests);
    } else {
      proceed();
    }

    return () => {
      if (refetchStatsTimeout.current) {
        clearTimeout(refetchStatsTimeout.current);
      }
    };
  }, [refetchTradeConfigStats]);

  const onIncomingMessages = useCallback(
    (messages: MessageEnhanced[]) => {
      const positionCreatedOrUpdated = messages.filter(
        (m) =>
          m.status === 'success' &&
          m.steps.some((s) => {
            return s.action === 'updatePosition' || s.action === 'createPosition';
          })
      ).length;

      if (positionCreatedOrUpdated > 0) {
        scheduleRefetchStats();
      }
    },
    [scheduleRefetchStats]
  );

  const renderContent = () => {
    if (errorFetchTradeConfig) {
      if (errorFetchTradeConfig.statusCode === 404) {
        return <NotFoundTitle />;
      }

      return (
        <ApiErrorAlert title="Error loading bot" error={errorFetchTradeConfig}>
          We could not retrieve the bot details. Try refreshing the browser window. If the error
          persists contatct our support.
        </ApiErrorAlert>
      );
    }

    if (!tradeConfig) {
      return <div />;
    }

    let avgOpenPrice: string | undefined;
    if (tradeConfigStats) {
      const cost = BigNumber(tradeConfigStats.unrealized.cost);
      avgOpenPrice = cost.isZero()
        ? '0'
        : BigNumber(tradeConfigStats.unrealized.cost)
            .div(BigNumber(tradeConfigStats.unrealized.size))
            .toFixed(RESULT_DECIMALS);
    }

    return (
      <>
        <Flex align="center" justify="space-between">
          <Group align="center">
            <Title my="lg" order={1}>
              {tradeConfig.tradeName}
            </Title>
            <Badge color={isActive ? 'teal' : 'red'} mt="sm">
              {isActive ? 'Active' : 'Paused'}
            </Badge>

            {tradeConfigStats && (
              <>
                <Text fw={600} mt="sm">
                  <Roi
                    openPrice={avgOpenPrice}
                    assetPair={tradeConfig.ticker}
                    xchange={tradeConfig.outputs[0].xchange.id}
                    positivePrefix="+"
                    withColor
                  />
                </Text>
                {tradeConfigStats.realized && (
                  <Text fw={600} mt="sm">
                    <Roi roi={tradeConfigStats.realized.roi} positivePrefix="+" withColor />
                  </Text>
                )}
              </>
            )}
          </Group>

          <Link to={`../edit/${tradeConfig.tradeName}`}>
            <Button variant="outline">Edit</Button>
          </Link>
        </Flex>

        <MessagesInspector
          useMessages={useMessages}
          waitingIndicator={isActive}
          idx={idx}
          onIncomingMessages={onIncomingMessages}
        >
          <DetailsPanel
            tradeConfig={tradeConfig}
            balance={balance}
            stats={tradeConfigStats}
            refetchStats={scheduleRefetchStats}
          />
        </MessagesInspector>
      </>
    );
  };

  useEffect(() => {
    setIdx((prev) => prev + 1);
  }, [testModeOn]);

  return (
    <>
      <Page>
        {isLoading ? (
          <Center sx={(theme) => ({ marginTop: `calc(${theme.spacing.lg} * 2)` })}>
            <Loader variant="dots" />
          </Center>
        ) : (
          renderContent()
        )}
      </Page>
    </>
  );
};
