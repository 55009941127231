import { useInfiniteQuery } from '@tanstack/react-query';
import type { ListPositionsQueryParams } from '../shared_imports';

import type { API } from './api';
import type { CoreState } from '../../../core';

export const init = ({ api, coreState: { testModeOn } }: { api: API; coreState: CoreState }) => {
  function usePositions(
    params: ListPositionsQueryParams = { limit: '20' },
    { refetchInterval }: { refetchInterval?: number } = {}
  ) {
    return useInfiniteQuery({
      queryKey: ['positions', testModeOn, params],
      queryFn: ({ pageParam: pageCursor }) => {
        return api.getPositions({ ...params, pageCursor }, { testModeOn });
      },
      getNextPageParam: (lastPage) => lastPage.pageCursor,
      refetchInterval,
    });
  }

  return {
    usePositions,
  };
};

export type Queries = ReturnType<typeof init>;
