import { FC, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import { ActionIcon, Anchor, Divider, Space, Stack, Text, Tooltip } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import type { GetTradeConfigStatsResponse, TradeConfig } from '../../shared_imports';
import { KeyValue, Pl, Roi } from '../../../../components';
import { calculateUnrealizedRoiTradeConfig } from '../../utils';
import { renderDecimalValue } from '../../../../utils';
import { useUpdateTradeConfig } from '../../api';

interface Props {
  tradeConfig: TradeConfig;
  stats: GetTradeConfigStatsResponse;
  refetchStats: () => void;
}

const oneDay = 1000 * 60 * 60 * 24;

export const TradeConfigStats: FC<Props> = ({ tradeConfig, stats, refetchStats }) => {
  const checkStaleStatsTimeout = useRef<NodeJS.Timeout>();

  const { quote, tradeName, status } = tradeConfig;
  const { unrealized, realized, createdAt } = stats;
  const updateTradeConfigMutation = useUpdateTradeConfig();
  const roiData = calculateUnrealizedRoiTradeConfig({ tradeConfig, stats });
  const [lastOpen] = unrealized.positions.lastOpen;
  const lastClosed = realized?.positions.lastClosed[1];
  const totalPositionClosed = realized?.positions.total ?? 0;

  const { isLoading: isUpdatingTradeConfig } = updateTradeConfigMutation;

  const renderUnrealizedRoi = () => {
    if (!roiData) {
      return null;
    }
    const { avgOpenPrice, assetPair, xchange } = roiData;
    return <Roi openPrice={avgOpenPrice} assetPair={assetPair} xchange={xchange} withColor />;
  };

  const renderUnrealizedPl = () => {
    if (!roiData) {
      return null;
    }
    const { avgOpenPrice, assetPair, xchange } = roiData;
    return (
      <Pl
        openPrice={avgOpenPrice}
        assetPair={assetPair}
        xchange={xchange}
        quote={quote}
        size={unrealized.size}
      />
    );
  };

  const getTimeActiveToClosePositions = () => {
    const now = Date.now();
    let duration = now - stats.createdAt;

    const pauseDuration = now - (stats.inactivityTime.current.close ?? now);
    const inactivity = (stats.inactivityTime.total.close ?? 0) + pauseDuration;

    duration -= inactivity;
    return duration;
  };

  const getAvgClosePositionPerDay = () => {
    const durationActive = getTimeActiveToClosePositions();
    const daysActive = Math.max(1, durationActive / oneDay); // Take at least 1 day

    const closedPerDay = totalPositionClosed / daysActive;

    const float = closedPerDay.toFixed(2);
    if (parseFloat(float) === 0) {
      return '0.00';
    }
    return float;
  };

  /**
   * Make sure we don't have stale stats data the read is eventually consistent
   * We will wait 2 seconds between check as we might have inflight requests that
   * will update the stats.
   */
  useEffect(() => {
    if (checkStaleStatsTimeout.current) {
      clearTimeout(checkStaleStatsTimeout.current);
    }

    if (stats && stats.inactivityTime.current.open !== null && status === 'on') {
      checkStaleStatsTimeout.current = setTimeout(() => {
        checkStaleStatsTimeout.current = undefined;
        refetchStats();
      }, 2000);
    }

    return () => {
      if (checkStaleStatsTimeout.current) {
        clearTimeout(checkStaleStatsTimeout.current);
      }
    };
  }, [stats, refetchStats, status]);

  return (
    <Stack>
      <div>
        {unrealized.positions.total > 0 && (
          <>
            <Text size="sm" fw={700} mb="sm">
              Unrealized
            </Text>
            <KeyValue label="Avg return per trade" value={renderUnrealizedRoi()} />
            <KeyValue label="Profit & Loss" value={renderUnrealizedPl()} />
            <KeyValue label="Opened positions" value={unrealized.positions.total} />
            {lastOpen && (
              <KeyValue
                label="Last opened position"
                value={format(new Date(lastOpen), 'MMM do pp')}
              />
            )}
            <KeyValue label="Total size" value={renderDecimalValue(unrealized.size)} />
            <KeyValue label="Total cost" value={renderDecimalValue(unrealized.cost, { quote })} />
          </>
        )}

        {realized && totalPositionClosed > 0 && (
          <>
            <Space h="md" />
            <Divider variant="dotted" />
            <Space h="md" />

            <Text size="sm" fw={700} mb="sm">
              Realized
            </Text>
            <KeyValue label="Avg return per trade" value={<Roi roi={realized.roi} withColor />} />
            <KeyValue label="Profit & Loss" value={<Pl pl={realized.pl} quote={quote} />} />
            <KeyValue label="Closed positions" value={realized.positions.total} />
            <KeyValue
              label="Win ratio"
              value={`${((realized.positions.closedWithProfit / totalPositionClosed) * 100).toFixed(
                2
              )}%`}
            />
            {lastClosed && (
              <KeyValue
                label="Last closed position"
                value={format(new Date(lastClosed), 'MMM do pp')}
              />
            )}
            <KeyValue
              label="Avg closed per day"
              value={getAvgClosePositionPerDay()}
              valueSuffix={
                <ActionIcon sx={{ display: 'inline' }}>
                  <Tooltip label="Based on active time" position="right" openDelay={300}>
                    <IconInfoCircle size="0.8rem" />
                  </Tooltip>
                </ActionIcon>
              }
            />
            <KeyValue label="Total size" value={renderDecimalValue(realized.size)} />
            <KeyValue label="Total cost" value={renderDecimalValue(realized.cost, { quote })} />
          </>
        )}

        {unrealized.positions.total > 0 ||
          (realized && totalPositionClosed > 0 && (
            <>
              <Space h="md" />
              <Divider variant="dotted" />
            </>
          ))}
        <Space h="md" />

        <KeyValue label="Created on" value={format(new Date(createdAt), 'MMM do pp')} />
        {stats.inactivityTime.current.open && (
          <KeyValue
            label="Paused on"
            value={format(new Date(stats.inactivityTime.current.open), 'MMM do pp')}
            valueSuffix={
              <Anchor
                ml="sm"
                color={isUpdatingTradeConfig ? 'dimmed' : undefined}
                onClick={() => {
                  if (isUpdatingTradeConfig) {
                    return;
                  }

                  updateTradeConfigMutation.mutate({
                    tradeName,
                    tradeConfig: {
                      status: 'on',
                    },
                  });
                }}
              >
                (Resume)
              </Anchor>
            }
          />
        )}
        <KeyValue
          label="Max open positions at any given time"
          value={unrealized.positions.maxOpen}
        />
      </div>
    </Stack>
  );
};
