import type { FC } from 'react';
import { Space, Text, TextInput, Title } from '@mantine/core';

import type { ListConnectorResponse } from '../../../shared_imports';
import { BalanceController } from '../../balance-controller';
import { useFormContext } from '../create-edit-trade-config-form';
import { FormSection } from '../form-section';
import { FormDescriptionRow } from '../form-description-row';
import { AssetSelector } from '../asset-selector';
import { useTradeConfigFormContext } from '../create-edit-trade-config';

interface Props {
  isLoadingConnectors: boolean;
  userConnectors?: ListConnectorResponse | null;
  isFetchingAssetPair?: boolean;
  showFormErrors: boolean;
  testModeOn: boolean;
}

export const StepDetails: FC<Props> = ({
  isLoadingConnectors,
  isFetchingAssetPair,
  userConnectors,
  showFormErrors,
  testModeOn,
}) => {
  const {
    getInputProps,
    values: { xchange },
  } = useFormContext();
  const {
    tradeConfig: tradeConfigFromForm,
    balance,
    isLoadingBalances,
    isEdit,
  } = useTradeConfigFormContext();

  const showBalanceController = !!tradeConfigFromForm?.quote && !isLoadingBalances;
  const showAssetSelector =
    (testModeOn && xchange === 'paper') || (!testModeOn && xchange !== 'paper');

  return (
    <>
      <FormDescriptionRow>
        <FormDescriptionRow.Description>
          <Title order={2}>Signal details</Title>
          <Text>Configure the general settings of your signal.</Text>

          {showBalanceController && (
            <BalanceController
              balance={balance}
              quote={tradeConfigFromForm.quote}
              allowIncrease={false}
            />
          )}
        </FormDescriptionRow.Description>

        <FormDescriptionRow.Content>
          <TextInput
            label="Name"
            description="Give a unique name for this signal"
            placeholder="e.g MyBTCTrade"
            required
            {...getInputProps('tradeName')}
            error={showFormErrors && getInputProps('tradeName').error}
          />

          <Space h="md" />

          <FormSection
            title="Asset"
            description="Which asset do you want to trade?"
            titleOrder={3}
            isLast
          >
            {showAssetSelector && (
              <AssetSelector
                isLoadingConnectors={isLoadingConnectors}
                isFetchingAssetPair={isFetchingAssetPair}
                userConnectors={userConnectors}
                showFormErrors={showFormErrors}
                isDisabled={isEdit}
              />
            )}
          </FormSection>
        </FormDescriptionRow.Content>
      </FormDescriptionRow>
    </>
  );
};
