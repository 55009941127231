import deepEqual from 'react-fast-compare';
import { type FC, useCallback, useEffect, useState, useMemo } from 'react';
import { Button, Title, Stepper, Group, Text, Alert } from '@mantine/core';
import { isNotEmpty, matches, createFormContext } from '@mantine/form';
import { randomId } from '@mantine/hooks';
import type { UseFormInput } from '@mantine/form/lib/types';
import { useSearchParams } from 'react-router-dom';
import { IconAlertCircle } from '@tabler/icons-react';

import type { GsheetConnector, TradeConfigConditions } from '../../../../shared_imports';
import type { BaseError } from '../../../../core';
import type {
  AllocationConfig,
  ListConnectorResponse,
  OpenCloseConditions,
  TradeConfig,
  TradeExitType,
  XchangeConnectorId,
} from '../../shared_imports';
import { StepDetails } from './form-steps/step-details';
import { StepOpenPosition } from './form-steps/step-open-position';
import { StepClosePosition } from './form-steps/step-close-position';
import { StepOutputActions } from './form-steps/step-output-actions';
import { Summary } from './summary-column';
import { useTradeConfigFormContext, LOCALSTORAGE_KEY } from './create-edit-trade-config';
import { FormDescriptionRow } from './form-description-row';
import { xchangeSelectItems } from './asset-selector';
import { useSyncLocalStorage } from './use-sync-localstorage';

type OpenCloseConditionsForm = Omit<OpenCloseConditions, 'types'>;

export interface FormIndicator {
  name: string;
  key: string;
}

export type FormValues = {
  tradeName: string;
  ticker: string;
  xchange?: XchangeConnectorId | 'paper';
  allocation?: AllocationConfig;
  conditions?: {
    open?: OpenCloseConditionsForm;
    close?: OpenCloseConditionsForm;
  };
  exitType?: TradeExitType;
  __internal__: {
    multipleIndicators: {
      open: boolean;
      close: boolean;
    };
    closeSettings: {
      sameIndicatorAsOpen: boolean;
      trigger: 'webhook' | 'manual';
    };
  };
  pipesConfig: {
    gsheet: Required<Required<TradeConfig>['pipesConfig']>['gsheet'];
  };
  indicatorsOpen?: FormIndicator[];
  indicatorsOpenTimeSpan?: number;
  indicatorsClose?: FormIndicator[];
  indicatorsCloseTimeSpan?: number;
};

const TOTAL_STEPS_AVAILABLE = 4;

export const getDefaultFormValues = ({
  userConnectors,
}: {
  userConnectors?: ListConnectorResponse | null;
}): FormValues => {
  const gsheetConnector = userConnectors?.find(({ id }) => id === 'gsheet');
  const hasGsheetConnector = !!gsheetConnector;

  return {
    tradeName: '',
    ticker: '',
    exitType: 'PL',
    xchange: xchangeSelectItems[0]?.value ?? 'paper',
    indicatorsOpen: [
      { name: '', key: randomId() },
      { name: '', key: randomId() },
    ],
    conditions: {
      open: {},
      close: {},
    },
    pipesConfig: {
      gsheet: {
        enabled: hasGsheetConnector,
      },
    },
    __internal__: {
      multipleIndicators: {
        open: true,
        close: true,
      },
      closeSettings: {
        sameIndicatorAsOpen: true,
        trigger: 'webhook',
      },
    },
  };
};

const deserializeConditions = (
  configConditions?: TradeConfigConditions
): Pick<
  FormValues,
  | 'conditions'
  | 'indicatorsOpen'
  | 'indicatorsOpenTimeSpan'
  | 'indicatorsClose'
  | 'indicatorsCloseTimeSpan'
> => {
  if (!configConditions) {
    return {
      conditions: {
        open: {},
        close: {},
      },
    };
  }
  const { open, close } = configConditions;

  const deserialize = (
    conditions?: OpenCloseConditions
  ): { conditions?: OpenCloseConditionsForm; indicators?: FormIndicator[]; timeSpan?: number } => {
    if (!conditions) {
      return {
        conditions: {},
      };
    }

    const { types, ...rest } = conditions;

    if (!types) {
      return {
        conditions: rest,
      };
    }

    return {
      conditions: rest,
      indicators: types.required.map((type) => ({ name: type, key: randomId() })),
      timeSpan: types.maxDelayBetweenTypes,
    };
  };

  const {
    conditions: openConditions,
    indicators: indicatorsOpen,
    timeSpan: indicatorsOpenTimeSpan,
  } = deserialize(open);

  const {
    conditions: closeConditions,
    indicators: indicatorsClose,
    timeSpan: indicatorsCloseTimeSpan,
  } = deserialize(close);

  return {
    conditions: {
      open: openConditions,
      close: closeConditions,
    },
    indicatorsOpen,
    indicatorsOpenTimeSpan,
    indicatorsClose,
    indicatorsCloseTimeSpan,
  };
};

const deserializeTradeConfig = (tradeConfig: TradeConfig): FormValues => {
  return {
    tradeName: tradeConfig.tradeName,
    ticker: tradeConfig?.ticker,
    xchange: tradeConfig.outputs?.[0]?.xchange.id ?? 'paper',
    exitType: tradeConfig.outputs?.[0]?.exitType,
    ...deserializeConditions(tradeConfig.conditions),
    pipesConfig: {
      gsheet: {
        enabled: tradeConfig.pipesConfig?.gsheet?.enabled ?? true,
      },
    },
    __internal__: {
      multipleIndicators: {
        open: !!tradeConfig.conditions?.open?.types?.required?.length,
        close: !!tradeConfig.conditions?.close?.types?.required?.length,
      },
      closeSettings: {
        sameIndicatorAsOpen: deepEqual(
          tradeConfig.conditions?.open?.types,
          tradeConfig.conditions?.close?.types
        ),
        trigger: tradeConfig.outputs[0].exitType === 'manual' ? 'manual' : 'webhook',
      },
    },
  };
};

const indicatorNameValidator = (side: 'open' | 'close') => (value: string, values: FormValues) => {
  const indicators = side === 'open' ? values.indicatorsOpen : values.indicatorsClose;

  if (!indicators) {
    return null;
  }

  if (value.trim() === '') {
    return 'Indicator name is required';
  }

  if (indicators.length < 2) {
    return 'Two or more indicators are required';
  }

  const countWithSameName = indicators.reduce(
    (acc, item) => (item.name === value ? acc + 1 : acc),
    0
  );

  if (countWithSameName > 1) {
    return 'Indicator name must be unique';
  }

  return null;
};

const timeSpanValidator = (value?: number) => {
  return value === null ? 'Invalid time span' : null;
};

const getStep = (isEdit: boolean) => (idx: number) => isEdit ? Math.max(0, idx - 1) : idx;

const getFormConfig = ({
  activeStep,
  tradeConfig,
  userConnectors,
  assetPairError,
  gsheetConnector,
  isEdit,
}: {
  activeStep: number;
  userConnectors?: ListConnectorResponse | null;
  tradeConfig?: TradeConfig | null;
  assetPairError?: BaseError | null;
  gsheetConnector?: GsheetConnector;
  isEdit: boolean;
}): UseFormInput<FormValues> => {
  const initialValues: FormValues = tradeConfig
    ? deserializeTradeConfig(tradeConfig)
    : getDefaultFormValues({ userConnectors });

  if (tradeConfig) {
    const [{ allocation }] = tradeConfig.outputs;
    if (allocation) {
      initialValues.allocation = {
        ...allocation,
        value: allocation.type === 'percCurrentCapital' ? allocation.value * 100 : allocation.value,
      };
    }
  }

  let validate: UseFormInput<FormValues>['validate'] = {};
  const step = getStep(isEdit);

  if (activeStep === 0 && !isEdit) {
    validate = {
      tradeName: (value) => {
        if (value.trim() === '') {
          return 'Name is required.';
        }
        const err = matches(
          /^[a-zA-Z-_0-9~]+$/,
          'Invalid Id. Only alpha numeric characters + "-_" allowed.'
        )(value);
        if (err) {
          return err;
        }
        if (value.length > 20) {
          return 'Name too long. Max length is 20';
        }
        return null;
      },
      xchange: isNotEmpty('Exchange is required.'),
      ticker: (value) => {
        if (assetPairError) {
          return 'Asset pair not valid.';
        }
        return isNotEmpty('Asset pair is required.')(value);
      },
    };
  } else if (activeStep === step(1)) {
    // Step 2: open positions
    validate = {
      indicatorsOpen: {
        name: indicatorNameValidator('open'),
      },
      indicatorsOpenTimeSpan: (value, values) => {
        if (value === undefined && values.indicatorsOpen?.length) {
          return 'Time span is required';
        }
        return timeSpanValidator(value);
      },
      allocation: {
        value: (value, values) => {
          if (value <= 0) {
            return 'Value must be greater than 0';
          }
          if (values.allocation?.type === 'percCurrentCapital' && value > 100) {
            return 'Max value is 100';
          }
          return null;
        },
      },
      conditions: {
        open: {
          minTimeBetweenTrades: timeSpanValidator,
        },
      },
    };
  } else if (activeStep === step(2)) {
    // Step 3: close positions
    validate = {
      indicatorsClose: {
        name: indicatorNameValidator('close'),
      },
      indicatorsCloseTimeSpan: (value, values) => {
        if (value === undefined && values.indicatorsClose?.length) {
          return 'Time span is required';
        }
        return timeSpanValidator(value);
      },
      conditions: {
        close: {
          minTimeBetweenTrades: timeSpanValidator,
        },
      },
    };
  } else if (activeStep === step(3)) {
    validate = {
      pipesConfig: {
        gsheet: {
          enabled: (value) => {
            if (value) {
              if (!gsheetConnector) {
                return 'Gsheet connector is required';
              }

              if (!gsheetConnector.files || gsheetConnector.files.length === 0) {
                return 'You need to select one of your Google sheet files.';
              }
            }
            return null;
          },
        },
      },
    };
  }

  return {
    initialValues,
    validateInputOnBlur: true,
    validate,
  };
};

export const [FormProvider, useFormContext, useForm] = createFormContext<FormValues>();

interface Props {
  testModeOn: boolean;
  isBeingSubmitted: boolean;
  isFetchingAssetPair: boolean;
  isLoadingConnectors: boolean;
  onFormValuesChange: (values: FormValues) => void;
  onSubmitForm: (formValues: FormValues) => void;
  webhookId?: string;
  tradeConfig?: TradeConfig | null;
  assetPairMeta?: { quote: string; base: string; assetPair: string };
  assetPairError: BaseError | null;
}

export const CreateEditTradeConfigForm: FC<Props> = ({
  tradeConfig,
  webhookId,
  testModeOn,
  isBeingSubmitted,
  isFetchingAssetPair,
  isLoadingConnectors,
  onFormValuesChange,
  onSubmitForm,
  assetPairMeta,
  assetPairError,
}) => {
  const {
    tradeConfig: tradeConfigFromForm,
    balance,
    userConnectors,
    gsheetConnector,
    isEdit,
  } = useTradeConfigFormContext();
  const TOTAL_STEPS = isEdit ? TOTAL_STEPS_AVAILABLE - 1 : TOTAL_STEPS_AVAILABLE;
  const step = useMemo(() => getStep(isEdit), [isEdit]);

  const [searchParams] = useSearchParams();
  const [activeStep, setActiveStep] = useState(0);
  const [isStepSubmitted, setIsStepSubmitted] = useState(false);
  const [highestStepVisited, setHighestStepVisited] = useState(isEdit ? TOTAL_STEPS : activeStep);
  const formConfigStable = useMemo(
    () =>
      getFormConfig({
        tradeConfig,
        userConnectors,
        activeStep,
        assetPairError,
        gsheetConnector,
        isEdit,
      }),
    [tradeConfig, userConnectors, activeStep, assetPairError, gsheetConnector, isEdit]
  );
  const form = useForm(formConfigStable);

  const { values, setFieldValue, errors, validate, clearErrors, validateField } = form;
  const formHasErrors = Object.keys(errors).length > 0 || assetPairError !== null;
  const loadFormFromLocalStorage = searchParams.get('connectAccount') === 'gsheet';

  const localStorageState = useMemo(
    () => ({ activeStep, highestStepVisited }),
    [activeStep, highestStepVisited]
  );

  const onLoadFormFromLocalStorage = useCallback(
    ({ state }: { state: { activeStep: number; highestStepVisited: number } }) => {
      if (state.activeStep !== undefined) {
        setActiveStep(state.activeStep);
      }
      if (state.highestStepVisited !== undefined) {
        setHighestStepVisited(state.highestStepVisited);
      }
    },
    []
  );

  const getButtonLabel = () => {
    if (activeStep === TOTAL_STEPS - 1) {
      return 'View summary';
    }

    if (activeStep === TOTAL_STEPS) {
      return isEdit ? 'Update signal' : 'Create signal';
    }

    return 'Next';
  };

  const handleStepChange = useCallback(
    (nextStep: number) => {
      const isOutOfBounds = nextStep > TOTAL_STEPS + 1 || nextStep < 0;

      if (isOutOfBounds) {
        return;
      }

      if (validate().hasErrors) {
        return;
      }

      setActiveStep(nextStep);
    },
    [TOTAL_STEPS, validate]
  );

  const prevStep = () => {
    setIsStepSubmitted(true);
    handleStepChange(activeStep > 0 ? activeStep - 1 : activeStep);
  };

  // Allow the user to freely go back and forth between visited steps.
  const shouldAllowSelectStep = useCallback(
    (stepIdx: number) => {
      if (formHasErrors || validate().hasErrors) {
        return false;
      }

      return highestStepVisited >= stepIdx && activeStep !== stepIdx;
    },
    [formHasErrors, highestStepVisited, activeStep, validate]
  );

  const step0Allowed = useMemo(() => shouldAllowSelectStep(0), [shouldAllowSelectStep]);
  const step1Allowed = useMemo(() => shouldAllowSelectStep(step(1)), [shouldAllowSelectStep, step]);
  const step2Allowed = useMemo(() => shouldAllowSelectStep(step(2)), [shouldAllowSelectStep, step]);
  const step3Allowed = useMemo(() => shouldAllowSelectStep(step(3)), [shouldAllowSelectStep, step]);

  const submitForm = useCallback(
    (updatedValues: FormValues) => {
      onSubmitForm(updatedValues);
    },
    [onSubmitForm]
  );

  const onClickNext = useCallback(() => {
    setIsStepSubmitted(true);

    if (isFetchingAssetPair) {
      return;
    }

    if (activeStep === TOTAL_STEPS) {
      submitForm(values);
      return;
    }
    handleStepChange(activeStep + 1);
  }, [isFetchingAssetPair, handleStepChange, activeStep, submitForm, values, TOTAL_STEPS]);

  const areButtonsDisabled = isFetchingAssetPair || (formHasErrors && isStepSubmitted);

  useEffect(() => {
    if (testModeOn) {
      setFieldValue('xchange', 'paper');
    } else {
      const defaultXchangeValue = xchangeSelectItems[0]?.value;
      if (defaultXchangeValue) {
        setFieldValue('xchange', defaultXchangeValue);
      }
    }
  }, [setFieldValue, testModeOn, isEdit]);

  useEffect(() => {
    setHighestStepVisited((prev) => Math.max(prev, activeStep));
    setIsStepSubmitted(false);
  }, [activeStep]);

  useEffect(() => {
    onFormValuesChange(values);
  }, [values, onFormValuesChange]);

  useEffect(() => {
    clearErrors();
  }, [testModeOn, clearErrors]);

  useEffect(() => {
    validateField('ticker');
  }, [validateField, assetPairError]);

  useEffect(() => {
    setActiveStep(0);
  }, [testModeOn]);

  // Load the form values from local storage. This allows us to navigate to Google Sheets and authorize
  // the account and come back to the form with the values still there.
  // Important: needs to be after the `useEffect` that sets setActiveStep(0) when testModeOn changes.
  useSyncLocalStorage({
    key: LOCALSTORAGE_KEY,
    form,
    state: localStorageState,
    isActive: loadFormFromLocalStorage,
    onLoad: onLoadFormFromLocalStorage,
  });

  return (
    <FormProvider form={form}>
      <form onSubmit={form.onSubmit(onClickNext)}>
        <Title my="lg" order={1}>
          {isEdit ? 'Edit' : 'New'} signal
        </Title>

        <Stepper
          active={activeStep}
          breakpoint="sm"
          onStepClick={setActiveStep}
          styles={(theme) => ({
            content: {
              marginTop: '24px',
            },
            stepIcon: {
              '&[data-progress="true"]': {
                borderColor:
                  theme.colorScheme === 'dark' ? theme.colors.yellow[5] : theme.colors.teal[5],
              },
            },
            steps: {
              [theme.fn.smallerThan('sm')]: {
                display: 'none',
              },
            },
          })}
        >
          {!isEdit && (
            <Stepper.Step
              label="Details"
              description="General settings"
              allowStepSelect={step0Allowed}
            >
              <StepDetails
                isLoadingConnectors={isLoadingConnectors}
                isFetchingAssetPair={isFetchingAssetPair}
                userConnectors={userConnectors}
                showFormErrors={isStepSubmitted}
                testModeOn={testModeOn}
              />
            </Stepper.Step>
          )}

          <Stepper.Step
            label="Open"
            description="When to open a position"
            allowStepSelect={step1Allowed}
          >
            <StepOpenPosition
              assetPairMeta={assetPairMeta}
              webhookId={webhookId}
              testModeOn={testModeOn}
              showFormErrors={isStepSubmitted}
            />
          </Stepper.Step>

          <Stepper.Step
            label="Close"
            description="When to close a position"
            allowStepSelect={step2Allowed}
          >
            <StepClosePosition
              webhookId={webhookId}
              testModeOn={testModeOn}
              showFormErrors={isStepSubmitted}
            />
          </Stepper.Step>

          <Stepper.Step
            label="Output"
            description="Who should be notified"
            allowStepSelect={step3Allowed}
          >
            <StepOutputActions showFormErrors={isStepSubmitted} />
          </Stepper.Step>

          <Stepper.Completed>
            <FormDescriptionRow>
              <FormDescriptionRow.Description>
                <Title order={2}>Summary</Title>
                <Text>Does everything look good?</Text>
              </FormDescriptionRow.Description>

              <FormDescriptionRow.Content>
                {tradeConfigFromForm && (
                  <Summary
                    tradeConfig={tradeConfigFromForm}
                    balance={balance}
                    showStatus={isEdit}
                    sameCloseIndicatorAsOpen={values.__internal__.closeSettings.sameIndicatorAsOpen}
                    gsheetConnector={gsheetConnector}
                  />
                )}
              </FormDescriptionRow.Content>
            </FormDescriptionRow>
          </Stepper.Completed>
        </Stepper>

        {formHasErrors && isStepSubmitted && (
          <Alert icon={<IconAlertCircle size="1rem" />} title="Errors in form" color="red">
            Fix the errors in the form to continue.
          </Alert>
        )}
        <Group position="right" mt="xl">
          {activeStep < TOTAL_STEPS - 1 && highestStepVisited >= TOTAL_STEPS - 1 && (
            <Button
              variant="subtle"
              onClick={() => {
                handleStepChange(TOTAL_STEPS);
              }}
              disabled={areButtonsDisabled}
            >
              View summary
            </Button>
          )}
          {activeStep !== 0 && (
            <Button variant="default" onClick={prevStep} disabled={areButtonsDisabled}>
              Back
            </Button>
          )}
          <Button
            type="submit"
            loading={isBeingSubmitted || isFetchingAssetPair}
            disabled={formHasErrors && isStepSubmitted}
            onClick={(e) => {
              if (formHasErrors) {
                e.preventDefault();
              }
              setIsStepSubmitted(true);
            }}
          >
            {getButtonLabel()}
          </Button>
        </Group>
      </form>
    </FormProvider>
  );
};
