import { type FC, useCallback, useEffect } from 'react';
import { Grid, NumberInput, Text, type TitleOrder } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

import { TimespanInput } from '../../../../components';
import { useFormContext } from './create-edit-trade-config-form';
import { FormSection } from './form-section';

interface Props {
  titleOrder?: TitleOrder;
  withSeparator?: boolean;
  showFormErrors?: boolean;
}

export const TradeConfigConditionsOpen: FC<Props> = ({
  titleOrder = 3,
  withSeparator,
  showFormErrors = false,
}) => {
  const isSmall = useMediaQuery('(max-width: 48em)');

  const form = useFormContext();
  const {
    values: { conditions },
    setFieldValue,
    setValues,
    clearFieldError,
  } = form;

  const maxOpenPositions = conditions?.open?.maxOpenPositions;
  const defaultIsExpanded =
    conditions?.open?.maxOpenPositions !== undefined ||
    conditions?.open?.minTimeBetweenTrades !== undefined;

  const resetFields = useCallback(() => {
    setFieldValue('conditions.open.maxOpenPositions', undefined);
    clearFieldError('conditions.open.maxOpenPositions');

    setFieldValue('conditions.open.minTimeBetweenTrades', undefined);
    clearFieldError('conditions.open.minTimeBetweenTrades');
  }, [clearFieldError, setFieldValue]);

  useEffect(() => {
    if (maxOpenPositions === undefined) {
      setFieldValue('conditions.open.maxOpenPositions', '');
    }
  }, [maxOpenPositions, setFieldValue]);

  useEffect(() => {
    return () => {
      setValues((prev) => {
        if (
          typeof prev.conditions?.open?.maxOpenPositions === 'string' &&
          (prev.conditions.open.maxOpenPositions as string).trim() === ''
        ) {
          clearFieldError('conditions.open.maxOpenPositions');

          return {
            ...prev,
            conditions: {
              ...prev.conditions,
              open: {
                ...prev.conditions.open,
                maxOpenPositions: undefined,
              },
            },
          };
        }
        return prev;
      });
    };
  }, [clearFieldError, setValues]);

  return (
    <FormSection
      title="Conditions"
      description="Add conditions that must pass to open a position."
      isToggeable
      toggleDefaultValue={defaultIsExpanded}
      titleOrder={titleOrder}
      withSeparator={withSeparator}
      onToggle={(on) => {
        if (!on) {
          resetFields();
        }
      }}
      isLast
    >
      <Grid gutter={isSmall ? 0 : undefined}>
        {/* Max open positions */}
        <Grid.Col sm={5} pb={isSmall ? undefined : 'lg'}>
          <Text>Max open positions</Text>
          <Text size="xs" color="dimmed">
            The maximum open positions at any given time.
          </Text>
        </Grid.Col>
        <Grid.Col sm={7} pb="lg">
          <NumberInput
            label="Max open positions"
            placeholder="e.g 10"
            min={1}
            {...form.getInputProps('conditions.open.maxOpenPositions')}
          />
        </Grid.Col>

        {/* Time between orders */}
        <Grid.Col sm={5}>
          <Text>Time between orders</Text>
          <Text size="xs" color="dimmed">
            The minimum time that needs to pass since the previous buy order.
          </Text>
        </Grid.Col>
        <Grid.Col sm={7}>
          <TimespanInput
            label="Time between orders"
            fieldPath="conditions.open.minTimeBetweenTrades"
            form={form}
            defaultValue={conditions?.open?.minTimeBetweenTrades}
            showFormErrors={showFormErrors}
          />
        </Grid.Col>
      </Grid>
    </FormSection>
  );
};
