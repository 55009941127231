import { HttpError } from './httpError';
import type { HttpStatusCode } from './types';
import { addRequestInterceptor, addResponseInterceptor } from './fetch';
import { ENV } from '../../constants';

type UserAccount = 'root' | 'paper';

const UserAccountHeader = 'User-Account';

interface PostOptions<T = BodyInit> {
  body: T | null | undefined | string;
  queryParams?: Record<string, string>;
  headers?: Record<string, string>;
}

interface Params {
  apiEndpoint: string;
}

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json;charset=UTF-8',
};

const defaultCredentials = ENV === 'dev' ? ('include' as const) : ('same-origin' as const);
const defaultMode = ENV === 'dev' ? ('cors' as const) : undefined;

const getHeaders = (userAccount?: UserAccount) => {
  const headers: Record<string, string> = {
    ...defaultHeaders,
  };

  if (userAccount) {
    headers[UserAccountHeader] = userAccount;
  }

  return headers;
};

export const getHttpClient = ({ apiEndpoint }: Params) => {
  const addEndpoint = (url: string) => `${apiEndpoint}${url}`;

  const getDefaultInit = ({ userAccount }: { userAccount?: UserAccount } = {}): RequestInit => ({
    headers: getHeaders(userAccount),
    mode: defaultMode,
    credentials: defaultCredentials,
  });

  const handleError = async (response: Response) => {
    const body = await response.json();
    throw new HttpError(body.message, {
      statusCode: response.status as HttpStatusCode,
      name: body.name ?? 'Bad request',
      type: body.type,
      code: body.code,
      data: {
        url: response.url,
        headers: response.headers,
        ...body.data,
      },
    });
  };

  async function get<T = unknown, Q extends Record<string, string> = Record<string, string>>(
    uri: string,
    queryParams?: Q,
    options?: {
      returnRawResponse?: false | undefined;
      userAccount?: UserAccount;
      signal?: AbortSignal;
    }
  ): Promise<T>;
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  async function get<T = unknown, Q extends Record<string, string> = Record<string, string>>(
    uri: string,
    queryParams?: Q,
    options?: { returnRawResponse: true; userAccount?: UserAccount; signal?: AbortSignal }
  ): Promise<Response>;
  async function get<T = unknown, Q extends Record<string, string> = Record<string, string>>(
    uri: string,
    queryParams?: Q,
    {
      returnRawResponse,
      userAccount,
      signal,
    }: { returnRawResponse?: boolean; userAccount?: UserAccount; signal?: AbortSignal } = {}
  ): Promise<T | Response> {
    const options: RequestInit = {
      method: 'GET',
      signal,
      ...getDefaultInit({ userAccount }),
    };

    let url = addEndpoint(uri);
    if (queryParams && Object.keys(queryParams).length > 0) {
      url += `?${new URLSearchParams(queryParams).toString()}`;
    }

    return fetch(url, options).then((response) => {
      if (returnRawResponse) {
        return response;
      }
      if (!response.ok) {
        return handleError(response);
      }
      return response.json() as T;
    });
  }

  async function post<T = unknown, B = BodyInit>(
    uri: string,
    requestOptions?: PostOptions<B>,
    options?: {
      returnRawResponse?: false | undefined;
      sendCredentials?: boolean;
      userAccount?: UserAccount;
    }
  ): Promise<T>;
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  async function post<T = unknown, B = BodyInit>(
    uri: string,
    requestOptions?: PostOptions<B>,
    options?: { returnRawResponse: true; sendCredentials?: boolean; userAccount?: UserAccount }
  ): Promise<Response>;
  async function post<T = unknown, B = BodyInit>(
    uri: string,
    requestOptions?: PostOptions<B>,
    {
      returnRawResponse,
      sendCredentials = true,
      userAccount,
    }: { returnRawResponse?: boolean; sendCredentials?: boolean; userAccount?: UserAccount } = {}
  ): Promise<T | Response> {
    const { body: _body, queryParams, headers: _headers = {} } = requestOptions ?? {};
    let bodyToString = _body;

    if (_body && typeof _body !== 'string' && _body !== null) {
      bodyToString = JSON.stringify(_body);
    }

    const defaultOptions = getDefaultInit({ userAccount });
    const headers = { ..._headers, ...defaultOptions.headers };

    const options: RequestInit = {
      ...defaultOptions,
      headers,
      method: 'POST',
      body: bodyToString as string,
      credentials: sendCredentials ? defaultCredentials : 'omit',
    };

    let url = addEndpoint(uri);
    if (queryParams && Object.keys(queryParams).length > 0) {
      url += `?${new URLSearchParams(queryParams).toString()}`;
    }

    return fetch(url, options).then(async (response) => {
      if (!response.ok) {
        return handleError(response);
      }
      if (returnRawResponse) {
        return response;
      }
      return response.json() as T;
    });
  }

  async function put<T = unknown, B = BodyInit>(
    url: string,
    { body: _body }: PostOptions<B>,
    { userAccount }: { userAccount?: UserAccount } = {}
  ): Promise<T> {
    let bodyToString: unknown = _body;

    if (_body && typeof _body !== 'string' && _body !== null) {
      bodyToString = JSON.stringify(_body);
    }

    const options: RequestInit = {
      ...getDefaultInit({ userAccount }),
      method: 'PUT',
      body: bodyToString as string,
    };

    return fetch(addEndpoint(url), options).then(async (response) => {
      if (!response.ok) {
        return handleError(response);
      }
      return response.json() as T;
    });
  }

  async function deleteMethod<T = unknown>(
    url: string,
    { userAccount }: { userAccount?: UserAccount } = {}
  ): Promise<T> {
    const options: RequestInit = {
      ...getDefaultInit({ userAccount }),
      method: 'DELETE',
    };

    return fetch(addEndpoint(url), options).then(async (response) => {
      if (!response.ok) {
        return handleError(response);
      }
      return response.json() as T;
    });
  }

  return {
    addEndpoint,
    getDefaultInit,
    get,
    post,
    put,
    delete: deleteMethod,
    addRequestInterceptor,
    addResponseInterceptor,
  };
};

export type HttpClient = ReturnType<typeof getHttpClient>;
