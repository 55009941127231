import type { FC } from 'react';
import format from 'date-fns/format';
import intervalToDuration from 'date-fns/intervalToDuration';
import formatDuration from 'date-fns/formatDuration';
import { Accordion, Box, Code, Divider, Space, Stack, Text, createStyles } from '@mantine/core';

import { CurrentPrice, GoogleSheetLink, KeyValue, Pl, Roi } from '../../../components';
import { renderDecimalValue } from '../../../utils';
import type { PositionClosed, PositionOpen } from '../../../shared_imports';
import type { Position } from '../types';

const useStyles = createStyles((theme) => ({
  item: {
    borderBottom: 0,
  },
  control: {
    paddingTop: 0,
    paddingBottom: 0,
    fontSize: theme.fontSizes.sm,
  },
  content: {
    padding: 0,
  },
  label: {
    padding: `${theme.spacing.xs} 0`,
  },
}));

export const isOpenPosition = (position: PositionOpen | PositionClosed): position is PositionOpen =>
  position.status === 'open';

interface Props {
  position: Position;
}

export const PositionDetails: FC<Props> = ({ position }) => {
  const isOpen = isOpenPosition(position);
  const { classes } = useStyles();

  const { quote, settingsOpen } = position;
  const xChangeDisplay = position.exchange === 'paper' ? 'N/A (test mode)' : position.exchange;

  const renderDetails = () => {
    if (isOpen) {
      return (
        <div>
          <KeyValue label="Opened at" value={format(new Date(position.openedAt), 'MMM do pp')} />
          <Space h="md" />
          <KeyValue label="Open price" value={renderDecimalValue(position.openPrice, { quote })} />
          <KeyValue
            label="Current price"
            value={
              <CurrentPrice assetPair={position.ticker} xchange={position.exchange} quote={quote} />
            }
          />
          <KeyValue label="Order size" value={renderDecimalValue(position.size)} />
          <KeyValue
            label="Cost"
            value={renderDecimalValue(position.cost, { quote })}
            valueSuffix={quote !== 'USD' ? quote : ''}
          />
          <KeyValue
            label="Unrealized P/L"
            value={
              <Pl
                assetPair={position.ticker}
                openPrice={position.openPrice}
                xchange={position.exchange}
                size={position.size}
                quote={quote}
              />
            }
          />
          <KeyValue
            label="Unrealized ROI"
            value={
              <Roi
                assetPair={position.ticker}
                openPrice={position.openPrice}
                xchange={position.exchange}
              />
            }
          />
          <Space h="md" />
          <KeyValue label="Trigger" value={position.tradeName} />
          <KeyValue label="Exchange" value={xChangeDisplay} />
          {position.exchange !== 'paper' && (
            <KeyValue label="Order id" value={position.openOrderExchangeId} />
          )}
        </div>
      );
    }

    const duration = intervalToDuration({
      start: new Date(position.openedAt),
      end: new Date(position.closedAt),
    });

    return (
      <div>
        <KeyValue label="Opened at" value={format(new Date(position.openedAt), 'MMM do pp')} />
        <KeyValue label="Closed at" value={format(new Date(position.closedAt), 'MMM do pp')} />
        <KeyValue label="Duration" value={formatDuration(duration)} />
        <Space h="md" />
        <KeyValue label="Open price" value={renderDecimalValue(position.openPrice, { quote })} />
        <KeyValue label="Close price" value={renderDecimalValue(position.closePrice, { quote })} />
        <Space h="md" />
        <KeyValue label="Order size" value={renderDecimalValue(position.size)} />
        <KeyValue
          label="Cost"
          value={renderDecimalValue(position.cost, { quote })}
          valueSuffix={quote !== 'USD' ? quote : ''}
        />
        <KeyValue label="Proceeds" value={renderDecimalValue(position.proceeds, { quote })} />
        <KeyValue label="Realized P/L" value={renderDecimalValue(position.pl, { quote })} />
        <KeyValue label="ROI" value={`${renderDecimalValue(position.result, { decimals: 2 })}%`} />
        <Space h="md" />
        <KeyValue label="Trigger" value={position.tradeName} />
        <KeyValue label="Exchange" value={xChangeDisplay} />
        {position.exchange !== 'paper' && (
          <>
            <KeyValue label="Open order id" value={position.openOrderExchangeId} />
            <KeyValue label="Close order id" value={position.closeOrderExchangeId} />
          </>
        )}
      </div>
    );
  };

  const renderSettings = () => {
    if (!settingsOpen || (!isOpen && !position.settingsClose)) {
      return null;
    }

    return (
      <div>
        <Divider my="sm" />
        <Box
          sx={(theme) => ({
            marginTop: theme.spacing.xl,
            borderRadius: theme.radius.sm,
            border: `1px solid ${
              theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
            }`,
          })}
        >
          <Accordion classNames={classes}>
            {settingsOpen && (
              <Accordion.Item value="open">
                <Accordion.Control>Settings open</Accordion.Control>
                <Accordion.Panel>
                  <Code block>{JSON.stringify(settingsOpen, null, 2)}</Code>
                </Accordion.Panel>
              </Accordion.Item>
            )}

            {!isOpen && position.settingsClose && (
              <Accordion.Item value="close">
                <Accordion.Control>Settings close</Accordion.Control>
                <Accordion.Panel>
                  <Code block>{JSON.stringify(position.settingsClose, null, 2)}</Code>
                </Accordion.Panel>
              </Accordion.Item>
            )}
          </Accordion>
        </Box>
      </div>
    );
  };

  const renderOutputs = () => {
    const gsheetOutput = position.outputs?.googleSheet;

    if (!gsheetOutput) {
      return null;
    }

    return (
      <div>
        <Divider my="sm" />
        <Text size="sm" weight={600}>
          Output
        </Text>
        <KeyValue label="Google Sheet" value={<GoogleSheetLink meta={gsheetOutput} />} />
      </div>
    );
  };

  return (
    <Stack>
      {renderDetails()}
      {renderSettings()}
      {renderOutputs()}
    </Stack>
  );
};
