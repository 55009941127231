import { Text, Box, Button, Flex, Stack, Accordion, Title } from '@mantine/core';
import type { FC } from 'react';
import { IconPlus } from '@tabler/icons-react';
import type { MessageError, MessageSuccess } from '../../shared_imports';
import type { MessageEnhanced } from '../../types';
import {
  getMessageColor,
  getGsheetRowInserted,
  isMessageSuccessfullTrade,
  isOpenPosition,
} from './utils';
import { MessagePosition } from './message-position';
import { KeyValue } from '../key-value';
import { MessageSteps } from './message-steps';

const isSuccessMessage = (message: MessageSuccess | MessageError): message is MessageSuccess =>
  message.status === 'success';

const ContentWrapper: FC<{ children: React.ReactNode }> = ({ children }) => (
  <Box
    sx={(theme) => ({
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.white,
      padding: theme.spacing.sm,
      borderRadius: theme.radius.md,
      border: theme.colorScheme === 'dark' ? `1px solid ${theme.colors.dark[7]}` : undefined,
      position: 'relative',
      boxShadow: theme.colorScheme === 'dark' ? undefined : theme.shadows.xs,
    })}
  >
    {children}
  </Box>
);

interface Props {
  message: MessageEnhanced;
  onCloseMessage: () => void;
}

export const Message: FC<Props> = ({ message, onCloseMessage }) => {
  const messageHasError = message.status === 'error';
  const { status, position } = isMessageSuccessfullTrade(message);
  const gsheetRowInserted = getGsheetRowInserted(message);

  const getDescription = () => {
    if (position) {
      let description = isOpenPosition(position) ? 'Position opened' : 'Position closed';
      if (messageHasError) {
        description += ' with error';
      }
      return description;
    }

    if (!isSuccessMessage(message)) {
      return message.error.reason;
    }

    // To improve. Error message could be inside the step itself
    return '';
  };

  return (
    <>
      <Flex justify="flex-end" mt="-4px">
        <Button onClick={onCloseMessage} p={0} variant="subtle">
          close
        </Button>
      </Flex>

      <Stack>
        {/* Status */}
        <ContentWrapper>
          <Text fw={600} fz="lg" sx={(theme) => ({ color: getMessageColor(status, theme) })}>
            {status === 'success' ? 'Success' : 'Error'}
          </Text>
          <Text size="sm">{getDescription()}</Text>
        </ContentWrapper>

        {/* Position open or closed */}
        {position && (
          <ContentWrapper>
            <MessagePosition position={position} gsheetRowMeta={gsheetRowInserted} />
          </ContentWrapper>
        )}

        {/* Details error message */}
        {!isSuccessMessage(message) && (
          <ContentWrapper>
            <Title order={3} mb="sm" size="sm">
              Error
            </Title>
            <KeyValue label="Reason" value={message.error.reason ?? message.error.message} />
          </ContentWrapper>
        )}

        {/* Step details */}
        <ContentWrapper>
          <Accordion
            chevron={<IconPlus size="1rem" />}
            styles={(theme) => ({
              control: {
                borderTopLeftRadius: theme.radius.sm,
                borderTopRightRadius: theme.radius.sm,
                '&[data-active]': {
                  borderBottom: `1px solid ${
                    theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[3]
                  }`,
                },
              },
              item: {
                border: 0,
              },
              content: {
                padding: 0,
              },
              chevron: {
                '&[data-rotate]': {
                  transform: 'rotate(45deg)',
                },
              },
            })}
          >
            <Accordion.Item value="details">
              <Accordion.Control p="xs">Details</Accordion.Control>
              <Accordion.Panel p="xs" pt="md">
                <MessageSteps steps={message.steps} />
              </Accordion.Panel>
            </Accordion.Item>
          </Accordion>
        </ContentWrapper>
      </Stack>
    </>
  );
};
