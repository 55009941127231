import type { FC } from 'react';
import {
  Text,
  Popover,
  Group,
  ActionIcon,
  Box,
  Code,
  CopyButton,
  Tooltip,
  Grid,
} from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconCheck, IconCopy, IconInfoCircle } from '@tabler/icons-react';

import { WEBHOOK_BASE } from '../../../../constants';

interface Props {
  webhookId?: string;
  testModeOn: boolean;
}

export const WebhookUrlPreview: FC<Props> = ({ webhookId = '', testModeOn }) => {
  const [isWebhookPopoverVisible, { close: closePopover, open: openPopover }] =
    useDisclosure(false);
  const isTabletOrBigger = useMediaQuery('(min-width: 48em)');
  const isMobile = useMediaQuery('(max-width: 36em)');

  const webhookUrl = `${WEBHOOK_BASE}/${webhookId}`;
  let displayWebhookUrl = webhookUrl;

  if (isMobile) {
    // truncate the URL to fit the screen
    const res = /\/webhook\/.+$/.exec(webhookUrl);
    if (res) {
      displayWebhookUrl = `...${res[0]}`;
    }
  }

  return (
    <Grid gutter={0}>
      {/* Time between orders */}
      <Grid.Col>
        <Group spacing={0} sx={(theme) => ({ marginRight: theme.spacing.sm })}>
          <Text weight={700} size="sm">
            Webhook
          </Text>
          <Popover
            width={isTabletOrBigger ? 320 : 260}
            withArrow
            shadow="md"
            position="bottom-start"
            opened={isWebhookPopoverVisible}
          >
            <Popover.Target>
              <ActionIcon color="blue" onMouseEnter={openPopover} onMouseLeave={closePopover}>
                <IconInfoCircle size="1rem" />
              </ActionIcon>
            </Popover.Target>
            <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
              <Text size="sm">
                This is your {testModeOn ? 'test mode' : ''} Webhook URL to send{' '}
                <Text
                  span
                  sx={(theme) => ({
                    fontWeight: 700,
                    fontFamily: theme.fontFamilyMonospace,
                    fontSize: theme.fontSizes.xs,
                  })}
                >
                  POST
                </Text>{' '}
                requests to. See messages to send below.
              </Text>
            </Popover.Dropdown>
          </Popover>
        </Group>
      </Grid.Col>
      <Grid.Col>
        <Box
          sx={(theme) => ({
            borderRadius: theme.radius.sm,
            flex: 1,
          })}
        >
          <Group spacing="xs">
            <Text size="xs">
              <Code bg="none">{displayWebhookUrl}</Code>
            </Text>
            <CopyButton value={webhookUrl} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
        </Box>
      </Grid.Col>
    </Grid>
  );
};
