import { ReactNode, Children } from 'react';
import { Box, Grid } from '@mantine/core';

function FormSectionDescription({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

function FormSectionContent({ children }: { children: ReactNode }) {
  return <>{children}</>;
}

interface Props {
  children: ReactNode;
  isLast?: boolean;
}

export function FormDescriptionRow({ children, isLast = false }: Props) {
  const convertedChildren = Children.toArray(children) as React.ReactElement[];
  const description = convertedChildren.find((item) => item.type === FormSectionDescription);
  const content = convertedChildren.find((item) => item.type === FormSectionContent);

  const renderContent = (): JSX.Element => {
    if (description === null && content === null) {
      return <>{children}</>;
    }

    return <>{content}</>;
  };

  return (
    <Box
      component="section"
      sx={(theme) => ({
        marginBottom: isLast ? 0 : `calc(${theme.spacing.xl} * 2)`,
      })}
    >
      <Grid gutter="xl">
        {description && <Grid.Col sm={5}>{description}</Grid.Col>}
        <Grid.Col sm={description === null ? 12 : 7}>{renderContent()}</Grid.Col>
      </Grid>
    </Box>
  );
}

FormDescriptionRow.Description = FormSectionDescription;
FormDescriptionRow.Content = FormSectionContent;
