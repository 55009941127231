import { notifications } from '@mantine/notifications';
import { useEffect } from 'react';
import { BaseError } from '../core';

export const useHttpErrorNotification = <E = unknown>(error: E, title?: string) => {
  useEffect(() => {
    if (error instanceof BaseError) {
      const defaultTitle = error.name ?? 'Bummer!';
      notifications.show({
        title: title ?? defaultTitle,
        message: error.message,
        color: 'red',
      });
    }
  }, [error, title]);
};
