import type { Core } from '../../../core';
import type {
  ConnectorId,
  CreateConnectorPayload,
  CreateConnectorResponse,
  ListConnectorResponse,
  GetGsheetFileDetails,
  GetConnectorQueryParams,
  GetGsheetAccessToken,
  UpdateConnectorPayload,
  UpdateConnectorResponse,
  DeleteConnectorResponse,
} from '../shared_imports';

import { init as initQueries } from './queries';

const initApi = ({ httpClient }: { httpClient: Core['httpClient'] }) => {
  const getConnectors = () => {
    return httpClient.get<ListConnectorResponse>('/connectors');
  };

  const getGsheetAccessToken = () => {
    return httpClient.get<GetGsheetAccessToken, GetConnectorQueryParams>('/connectors/gsheet', {
      action: 'getToken',
    });
  };

  const getGsheetDetails = (fileId: string, accessToken: string) => {
    return httpClient.get<GetGsheetFileDetails, GetConnectorQueryParams>('/connectors/gsheet', {
      action: 'gsheetDetails',
      fileId,
      accessToken,
    });
  };

  const createConnector = ({
    id,
    payload,
  }: {
    id: ConnectorId;
    payload: CreateConnectorPayload;
  }) => {
    return httpClient.post<CreateConnectorResponse, CreateConnectorPayload>(`/connectors/${id}`, {
      body: payload,
    });
  };

  const updateConnector = ({
    id,
    payload,
  }: {
    id: ConnectorId;
    payload: UpdateConnectorPayload;
  }) => {
    return httpClient.put<UpdateConnectorResponse, UpdateConnectorPayload>(`/connectors/${id}`, {
      body: payload,
    });
  };

  const deleteConnector = ({ id }: { id: ConnectorId }) => {
    return httpClient.delete<DeleteConnectorResponse>(`/connectors/${id}`);
  };

  const api = {
    getConnectors,
    getGsheetAccessToken,
    getGsheetDetails,
    createConnector,
    updateConnector,
    deleteConnector,
  };

  return api;
};

export const init = ({ httpClient }: { httpClient: Core['httpClient'] }) => {
  const api = initApi({ httpClient });
  const queries = initQueries({ api });

  return {
    api,
    queries,
  };
};

export type API = ReturnType<typeof initApi>;
