import { Select, Stack, Slider, NumberInput, type TitleOrder } from '@mantine/core';
import { IconPercentage } from '@tabler/icons-react';
import type { FC } from 'react';
import type { AllocationConfig } from '../../shared_imports';
import { useFormContext } from './create-edit-trade-config-form';
import { FormSection } from './form-section';

export const defaultAllocationValue: AllocationConfig = {
  value: 10,
  type: 'percCurrentCapital',
};

interface Props {
  assetPairMeta?: { quote: string; base: string } | null;
  /** The title order (h1, h2, h3...) */
  titleOrder?: TitleOrder;
  withSeparator?: boolean;
}

export const TradeConfigOrderSize: FC<Props> = ({
  assetPairMeta,
  titleOrder = 2,
  withSeparator,
}) => {
  const form = useFormContext();
  const { setFieldValue, values, getInputProps } = form;

  const { quote, base } = assetPairMeta ?? {};

  const { allocation } = values;
  const isOn = allocation !== undefined;

  const getOrderSizeInputLabel = (): string => {
    switch (allocation?.type) {
      case 'percCurrentCapital':
        return 'Percentage';
      case 'fixAmount':
        return 'Amount';
      default:
        return 'Size';
    }
  };

  return (
    <FormSection
      title="Order size"
      titleOrder={titleOrder}
      isToggeable
      description="Customise the order size of the trade. If not set, the defaults is 10% of the available balance."
      isContentVisible={isOn}
      onToggle={(on: boolean) => {
        setFieldValue('allocation', on ? defaultAllocationValue : undefined);
      }}
      withSeparator={withSeparator}
    >
      {isOn && (
        <Stack>
          <Select
            label="Type"
            description="How the order size is calculated"
            placeholder="Select an order size type"
            {...getInputProps('allocation.type')}
            onChange={(value) => {
              const inputValue = value === 'percCurrentCapital' ? 10 : 1;
              setFieldValue('allocation.type', value);
              setFieldValue('allocation.value', inputValue);
            }}
            data={[
              {
                value: 'percCurrentCapital',
                label: `Percentage of balance${quote ? `(${quote})` : ''}`,
              },
              {
                value: 'fixAmount',
                label: `Fix amount${quote ? `(${quote})` : ''}`,
              },
              {
                value: 'fixSize',
                label: `Fix size${base ? `(${base})` : ''}`,
              },
            ]}
          />

          <NumberInput
            label={getOrderSizeInputLabel()}
            placeholder="e.g 20"
            min={allocation.type === 'percCurrentCapital' ? 1 : 0}
            decimalSeparator="."
            precision={allocation.type === 'percCurrentCapital' ? 0 : 4}
            required
            icon={allocation.type === 'percCurrentCapital' ? <IconPercentage size="1rem" /> : null}
            {...getInputProps('allocation.value')}
          />

          {allocation.type === 'percCurrentCapital' && (
            <Slider
              value={allocation.value}
              onChange={(newValue) => {
                setFieldValue('allocation.value', newValue);
              }}
              marks={[
                { value: 20, label: '20%' },
                { value: 50, label: '50%' },
                { value: 80, label: '80%' },
              ]}
            />
          )}
        </Stack>
      )}
    </FormSection>
  );
};
