import type { Core, CoreState, Plugin } from '../../core';
import { PLUGIN_ID } from './constants';
import type { PluginStart } from './types';
import { init as initApi } from './api';

export class WebhookPlugin implements Plugin {
  id = PLUGIN_ID;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setup() {}

  start(core: Core): PluginStart {
    return {
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
  }
}

export default WebhookPlugin;
