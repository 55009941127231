import { createStyles, Container, Group, Anchor, Flex, useMantineTheme } from '@mantine/core';
import { WeTradeTimeLogo } from '../components';

const useStyles = createStyles((theme) => ({
  footer: {
    marginTop: theme.spacing.xl,
    borderTop: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xl,
  },
}));

export function Footer() {
  const { classes } = useStyles();
  const { colorScheme } = useMantineTheme();

  return (
    <div className={classes.footer}>
      <Container size="lg" className={classes.inner}>
        <Flex justify="space-between" w="100%">
          <WeTradeTimeLogo width={140} colorScheme={colorScheme} withAppVersion />
          <Group sx={{ justifyContent: 'flex-end' }}>
            <Anchor href="/documentation.html" size="sm">
              Documentation
            </Anchor>
            <Anchor href="/privacy.html" size="sm">
              Privacy policy
            </Anchor>
            <Anchor href="/terms.html" size="sm">
              Terms of service
            </Anchor>
          </Group>
        </Flex>
      </Container>
    </div>
  );
}
