import { Button, Select, Space, Text, Flex, Anchor, Loader, type ButtonProps } from '@mantine/core';
import { FC, useEffect } from 'react';
import type { GetGsheetFileDetails } from '../../shared_imports';

export interface SelectedFile {
  id: string;
  title: string;
  isWeTradeTimeTemplate: boolean;
  sheets?: GetGsheetFileDetails['sheets'];
}

interface Props {
  selectedFile: SelectedFile;
  selectFile: () => Promise<void>;
  connectGoogleSheet: () => void;
  isLoading: boolean;
  isConnectingFile: boolean;
  sheetForPositions: string | null;
  onSheetForPositionsChange: (sheetTitle: string) => void;
  buttonVariant?: ButtonProps['variant'];
}

export const SelectedFile: FC<Props> = ({
  selectedFile,
  selectFile,
  connectGoogleSheet,
  isLoading,
  isConnectingFile,
  sheetForPositions,
  onSheetForPositionsChange,
  buttonVariant,
}) => {
  const { sheets } = selectedFile;

  const isSheetSelectVisible = !selectedFile.isWeTradeTimeTemplate && sheetForPositions !== null;
  const isSelectButtonVisible = sheetForPositions !== null || selectedFile.isWeTradeTimeTemplate;

  useEffect(() => {
    if (Array.isArray(sheets) && sheets.length > 0) {
      onSheetForPositionsChange(sheets[0]!.title);
    }
  }, [sheets, onSheetForPositionsChange]);

  return (
    <>
      <Flex justify="space-between">
        <Text fw={700}>Selected file:</Text>
        <Anchor
          size="xs"
          ml="sm"
          onClick={() => {
            selectFile();
          }}
        >
          Select other
        </Anchor>
      </Flex>
      <Text ff="monospace">{selectedFile.title}</Text>

      {isSheetSelectVisible && (
        <>
          <Space h="sm" />
          <Select
            label="Select sheet for positions"
            placeholder="Pick one"
            withAsterisk
            onChange={onSheetForPositionsChange}
            value={sheetForPositions}
            data={selectedFile.sheets!.map(({ title }) => ({
              value: title,
              label: title,
            }))}
          />
        </>
      )}

      {isSelectButtonVisible ? (
        <>
          <Button
            loading={isConnectingFile}
            onClick={connectGoogleSheet}
            variant={buttonVariant}
            fullWidth={false}
            mt="md"
          >
            Connect Google Sheet file
          </Button>
        </>
      ) : (
        isLoading && (
          <>
            <Space h="sm" />
            <Loader variant="dots" />
          </>
        )
      )}
    </>
  );
};
