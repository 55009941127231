import { createBrowserRouter, Navigate, Outlet } from 'react-router-dom';
import type { RouteObject } from 'react-router-dom';

import { Layout, NotFoundPage, ErrorBoundary } from '../layout';
import { ProtectedRoute } from './auth';
import type { Router } from './types';
import type { PluginRouterDefinition } from './plugins';

const baseRoutes: RouteObject[] = [
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

export const init = ({ coreRoutes }: { coreRoutes: RouteObject[] }) => {
  const publicRoutes: RouteObject[] = [];
  const protectedRoutes: RouteObject[] = [];
  const mainNavLinks: Array<{ label: string; link: string; order: number }> = [];
  let router: Router | null = null;

  const registerRoutes = (routes: PluginRouterDefinition) => {
    const { isProtected, route, mainNavLabel, mainNavOrder = 0 } = routes;
    if (isProtected === false) {
      publicRoutes.push(route);
    } else {
      protectedRoutes.push(route);
    }

    if (mainNavLabel && route.path) {
      mainNavLinks.push({ label: mainNavLabel, link: route.path, order: mainNavOrder });
    }
  };

  const getRouter = (defaultRoute = '/home') => {
    if (router !== null) {
      return router;
    }

    // Sort the main nav links
    mainNavLinks.sort(({ order: a }, { order: b }) => a - b);

    router =
      protectedRoutes.length === 0
        ? createBrowserRouter(baseRoutes)
        : createBrowserRouter([
            ...baseRoutes,
            ...coreRoutes,
            // Protected routes
            {
              element: <ProtectedRoute />,
              children: [
                {
                  path: '/',
                  element: <Layout mainNavLinks={mainNavLinks} />,
                  errorElement: <ErrorBoundary />,
                  children: [
                    {
                      path: '',
                      index: true,
                      element: <Navigate to={defaultRoute} replace />,
                    },
                    ...protectedRoutes,
                  ],
                },
              ],
            },
            // Public routes
            {
              element: <Outlet />,
              children: [
                {
                  path: '/',
                  element: <Layout mainNavLinks={mainNavLinks} />,
                  errorElement: <ErrorBoundary />,
                  children: publicRoutes,
                },
              ],
            },
          ]);

    // @ts-expect-error "hot" does not seem to be in the types
    if (import.meta.hot) {
      // @ts-expect-error "hot" does not seem to be in the types
      import.meta.hot.dispose(() => router.dispose());
    }
    return router;
  };

  return {
    registerRoutes,
    getRouter,
  };
};

export default init;
