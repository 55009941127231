import { useEffect, type FC, useRef } from 'react';
import { Text, Title } from '@mantine/core';

import { useFormContext } from '../create-edit-trade-config-form';
import { FormSection } from '../form-section';
import { FormDescriptionRow } from '../form-description-row';
import { TradeConfigOrderSize } from '../trade-config-order-size';
import { TradeConfigIndicators } from '../trade-config-indicators';
import { WebhookUrlPreview } from '../webhook-url-preview';
import { WebhookMessageExamples } from '../webhook-message-examples';
import { TradeConfigConditionsOpen } from '../trade-config-conditions-open';

interface Props {
  testModeOn: boolean;
  showFormErrors: boolean;
  assetPairMeta?: { quote: string; base: string } | null;
  webhookId?: string;
}

export const StepOpenPosition: FC<Props> = ({
  assetPairMeta,
  testModeOn,
  webhookId,
  showFormErrors,
}) => {
  const isMounted = useRef<boolean>(false);
  const { values } = useFormContext();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <FormDescriptionRow>
        <FormDescriptionRow.Description>
          <Title order={2}>Open position</Title>
          <Text>Configure how and when to open a position.</Text>
        </FormDescriptionRow.Description>

        <FormDescriptionRow.Content>
          <TradeConfigIndicators side="open" showFormErrors={isMounted.current && showFormErrors} />

          <FormSection title="Trigger" titleOrder={3} withSeparator>
            <WebhookUrlPreview webhookId={webhookId} testModeOn={testModeOn} />
            <WebhookMessageExamples
              formValues={values}
              side="open"
              timeSpan={values.indicatorsOpenTimeSpan}
            />
          </FormSection>

          <TradeConfigOrderSize assetPairMeta={assetPairMeta} titleOrder={3} withSeparator />

          <TradeConfigConditionsOpen showFormErrors={showFormErrors} />
        </FormDescriptionRow.Content>
      </FormDescriptionRow>
    </>
  );
};
