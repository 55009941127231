import type { Core, CoreState, Plugin } from '../../core';
import { PLUGIN_ID } from './constants';
import { init as initApp } from './app';
import { init as initApi } from './api';
import type { AppContext } from './context';

import type { PluginStart } from './types';
import type { PortfolioPluginStart } from '../portfolio';
import type { ConnectorsPluginStart } from '../connectors';
import type { WebhookPluginStart } from '../webhook';
import type { StatsPluginStart } from '../stats';
import type { PositionsPluginStart } from '../positions';

export interface PluginDependencies {
  portfolio: PortfolioPluginStart;
  connectors: ConnectorsPluginStart;
  webhook: WebhookPluginStart;
  stats: StatsPluginStart;
  positions: PositionsPluginStart;
}

export class TradesPlugin implements Plugin<void, PluginDependencies> {
  id = PLUGIN_ID;

  private apiStart: PluginStart = {};

  private pluginDeps: PluginDependencies | undefined;

  setup(core: Core) {
    const context: AppContext = {
      i18n: core.i18n,
      httpClient: core.httpClient,
      getPlugins: () => {
        if (!this.pluginDeps) {
          throw new Error(`Plugins dependencies missing`);
        }
        return this.pluginDeps;
      },
      getApi: ({ coreState }: { coreState: CoreState }) => {
        return initApi({ httpClient: core.httpClient, coreState });
      },
    };
    const { routes } = initApp(context);
    core.router.registerRoutes(routes);
  }

  start(_: Core, plugins: PluginDependencies) {
    this.pluginDeps = plugins;
    return this.apiStart;
  }
}

export default TradesPlugin;
