import type { Core, CoreState } from '../../../core';
import type {
  CreateCapitalPayload,
  CreateCapitalResponse,
  IncreaseDecreaseCapitalResponse,
  ListBalancesResponse,
} from '../shared_imports';

import { init as initQueries } from './queries';

const initApi = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const getBalances = (
    accounts?: string,
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    return httpClient.get<ListBalancesResponse>(
      `/balances${accounts !== undefined ? `/${accounts}` : ''}`,
      undefined,
      {
        userAccount: testModeOn ? 'paper' : 'root',
      }
    );
  };

  const addBalance = (
    config:
      | {
          action: 'increase';
          amount: number;
          capitalId: string;
        }
      | {
          action: 'create';
          amount: number;
          quote: string;
        },
    { testModeOn = coreState.testModeOn }: { testModeOn?: boolean } = {}
  ) => {
    if (config.action === 'increase') {
      return httpClient.post<IncreaseDecreaseCapitalResponse>(
        `/capitals/${config.capitalId}/increase/${config.amount}`,
        undefined,
        { userAccount: testModeOn ? 'paper' : 'root' }
      );
    }

    return httpClient.post<CreateCapitalResponse, CreateCapitalPayload>(
      `/capitals`,
      {
        body: {
          amount: config.amount,
          quote: config.quote,
          isDefault: true, // For now we can only create default capitals
        },
      },
      { userAccount: testModeOn ? 'paper' : 'root' }
    );
  };

  const api = {
    getBalances,
    addBalance,
  };

  return api;
};

export const init = ({
  httpClient,
  coreState,
}: {
  httpClient: Core['httpClient'];
  coreState: CoreState;
}) => {
  const api = initApi({ httpClient, coreState });
  const queries = initQueries({ api, coreState });

  return {
    api,
    queries,
  };
};

export type ApiWithQueries = ReturnType<typeof init>;
export type API = ReturnType<typeof initApi>;
