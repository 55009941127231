import { useMemo } from 'react';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

import type { ListConnectorResponse } from '../shared_imports';
import type { API } from './api';

export const init = ({ api }: { api: API }) => {
  function useConnectors({ isActive = true }: { isActive?: boolean } = {}) {
    const options = useMemo<UseQueryOptions<ListConnectorResponse | null>>(() => {
      return {
        queryKey: ['connectors', isActive],
        queryFn: () => {
          if (!isActive) {
            return null;
          }
          return api.getConnectors();
        },
      };
    }, [isActive]);

    return useQuery(options);
  }

  function useGoogleSheetAccessToken(hasConnector: boolean, accessToken?: string) {
    return useQuery({
      queryKey: ['gsheet', 'accessToken', hasConnector, accessToken],
      queryFn: () => {
        if (hasConnector || accessToken) {
          return api.getGsheetAccessToken();
        }
        return null;
      },
    });
  }

  function useGoogleSheetDetails(fileId?: string, accessToken?: string) {
    return useQuery({
      queryKey: ['gsheetDetails', fileId, accessToken],
      queryFn: () => {
        if (!fileId || !accessToken) {
          return null;
        }
        return api.getGsheetDetails(fileId, accessToken);
      },
    });
  }

  return {
    useConnectors,
    useGoogleSheetAccessToken,
    useGoogleSheetDetails,
  };
};

export type Queries = ReturnType<typeof init>;
