import type { FC, PropsWithChildren } from 'react';
import { Outlet, Navigate } from 'react-router-dom';

import { useCoreState } from '../context';

interface Props extends PropsWithChildren {
  redirectPath?: string;
}

export const ProtectedRoute: FC<Props> = ({ redirectPath = '/auth/login', children }) => {
  const [
    {
      auth: { isLoggedIn },
    },
  ] = useCoreState();

  if (isLoggedIn === null) {
    return null;
  }

  if (!isLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? <>{children}</> : <Outlet />;
};

export default ProtectedRoute;
