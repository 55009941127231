import { type FC, useEffect, useRef, useState, useCallback } from 'react';
import { useMouse } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';
import { useCore, useCoreState } from '../core';
import { useInterval } from '../utils';

const MAX_NON_ACTIVITY_TIME = 7 * 60; // 7 minutes
const MODAL_COUNTDOWN = 15; // 15 seconds

export const NonActivityLogout: FC = () => {
  const lastPos = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const mousePos = useMouse();
  const { auth } = useCore();
  const [
    {
      auth: { isLoggedIn },
    },
  ] = useCoreState();
  const [seconds, setSeconds] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { start, stop } = useInterval(() => setSeconds((s) => s + 1), 1000);

  const doLogout = useCallback(() => {
    auth.logout();
    modals.closeAll();
  }, [auth]);

  useEffect(() => {
    if (isLoggedIn === null) {
      return undefined;
    }

    if (isLoggedIn) {
      start();
      return stop;
    }
    stop();
    setSeconds(0);
    return undefined;
  }, [start, stop, isLoggedIn]);

  useEffect(() => {
    if (mousePos.x !== lastPos.current.x || mousePos.y !== lastPos.current.y) {
      // Activity....
      if (!isModalOpen) {
        setSeconds(0);
      }
      lastPos.current.x = mousePos.x;
      lastPos.current.y = mousePos.y;
    }
  }, [mousePos, isModalOpen]);

  useEffect(() => {
    if (seconds >= MAX_NON_ACTIVITY_TIME - MODAL_COUNTDOWN && isLoggedIn) {
      setIsModalOpen(true);

      if (seconds >= MAX_NON_ACTIVITY_TIME) {
        doLogout();
        return;
      }

      modals.openConfirmModal({
        title: `Session timeout`,
        modalId: 'sessionTimeout',
        centered: true,
        children: (
          <Text size="sm">
            You're being timed out due to inactivity. Chose to stay signed in or to logoff.
            Otherwise, you will be logged off automatically.
          </Text>
        ),
        labels: {
          confirm: `Stay logged in (${Math.max(MAX_NON_ACTIVITY_TIME - seconds, 0)})`,
          cancel: 'Log off',
        },
        closeOnEscape: false,
        closeOnClickOutside: false,
        closeButtonProps: {
          style: {
            display: 'none',
          },
        },
        confirmProps: {},
        onCancel: () => {
          doLogout();
        },
        onConfirm: () => {
          // Continue logged in
          setSeconds(0);
          setIsModalOpen(false);
        },
      });
    }
  }, [seconds, isLoggedIn, auth, doLogout]);

  return null;
};
