import type { FC } from 'react';
import { Button, type ButtonProps, Loader, Space, Text } from '@mantine/core';

interface Props {
  isLoading: boolean;
  isAuthenticated: boolean;
  selectFile: () => Promise<void>;
  withHelpText?: boolean;
  buttonVariant?: ButtonProps['variant'];
}

export const GdriveFilepicker: FC<Props> = ({
  isLoading,
  selectFile,
  isAuthenticated,
  withHelpText,
  buttonVariant,
}) => {
  const buttonLabel = isAuthenticated ? 'Select file' : 'Connect and select file';

  const renderHelpText = () => {
    if (!withHelpText) {
      return null;
    }
    if (isAuthenticated || isLoading) {
      return null;
    }
    return (
      <>
        <Space h="xs" />
        <Text color="dimmed" size="sm">
          You will have to authorize WeTradeTime to access{' '}
          <Text span fw={700} inherit>
            one
          </Text>{' '}
          of your Google sheet file.
        </Text>
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <>
          <Space h="sm" />
          <Loader variant="dots" />
        </>
      ) : (
        <Button onClick={selectFile} variant={buttonVariant} fullWidth={false}>
          {buttonLabel}
        </Button>
      )}

      {renderHelpText()}
    </>
  );
};
