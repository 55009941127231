import { useQuery } from '@tanstack/react-query';
import type {
  GetGlobalTradesStatsQueryParams,
  GetGlobalTradesStatsResponse,
  GetPositionsStatsQueryParams,
  GetPositionsStatsResponse,
  GetTradeConfigStatsResponse,
} from '../shared_imports';

import type { API } from './api';
import type { BaseError, CoreState } from '../../../core';

export const init = ({ api, coreState: { testModeOn } }: { api: API; coreState: CoreState }) => {
  function usePositionsStats(params: GetPositionsStatsQueryParams) {
    return useQuery<GetPositionsStatsResponse | null, BaseError>({
      queryKey: ['stats', 'positions', params, testModeOn],
      queryFn: () => {
        return api.getPositionsStats(params, { testModeOn });
      },
    });
  }

  function useTradeConfigStats(tradeId?: string) {
    return useQuery<GetTradeConfigStatsResponse | null, BaseError>({
      queryKey: ['stats', 'trades', tradeId, testModeOn],
      queryFn: () => {
        if (!tradeId) {
          return null;
        }
        return api.getTradeConfigStats(tradeId, { testModeOn });
      },
    });
  }

  function useGlobalTradesStats(params: GetGlobalTradesStatsQueryParams) {
    return useQuery<GetGlobalTradesStatsResponse | null, BaseError>({
      queryKey: ['stats', 'trades', params, testModeOn],
      queryFn: () => {
        return api.getGlobalTradesStats(params, { testModeOn });
      },
      refetchInterval: 7000, // 7 seconds (we like the number 7 :))
    });
  }

  return {
    usePositionsStats,
    useTradeConfigStats,
    useGlobalTradesStats,
  };
};

export type Queries = ReturnType<typeof init>;
