import { useRouteError } from 'react-router-dom';

export const ErrorBoundary = () => {
  const error = useRouteError() as Error;

  return (
    <>
      <h2>Error 💥</h2>
      <p>{error.message ?? (error as unknown as { statusText: string }).statusText}</p>
    </>
  );
};

export default ErrorBoundary;
