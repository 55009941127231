import type { ConnectorId, ConnectorType } from './shared_imports';

export const PLUGIN_ID = 'connectors';

export const CONNECTORS_META: {
  [id in ConnectorId]: { id: ConnectorId; type: ConnectorType; label: string };
} = {
  gsheet: {
    id: 'gsheet',
    type: 'db',
    label: 'Google Sheet',
  },
  kraken: {
    id: 'kraken',
    type: 'xchange',
    label: 'Kraken',
  },
};

export const CONNECTORS = Object.keys(CONNECTORS_META) as ConnectorId[];
