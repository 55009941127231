import type { FC } from 'react';
import format from 'date-fns/format';
import { Divider, Stack } from '@mantine/core';
import type { GsheetRowMeta, PositionClosed, PositionOpen } from '../../shared_imports';
import { isOpenPosition } from './utils';
import { KeyValue } from '../key-value';
import { renderDecimalValue } from '../../utils';
import { GoogleSheetLink } from '../google-sheet-link';

interface Props {
  position: PositionOpen | PositionClosed;
  gsheetRowMeta?: GsheetRowMeta | null;
}

export const MessagePosition: FC<Props> = ({ position, gsheetRowMeta }) => {
  const { quote } = position;

  const renderDetails = () => {
    if (isOpenPosition(position)) {
      return (
        <div>
          <KeyValue label="Opened at" value={format(new Date(position.openedAt), 'MMM do pp')} />
          <KeyValue label="Open price" value={renderDecimalValue(position.openPrice, { quote })} />
          <KeyValue label="Order size" value={renderDecimalValue(position.size)} />
          <KeyValue label="Cost" value={renderDecimalValue(position.cost, { quote })} />
          <KeyValue
            label="Exchange"
            value={position.exchange === 'paper' ? 'N/A (test mode)' : position.exchange}
          />
          {position.exchange !== 'paper' && (
            <KeyValue label="Order id" value={position.openOrderExchangeId} />
          )}
        </div>
      );
    }
    return (
      <div>
        <KeyValue label="Closed at" value={format(new Date(position.closedAt), 'MMM do pp')} />
        <KeyValue label="Close price" value={renderDecimalValue(position.closePrice, { quote })} />
        <KeyValue label="Order size" value={renderDecimalValue(position.size)} />
        <KeyValue label="PL" value={renderDecimalValue(position.pl, { quote })} />
        <KeyValue label="ROI" value={`${renderDecimalValue(position.result, { decimals: 2 })}%`} />
        <KeyValue label="Exchange" value={position.exchange} />
        {position.exchange !== 'paper' && (
          <KeyValue label="Order id" value={position.closeOrderExchangeId} />
        )}
      </div>
    );
  };

  const gsheetMeta = gsheetRowMeta ?? position.outputs?.googleSheet;

  return (
    <Stack>
      {renderDetails()}
      {gsheetMeta && (
        <>
          <Divider my="xs" />
          <KeyValue
            label="Google Sheet"
            sx={(theme) => ({ marginBottom: theme.spacing.md })}
            value={<GoogleSheetLink meta={gsheetMeta} />}
          />
        </>
      )}
    </Stack>
  );
};
