import { RouterProvider } from 'react-router-dom';
import { ColorSchemeProvider, MantineProvider } from '@mantine/core';
import type { ColorScheme } from '@mantine/core';
import { useHotkeys, useLocalStorage } from '@mantine/hooks';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { RawIntlProvider } from 'react-intl';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useCallback, useEffect } from 'react';
import type { Observable } from 'rxjs';
import { theme } from './theme';
import { Fallback } from './layout';
import { CoreProvider, CoreState } from './core';
import type { Core } from './core';
import { NonActivityLogout } from './components';

interface Props {
  core: Core;
}

const useLocalStorageStateSync = ({ state$ }: { state$: Observable<CoreState> }) => {
  // colorScheme
  const [colorScheme, setColorScheme] = useLocalStorage<ColorScheme>({
    key: 'color-scheme',
    defaultValue: 'light',
  });

  const toggleColorScheme = useCallback(
    (value?: ColorScheme) =>
      setColorScheme((prev) => {
        return value || (prev === 'dark' ? 'light' : 'dark');
      }),
    [setColorScheme]
  );

  useHotkeys([['mod+J', () => toggleColorScheme()]]);

  // testModeOn
  const setTestModeOnLocalStorage = useLocalStorage<boolean>({ key: 'testModeOn' })[1];

  useEffect(() => {
    if (state$) {
      const sub = state$.subscribe((newState) => {
        if (newState.auth.isLoggedIn === false) {
          setTestModeOnLocalStorage(false);
        } else if (newState.testModeOn !== null) {
          setTestModeOnLocalStorage(newState.testModeOn);
        }
      });
      return () => {
        sub.unsubscribe();
      };
    }
    return undefined;
  }, [state$, setTestModeOnLocalStorage]);

  return {
    colorScheme,
    toggleColorScheme,
  };
};

function App({ core }: Props) {
  const { plugins, router: _router, ...appCore } = core;
  const {
    state: { set: setCoreState },
  } = appCore;

  const { toggleColorScheme, colorScheme } = useLocalStorageStateSync({
    state$: core.state.state$,
  });

  const defaultRoute = '/signals';
  const router = _router.get(defaultRoute);

  useEffect(() => {
    const handleFocus = () => {
      setCoreState.isWindowFocused(document.visibilityState === 'visible');
    };
    window.addEventListener('visibilitychange', handleFocus, false);
    window.addEventListener('focus', handleFocus, false);
    return () => {
      window.removeEventListener('visibilitychange', handleFocus);
      window.removeEventListener('focus', handleFocus);
    };
  }, [setCoreState]);

  return (
    <CoreProvider {...appCore}>
      <RawIntlProvider value={core.i18n}>
        <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
          <MantineProvider withGlobalStyles withNormalizeCSS theme={{ ...theme, colorScheme }}>
            <ModalsProvider>
              <QueryClientProvider client={core.queryClient}>
                <Notifications />
                <NonActivityLogout />
                <RouterProvider router={router} fallbackElement={<Fallback />} />
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
      </RawIntlProvider>
    </CoreProvider>
  );
}

export default App;
