export const ENV = process.env.NODE_ENV === 'development' ? 'dev' : 'prod';

// Number of decimals that we keep in the system
export const SIZE_DECIMALS = 8; // Position size
export const RESULT_DECIMALS = 10; // Position price, cost, proceeds
export const PERC_DECIMALS = 4; // ROI (any percentage. e.g. 12.3534%)

const host =
  window.location.host === 'dashboard.wetradetime.com' ? 'wetradetime.com' : window.location.host;

export const WEBHOOK_BASE = `${window.location.protocol}//${host}/webhook`;
