import type { PluginInitializer } from '../../core';
import { PLUGIN_ID } from './constants';
import { ConnectorsPlugin } from './plugin';

export { AddGsheetConnector, AddKrakenConnector } from './components';
export { ConnectorsProvider } from './context';

export { CONNECTORS, CONNECTORS_META } from './constants';

export type { PluginStart as ConnectorsPluginStart } from './types';

export const init: PluginInitializer = () => {
  const plugin = new ConnectorsPlugin();

  return {
    id: PLUGIN_ID,
    plugin,
  };
};

export default init;
