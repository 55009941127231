import { Center, Group, Text, UnstyledButton, createStyles, rem } from '@mantine/core';
import { IconChevronDown, IconChevronUp, IconSelector } from '@tabler/icons-react';

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
  width?: number | string;
  isActive?: boolean;
}

const useStyles = createStyles((theme) => ({
  th: {
    padding: '0 !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.xs}`,

    '&:hover': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
    },
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },
}));

/**
 * A Table Header component that can be used to sort a table.
 */
export function Th({ children, reversed, sorted, onSort, width, isActive = true }: ThProps) {
  const { classes } = useStyles();
  let Icon = IconSelector;
  if (sorted) {
    Icon = reversed ? IconChevronUp : IconChevronDown;
  }

  const style = width ? { width: typeof width === 'number' ? `${width}px` : width } : undefined;

  return (
    <th className={classes.th} style={style}>
      {isActive ? (
        <UnstyledButton onClick={onSort} className={classes.control}>
          <Group position="apart">
            <Text fw={500} fz="sm">
              {children}
            </Text>
            <Center className={classes.icon}>
              <Icon size="0.9rem" stroke={1.5} />
            </Center>
          </Group>
        </UnstyledButton>
      ) : (
        children
      )}
    </th>
  );
}
