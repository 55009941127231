import { createIntl, createIntlCache } from 'react-intl';
import type { IntlShape } from 'react-intl';

export type I18n = IntlShape;

export const init = (
  { locale, messages }: { locale: string; messages: Record<string, string> } = {
    locale: 'en',
    messages: {},
  }
) => {
  const cache = createIntlCache();

  const i18n = createIntl(
    {
      locale,
      messages,
    },
    cache
  );

  return {
    i18n,
  };
};

export default init;
