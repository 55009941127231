import { UseQueryResult, useQuery } from '@tanstack/react-query';

import type { API } from './api';
import type { CoreState } from '../../../core';
import type { XchangeConnectorId } from '../../../shared_imports';
import type { ListConnectorResponse } from '../shared_imports';

export const init = ({ api, coreState: { testModeOn } }: { api: API; coreState: CoreState }) => {
  function useBalances(
    {
      useConnectors,
    }: {
      useConnectors: (args?: {
        isActive?: boolean;
      }) => UseQueryResult<ListConnectorResponse | null>;
    },
    xchanges?: Array<XchangeConnectorId | 'paper' | undefined>
  ) {
    const fetchOnlyPaper =
      testModeOn === true ||
      (xchanges !== undefined && xchanges.length === 1 && xchanges[0] === 'paper');
    const connectorsQuery = useConnectors();
    const { data: _userConnectors, isFetching: _isFetchingConnectors } = connectorsQuery;
    const userConnectors = _userConnectors ?? [];

    // Check that the user has the connector for every xchanges to fetch balances from
    let hasXchangeConnector = fetchOnlyPaper;
    if (xchanges) {
      hasXchangeConnector = xchanges.every((xchange) =>
        xchange === 'paper' ? true : userConnectors.some(({ id }) => id === xchange)
      );
    }

    const xchangesToString = xchanges === undefined ? undefined : xchanges.join(',');
    const isFetchingConnectors = fetchOnlyPaper ? false : _isFetchingConnectors;

    const balancesQuery = useQuery({
      queryKey: ['balances', xchangesToString, hasXchangeConnector, isFetchingConnectors],
      queryFn: () => {
        if (!hasXchangeConnector || isFetchingConnectors) {
          return null;
        }

        return api.getBalances(xchangesToString, { testModeOn });
      },
    });

    return {
      balances: balancesQuery,
      connectors: connectorsQuery,
    };
  }

  return {
    useBalances,
  };
};

export type Queries = ReturnType<typeof init>;
