import { Box, Button, TextInput } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, useCallback } from 'react';

import { notifications } from '@mantine/notifications';
import { useHttpErrorNotification } from '../../../../utils';
import { useConnectorsContext } from '../../context';

interface FormValues {
  key: string;
  secret: string;
}

interface Props {
  onConnectorCreated?: () => void;
}

export const AddKrakenConnector: FC<Props> = ({ onConnectorCreated }) => {
  const queryClient = useQueryClient();
  const { getApi } = useConnectorsContext();
  const { api } = getApi();
  const createConnector = useMutation({
    mutationFn: api.createConnector,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['connectors'] });

      if (onConnectorCreated) {
        onConnectorCreated();
      }

      notifications.show({
        title: 'Success!',
        message: `Connected to Kraken successfully.`,
      });
    },
  });

  const form = useForm<FormValues>({
    initialValues: {
      key: '',
      secret: '',
    },

    validate: {
      key: isNotEmpty('Key must not be empty'),
      secret: isNotEmpty('Secret must not be empty'),
    },
  });

  const { error: createConnectorError } = createConnector;

  const submitForm = useCallback(
    ({ key, secret }: FormValues) => {
      createConnector.mutate({
        id: 'kraken',
        payload: {
          id: 'kraken',
          key,
          secret,
        },
      });
    },
    [createConnector]
  );

  useHttpErrorNotification(createConnectorError);

  return (
    <Box
      component="form"
      onSubmit={form.onSubmit(submitForm)}
      sx={(theme) => ({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[0],
        borderRadius: theme.radius.md,
        padding: theme.spacing.md,
      })}
    >
      <TextInput
        label="API Key"
        placeholder="Your Kraken API Key"
        required
        {...form.getInputProps('key')}
      />
      <TextInput
        label="API Secret"
        placeholder="Your Kraken API Secret"
        required
        mt="md"
        {...form.getInputProps('secret')}
      />
      <Button mt="xl" type="submit" loading={createConnector.isLoading}>
        Connect Kraken account
      </Button>
    </Box>
  );
};
