import { createContext, type FC, type ReactNode, useContext } from 'react';
import type { API, Queries } from './api';

interface ConnectorsContext {
  getApi: () => {
    api: API;
    queries: Queries;
  };
}

const connectorsContext = createContext<ConnectorsContext | null>(null);
const ContextProvider = connectorsContext.Provider;

export const ConnectorsProvider: FC<ConnectorsContext & { children: ReactNode }> = ({
  children,
  ...rest
}) => {
  return <ContextProvider value={rest}>{children}</ContextProvider>;
};

export const useConnectorsContext = () => {
  const ctx = useContext(connectorsContext);
  if (!ctx) {
    throw new Error(`Ctx missing`);
  }
  return ctx;
};
