import type { Core } from '../../../core';
import type { UserFetchResponse, DeleteUserResponse } from '../shared_imports';

import { init as initQueries } from './queries';

const initApi = ({ httpClient }: { httpClient: Core['httpClient'] }) => {
  const getMe = () => {
    return httpClient.get<UserFetchResponse>('/users/me');
  };

  const deleteMe = () => {
    return httpClient.delete<DeleteUserResponse>('/users/me');
  };

  const api = {
    getMe,
    deleteMe,
  };

  return api;
};

export const init = ({ httpClient }: { httpClient: Core['httpClient'] }) => {
  const api = initApi({ httpClient });
  const queries = initQueries({ api });

  return {
    api,
    queries,
  };
};

export type ApiWithQueries = ReturnType<typeof init>;
export type API = ReturnType<typeof initApi>;
