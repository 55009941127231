export const { fetch: originalFetch } = window;

type RequestArgs = [input: RequestInfo | URL, init: RequestInit];

export type RequestInterceptor = (
  input: RequestInfo | URL,
  init: RequestInit
) => Promise<RequestArgs | null>;

export type ResponseInterceptor = (response: Response, request: RequestArgs) => Promise<Response>;

const interceptors: {
  request: Set<RequestInterceptor>;
  response: Set<ResponseInterceptor>;
} = {
  request: new Set<RequestInterceptor>(),
  response: new Set<ResponseInterceptor>(),
};

export const addRequestInterceptor = (interceptor: RequestInterceptor) => {
  interceptors.request.add(interceptor);
};

export const addResponseInterceptor = (interceptor: ResponseInterceptor) => {
  interceptors.response.add(interceptor);
};

async function runRequestInterceptors(args: RequestArgs, list: RequestInterceptor[]) {
  return list.reduce<Promise<RequestArgs | null>>((promise, interceptor) => {
    return promise.then((value) => {
      if (value === null) {
        return null;
      }
      return interceptor(...value);
    });
  }, Promise.resolve(args));
}

async function runResponseInterceptors(
  response: Response,
  request: RequestArgs,
  list: ResponseInterceptor[]
) {
  return list.reduce((promise, interceptor) => {
    return promise.then((res) => interceptor(res, request));
  }, Promise.resolve(response));
}

// Patch window.fetch to add interceptors

window.fetch = async (...args) => {
  const [incomingResource, incomingConfig] = args;
  if (!incomingConfig) {
    throw new Error('Fetch init missing.');
  }
  const parsedArgs: RequestArgs = [incomingResource, incomingConfig];
  const request = await runRequestInterceptors(parsedArgs, [...interceptors.request]);

  if (request === null) {
    // Abort the request
    return new Response();
  }

  const [resource, config] = request;
  const response = await originalFetch(resource, config);

  return runResponseInterceptors(response, parsedArgs, [...interceptors.response]);
};
