import type { UseFormReturnType } from '@mantine/form';
import { useEffect } from 'react';

export const useSyncLocalStorage = <T, S extends Record<any, any>>({
  key,
  isActive = true,
  form,
  state,
  onLoad,
}: {
  key: string;
  form: UseFormReturnType<T>;
  /** Additional state to save along with the form values */
  state?: S;
  isActive?: boolean;
  onLoad?: (data: { values: T; state: S }) => void;
}) => {
  const { values, setValues } = form;

  // Load the form values from local storage. This allows us to navigate to Google Sheets and authorize
  // the account and come back to the form with the values still there.
  useEffect(() => {
    if (!isActive) {
      return undefined;
    }

    const localStorageValue = window.localStorage.getItem(key);
    if (localStorageValue) {
      try {
        const parsed = JSON.parse(localStorageValue) as {
          values: T;
          state: S;
        };
        setValues(parsed.values);
        onLoad?.(parsed);
      } catch (e) {
        // Silently fail.
      }
    }

    return () => {
      if (localStorageValue) {
        window.localStorage.setItem(key, localStorageValue);
      }
    };
  }, [setValues, isActive, onLoad, key]);

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify({ values, state }));
  }, [values, state, key]);
};
