import formatDuration from 'date-fns/formatDuration';
import intervalToDuration from 'date-fns/intervalToDuration';
import {
  Code,
  Flex,
  MantineTheme,
  Text,
  Switch,
  rem,
  Popover,
  ActionIcon,
  Group,
  Alert,
  CopyButton,
  Tooltip,
  Box,
} from '@mantine/core';
import { FC, Fragment, useState } from 'react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import { IconCheck, IconCopy, IconInfoCircle } from '@tabler/icons-react';
import type { FormIndicator, FormValues } from './create-edit-trade-config-form';

interface Props {
  formValues: FormValues;
  side: 'open' | 'close';
  timeSpan?: number;
}

type Example = 'openPosition' | 'closePosition';

export const WebhookMessageExamples: FC<Props> = ({ formValues, side, timeSpan }) => {
  const [withSettings, setWithSettings] = useState(false);
  const [isWebhookPopoverVisible, { close: closePopover, open: openPopover }] =
    useDisclosure(false);
  const isTabletOrBigger = useMediaQuery('(min-width: 48em)');
  const indicators = side === 'open' ? formValues.indicatorsOpen : formValues.indicatorsClose;
  const {
    tradeName,
    __internal__: { multipleIndicators },
  } = formValues;
  const tradeIdDisplay = tradeName === '' ? '<SignalName>' : tradeName;
  const examples: Example = side === 'open' ? 'openPosition' : 'closePosition';
  const withMultipleIndicators = multipleIndicators[side];

  const filteredIndicators = (indicators ?? [])
    .filter(({ name }) => Boolean(name.trim()))
    .reduce<Array<FormIndicator & { hasError: boolean }>>((acc, item) => {
      const hasError = acc.some((indicator) => indicator.name === item.name);
      return [...acc, { ...item, hasError }];
    }, []);

  const renderIndicatorName = (name: string) => {
    if (name === '') {
      return '<IndicatorName>';
    }
    return name;
  };

  const renderMessage = (
    message: string,
    {
      isLast = true,
      hasMultiple = false,
      hasError = false,
    }: { isLast?: boolean; hasMultiple?: boolean; hasError?: boolean } = {}
  ) => {
    return (
      <Group
        sx={(theme: MantineTheme) => ({
          marginBottom: isLast ? 0 : theme.spacing.md,
        })}
      >
        <Box
          sx={(theme: MantineTheme) => ({
            flex: 1,
            overflow: 'visible',
            marginLeft: hasMultiple ? theme.spacing.lg : undefined,
            maxWidth: `calc(100% - ${theme.spacing.lg} - ${rem(44)})`,
            position: 'relative',
            '&:before': hasMultiple
              ? {
                  content: '""',
                  width: theme.spacing.lg,
                  position: 'absolute',
                  left: `calc(${theme.spacing.lg} * -1)`,
                  top: '50%',
                  borderBottom: `2px solid ${
                    theme.colorScheme === 'dark' ? theme.colors.teal[8] : theme.colors.teal[4]
                  }`,
                }
              : undefined,
            '&:after': isLast
              ? undefined
              : {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: `calc(${theme.spacing.lg} * -1)`,
                  height: rem(56),
                  borderLeft: `2px solid ${
                    theme.colorScheme === 'dark' ? theme.colors.teal[8] : theme.colors.teal[4]
                  }`,
                },
          })}
        >
          <Code block sx={(theme) => ({ color: hasError ? theme.colors.red[7] : undefined })}>
            <span>{message}</span>
          </Code>
        </Box>

        <CopyButton value={message} timeout={2000}>
          {({ copied, copy }) => (
            <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
              <ActionIcon color={copied ? 'teal' : 'gray'} onClick={copy}>
                {copied ? <IconCheck size="1rem" /> : <IconCopy size="1rem" />}
              </ActionIcon>
            </Tooltip>
          )}
        </CopyButton>
      </Group>
    );
  };

  const renderOpenClosePositionExample = () => {
    let action = 'open';
    if (examples === 'closePosition') {
      action = 'close';
    }

    const getSettings = (alertType?: string) => {
      if (!withSettings) {
        return '';
      }
      if (alertType !== undefined) {
        return `#setting1=foo,setting2=bar`;
      }
      return '#volume=18750,bias=bullish';
    };

    if (!withMultipleIndicators) {
      return renderMessage(`${tradeIdDisplay}#${action}`);
    }

    if (filteredIndicators.length === 0) {
      return (
        <Alert title="" color="yellow">
          Give a name to your indicator to preview the message to send.
        </Alert>
      );
    }

    return (
      <>
        {filteredIndicators.map((indicator, i) => (
          <Fragment key={indicator.key}>
            {renderMessage(
              `${tradeIdDisplay}:${renderIndicatorName(indicator.name)}#${action}${getSettings(
                indicator.name
              )}`,
              {
                isLast: i === filteredIndicators.length - 1,
                hasMultiple: filteredIndicators.length > 1,
                hasError: indicator.hasError,
              }
            )}
          </Fragment>
        ))}
      </>
    );
  };

  const getDescription = () => {
    if (!timeSpan) {
      return `Send the following messages to ${side} a position`;
    }
    return (
      <>
        Send the following messages{' '}
        <Text
          span
          sx={(theme) => ({
            fontWeight: 700,
            color: theme.colorScheme === 'dark' ? theme.colors.gray[4] : theme.colors.dark[4],
          })}
        >
          within {formatDuration(intervalToDuration({ start: 0, end: timeSpan * 1000 }))}{' '}
        </Text>
        {`to ${side} a position`}
      </>
    );
  };

  const hasMessage = filteredIndicators === undefined || filteredIndicators.length > 0;

  return (
    <>
      <Flex justify="space-between" align="flex-end" mb="md">
        <Box sx={{ paddingRight: rem(24) }} mt="md">
          <Text weight={700} size="sm">
            Messages
          </Text>
          {hasMessage && (
            <Text size="sm" color="dimmed">
              {getDescription()}
            </Text>
          )}
        </Box>
        {hasMessage && (
          <Group spacing={0} sx={{ flex: '0 0 150px' }}>
            <Switch
              size="xs"
              checked={withSettings}
              label="With settings"
              onChange={(event) => setWithSettings(event.currentTarget.checked)}
            />
            <Popover
              width={isTabletOrBigger ? 320 : 260}
              withArrow
              shadow="md"
              position="bottom-end"
              opened={isWebhookPopoverVisible}
            >
              <Popover.Target>
                <ActionIcon color="blue" onMouseEnter={openPopover} onMouseLeave={closePopover}>
                  <IconInfoCircle size="1rem" />
                </ActionIcon>
              </Popover.Target>
              <Popover.Dropdown sx={{ pointerEvents: 'none' }}>
                <Text size="sm">
                  Add any number of key/value pair settings to your messages. Those settings will be
                  added to your position for future reference of your indicator settings. This is
                  optional and can be left blank.
                </Text>
              </Popover.Dropdown>
            </Popover>
          </Group>
        )}
      </Flex>

      {renderOpenClosePositionExample()}
    </>
  );
};
