import { Page } from './page';
import { NotFoundTitle } from '../components';

export const NotFoundPage = () => {
  return (
    <Page>
      <NotFoundTitle />
    </Page>
  );
};

export default NotFoundPage;
