import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useCoreState } from '../../../core';
import type { PluginStart } from '../types';

export const useClosePosition = (getApi: PluginStart['getApi']) => {
  const queryClient = useQueryClient();
  const [coreState] = useCoreState();
  const { testModeOn } = coreState;

  return useMutation({
    mutationFn: ({ positionId }: { positionId: number }) => {
      return getApi({ coreState }).api.closePosition(positionId, { testModeOn });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['positions', testModeOn],
      });
      queryClient.invalidateQueries({
        queryKey: ['stats', 'trades'],
      });
    },
  });
};
