import { useEffect, useState } from 'react';

import type { Observable } from 'rxjs';

/* eslint-disable-next-line @typescript-eslint/no-unused-vars */
export function useObservable<T, D = T>(observable: Observable<T>): T | null;
export function useObservable<T, D = T>(observable: Observable<T>, defaultValue: D): T | D;
export function useObservable<T, D = T>(observable: Observable<T>, defaultValue?: D): T | D | null {
  const [value, setValue] = useState<T | D | null>(defaultValue ?? null);

  useEffect(() => {
    const subscription = observable.subscribe((updated) => {
      setValue(updated);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [observable]);

  return value;
}
