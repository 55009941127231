import { Anchor } from '@mantine/core';
import type { FC } from 'react';
import type { GsheetRowMeta } from '../shared_imports';

interface Props {
  meta: GsheetRowMeta;
}

const baseUrl = 'https://docs.google.com/spreadsheets/d';

const parseMeta = (meta: GsheetRowMeta) => {
  const {
    sheet: { sheetId = 0 },
    range,
    fileId,
  } = meta;

  let rowNumber = '0';
  let rowFound = false;
  const regRes = range.match(/!A(\d+):/);

  if (regRes && regRes.length > 1) {
    rowFound = true;
    rowNumber = regRes[1] ?? '0';
  }

  return {
    url: `${baseUrl}/${fileId}/edit#gid=${sheetId}&range=${rowNumber}:${rowNumber}`,
    rowNumber,
    rowFound,
  };
};

export const GoogleSheetLink: FC<Props> = ({ meta }) => {
  const { url, rowNumber, rowFound } = parseMeta(meta);
  return (
    <Anchor href={url} target="_blank">
      {rowFound ? `Row ${rowNumber}` : meta.range}
    </Anchor>
  );
};
