import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Tabs } from '@mantine/core';

import type {
  BalanceItem,
  GetTradeConfigStatsResponse,
  PaperBalanceItem,
  TradeConfig,
} from '../../shared_imports';
import { SummaryTrade } from '../summary-trade';
import { useApp } from '../../context';
import { useCore, useCoreState } from '../../../../core';
import { PositionsTable } from '../../../positions/components/positions_table';
import type {
  GetAssetPairsPricesResponse,
  PositionClosed,
  PositionOpen,
  XchangeConnectorId,
} from '../../../../shared_imports';
import { useObservable } from '../../../../utils';
import type { EnhancedPositionOpen, SortBy } from '../../../positions';
import { TradeConfigStats } from './trade-config-stats';

interface Props {
  tradeConfig: TradeConfig | null | undefined;
  balance?: BalanceItem | PaperBalanceItem;
  stats?: GetTradeConfigStatsResponse | null;
  refetchStats: () => void;
}

export const DetailsPanel: FC<Props> = ({ tradeConfig, balance, stats, refetchStats }) => {
  const totalOpenPositions = stats?.unrealized.positions.total;
  const {
    assets: { pricesService },
  } = useCore();
  const [coreState] = useCoreState();
  const assetPrices = useObservable<GetAssetPairsPricesResponse>(pricesService.assetPrices$, {});
  const { getPlugins } = useApp();
  const { positions } = getPlugins();
  const { data: positionsFetched, refetch: refetchPositions } = positions
    .getApi({ coreState })
    .queries.usePositions({
      tradeId: tradeConfig?.tradeId,
      status: 'open',
    });
  const [sortBy, setSortBy] = useState<SortBy>('timestamp');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const checkStaleStatsTimeout = useRef<NodeJS.Timeout>();
  const isMounted = useRef(false);

  const calculatePlAndRoi = useCallback(
    (position: PositionOpen | PositionClosed) => {
      const { openPrice, size } = position;
      const currentPrice = assetPrices[position.ticker]?.price ?? '0';
      const roi = pricesService.calculateRoi(openPrice, currentPrice);
      const pl = pricesService.calculatePl(openPrice, currentPrice, size);

      return {
        roi,
        pl,
      };
    },
    [assetPrices, pricesService]
  );

  const openPositions = useMemo<EnhancedPositionOpen[]>(() => {
    if (!positionsFetched) {
      return [];
    }
    const assetPricesRequested: { [key: string]: Set<string> } = {};

    const enhanced = positionsFetched.pages.reduce<EnhancedPositionOpen[]>((acc, page) => {
      page.positions.forEach((pos) => {
        if (pos.status === 'open') {
          const { pl, roi } = calculatePlAndRoi(pos);

          assetPricesRequested[pos.exchange] = assetPricesRequested[pos.exchange] ?? new Set();
          assetPricesRequested[pos.exchange]!.add(pos.ticker);

          const enhancedPosition: EnhancedPositionOpen = {
            ...pos,
            pl,
            result: roi,
          };
          acc.push(enhancedPosition);
        }
      });
      return acc;
    }, []);

    Object.entries(assetPricesRequested).forEach(([exchange, assets]) => {
      if (assets.size > 0) {
        pricesService.addAssetPairs(exchange as XchangeConnectorId, [...assets]);
      }
    });

    if (!sortBy) {
      return enhanced;
    }

    const sorted = enhanced.sort((a, b) => {
      if (sortBy === 'timestamp') {
        return sortOrder === 'asc' ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id);
      }

      if (sortBy === 'roi') {
        return sortOrder === 'asc'
          ? Number(a.result) - Number(b.result)
          : Number(b.result) - Number(a.result);
      }

      if (sortBy === 'pl') {
        return sortOrder === 'asc' ? Number(a.pl) - Number(b.pl) : Number(b.pl) - Number(a.pl);
      }

      if (sortBy === 'pair') {
        return sortOrder === 'asc'
          ? a.ticker.localeCompare(b.ticker)
          : b.ticker.localeCompare(a.ticker);
      }

      if (sortBy === 'signal') {
        return sortOrder === 'asc'
          ? (a.tradeName ?? '').localeCompare(b.tradeName ?? '')
          : (b.tradeName ?? '').localeCompare(a.tradeName ?? '');
      }

      return 0;
    });

    return sorted;
  }, [positionsFetched, sortBy, calculatePlAndRoi, pricesService, sortOrder]);

  useEffect(() => {
    if (stats !== undefined && isMounted.current) {
      refetchPositions();
    }
  }, [stats, refetchPositions]);

  /**
   * Make sure we don't have stale stats data as closing quickly multiple positions
   * make the read to the stats not consistent. We will wait 2 seconds between
   * check as we might have inflight requests that will update the stats.
   */
  useEffect(() => {
    if (checkStaleStatsTimeout.current) {
      clearTimeout(checkStaleStatsTimeout.current);
    }

    if (stats && stats.unrealized.positions.total !== openPositions.length) {
      checkStaleStatsTimeout.current = setTimeout(() => {
        checkStaleStatsTimeout.current = undefined;
        refetchStats();
      }, 2000);
    }

    return () => {
      if (checkStaleStatsTimeout.current) {
        clearTimeout(checkStaleStatsTimeout.current);
      }
    };
  }, [openPositions, stats, refetchStats]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Tabs defaultValue="stats" sx={{ height: '100%' }}>
      <Tabs.List>
        <Tabs.Tab value="stats">Stats</Tabs.Tab>
        <Tabs.Tab value="positions">
          Open positions{totalOpenPositions ? ` (${totalOpenPositions})` : ''}
        </Tabs.Tab>
        <Tabs.Tab value="config">Settings</Tabs.Tab>
      </Tabs.List>

      <Box mt="sm" sx={{ overflowY: 'auto', height: 'calc(100% - 45px)' }}>
        <Tabs.Panel value="stats">
          {tradeConfig && stats && (
            <TradeConfigStats tradeConfig={tradeConfig} stats={stats} refetchStats={refetchStats} />
          )}
        </Tabs.Panel>
        <Tabs.Panel value="positions">
          <PositionsTable
            doShowTableWhenEmpty={false}
            positions={openPositions}
            positionsStatus="open"
            columnConfig={{
              pair: {
                visible: false,
              },
              trigger: {
                visible: false,
              },
              pl: {
                visible: true,
                width: '25%',
              },
              roi: {
                visible: true,
                width: '25%',
              },
            }}
            getApi={positions.getApi}
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSortChange={(newSortBy, newSortOrder) => {
              setSortBy(newSortBy);
              setSortOrder(newSortOrder);
            }}
          />
        </Tabs.Panel>
        <Tabs.Panel value="config">
          {tradeConfig && <SummaryTrade tradeConfig={tradeConfig} balance={balance} />}
        </Tabs.Panel>
      </Box>
    </Tabs>
  );
};
