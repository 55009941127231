import { Flex, Text, type TextProps, Sx } from '@mantine/core';
import type { FC } from 'react';

export interface Props {
  label: string;
  value?: string | number | null | React.ReactNode;
  valueSuffix?: string | React.ReactNode;
  isDimmed?: boolean;
  color?: TextProps['color'];
  ff?: TextProps['ff'];
  size?: TextProps['size'];
  fontWeightValue?: TextProps['weight'] | null;
  lineHeight?: string;
  sx?: Sx;
  textValueProps?: TextProps;
}

export const KeyValue: FC<Props> = ({
  label,
  value,
  valueSuffix,
  color: _color,
  ff,
  size = 'sm',
  isDimmed = false,
  fontWeightValue = 600,
  lineHeight = '1.65rem',
  sx,
  textValueProps,
}) => {
  let color = _color;
  if (color === undefined) {
    color = isDimmed ? 'dimmed' : undefined;
  }

  return (
    <Flex gap="xs" sx={sx}>
      <Text
        size={size}
        ff={ff}
        sx={{
          flexShrink: 0,
          lineHeight,
          minHeight: lineHeight,
          verticalAlign: 'middle',
          display: 'table-cell',
        }}
      >
        {label}:
      </Text>
      <Text
        size={size}
        ff={ff}
        sx={{
          lineHeight,
          minHeight: lineHeight,
          verticalAlign: 'middle',
          display: 'table-cell',
          color,
          fontWeight: fontWeightValue === null ? undefined : 600,
        }}
        color={color}
        weight={fontWeightValue === null ? undefined : fontWeightValue}
        {...textValueProps}
      >
        <>
          {value}
          {valueSuffix && (
            <>
              {' '}
              <Text span fz="xs" color="dimmed">
                {valueSuffix}
              </Text>
            </>
          )}
        </>
      </Text>
    </Flex>
  );
};
